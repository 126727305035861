import React from 'react';
import PropTypes from 'prop-types';

export const IconLangDe = ({ className }) => (
  <svg className={className} xmlns="http://www.w3.org/2000/svg" width="30" height="24" viewBox="0 0 30 24">
    <defs>
      <path id="de-a" d="M5.128 0h19.744c1.783 0 2.43.186 3.082.534.652.349 1.163.86 1.512 1.512.348.652.534 1.299.534 3.082v13.744c0 1.783-.186 2.43-.534 3.082a3.635 3.635 0 0 1-1.512 1.512c-.652.348-1.299.534-3.082.534H5.128c-1.783 0-2.43-.186-3.082-.534a3.635 3.635 0 0 1-1.512-1.512C.186 21.302 0 20.655 0 18.872V5.128c0-1.783.186-2.43.534-3.082A3.635 3.635 0 0 1 2.046.534C2.698.186 3.345 0 5.128 0z" />
      <path id="de-d" d="M0 0h30v24H0z" />
      <filter id="de-c" width="116.7%" height="120.8%" x="-8.3%" y="-10.4%" filterUnits="objectBoundingBox">
        <feGaussianBlur in="SourceAlpha" result="shadowBlurInner1" stdDeviation="1.5" />
        <feOffset dy="2" in="shadowBlurInner1" result="shadowOffsetInner1" />
        <feComposite in="shadowOffsetInner1" in2="SourceAlpha" k2="-1" k3="1" operator="arithmetic" result="shadowInnerInner1" />
        <feColorMatrix in="shadowInnerInner1" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.2 0" />
      </filter>
    </defs>
    <g fill="none" fillRule="evenodd">
      <mask id="de-b" fill="#fff">
        <use xlinkHref="#de-a" />
      </mask>
      <g fillRule="nonzero" mask="url(#de-b)">
        <path fill="#464655" d="M30.304.02H-.304C-1.793.02-3 1.247-3 2.762v5.256h36V2.763C33 1.248 31.793.019 30.304.019z" />
        <path fill="#FFE15A" d="M-3 21.257C-3 22.772-1.793 24-.304 24h30.608C31.793 24 33 22.772 33 21.257V16H-3v5.257z" />
        <path fill="#FF4B55" d="M-3 8h36v8H-3z" />
      </g>
      <g fill="#000" mask="url(#de-b)">
        <use filter="url(#de-c)" xlinkHref="#de-d" />
      </g>
    </g>
  </svg>

);

IconLangDe.propTypes = {
  className: PropTypes.string,
};

IconLangDe.defaultProps = {
  className: null,
};
