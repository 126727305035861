import * as R from 'ramda';
import { GAME_SCREEN_MODE } from 'core/constants';
import { IFRAME_SETTINGS, BREAKPOINT_LG, SPECIFIC_LIVE_GAMES_PROVIDERS } from '../constants';

const {
  GAME_CONTAINER_OFFSET_X, GAME_CONTAINER_OFFSET_Y, GAME_CONTAINER_OFFSET_YS,
  WIDTH_TO_HEIGHT_IFRAME_HD,
  HEIGHT_TO_WIDTH_IFRAME_HD, GAME_CONTAINER_OFFSET_XS,
  WIDTH_TO_HEIGHT_IFRAME_NOT_HD, HEIGHT_TO_WIDTH_IFRAME_NOT_HD
} = IFRAME_SETTINGS;

const { SINGLE, TWIN, QUARTER } = GAME_SCREEN_MODE;

export const calculateGameIframeSize = (
  widthRoot, width, height,
  isHD, screenMode = SINGLE
) => {
  let iframeWidth;
  let iframeHeight;

  const offsetX = widthRoot < BREAKPOINT_LG ? GAME_CONTAINER_OFFSET_XS : GAME_CONTAINER_OFFSET_X;
  const offsetY = widthRoot < BREAKPOINT_LG ? GAME_CONTAINER_OFFSET_YS : GAME_CONTAINER_OFFSET_Y;

  const multipleScreenOffsetY = (screenMode === TWIN || screenMode === QUARTER) ? offsetY + 40 : offsetY;

  const [heightToWidth, widthToHeight] = isHD
    ? [HEIGHT_TO_WIDTH_IFRAME_HD, WIDTH_TO_HEIGHT_IFRAME_HD]
    : [HEIGHT_TO_WIDTH_IFRAME_NOT_HD, WIDTH_TO_HEIGHT_IFRAME_NOT_HD];

  if ((height - multipleScreenOffsetY) / (width - offsetX) < heightToWidth) {
    iframeHeight = height - multipleScreenOffsetY;
    iframeWidth = iframeHeight * widthToHeight;
  } else {
    iframeWidth = width - offsetX;
    iframeHeight = iframeWidth * heightToWidth;
  }

  if (screenMode === TWIN) {
    return {
      iframeWidth: iframeWidth / 2 - 10,
      iframeHeight: iframeHeight + 50
    };
  }

  if (screenMode === QUARTER) {
    return {
      iframeWidth: (iframeWidth / 2) - 10,
      iframeHeight: (iframeHeight / 2) - 5
    };
  }

  return { iframeWidth, iframeHeight };
};

export const getIsGameIframeScrollEnabled = (provider, scrollableProviders = SPECIFIC_LIVE_GAMES_PROVIDERS) =>
  R.compose(
    R.ifElse(
      R.equals(true),
      R.always('yes'),
      R.always('no')
    ),
    R.includes(R.__, scrollableProviders)
  )(provider);
