// DO NOT change config object's keys because they are used for payment methods in CORE!!!
module.exports = {
  amount: {
    type: 'text',
    placeholder: 'form.fields.amount',
    validation: [
      { type: 'required' },
      { type: 'amount' },
      { type: 'number-multiplicity', value: 10 },
    ],
  },
  accountId: {
    type: 'text',
    placeholder: 'form.fields.account-id',
    validation: [
      { type: 'required' },
    ],
  },
  clientAccountNumber: {
    type: 'text',
    placeholder: 'form.fields.client-account-number',
    validation: [
      { type: 'latin-and-number' },
      { type: 'required' },
    ],
  },
  skrillAccountEmail: {
    type: 'text',
    placeholder: 'form.fields.skrill-account-email',
    validation: [
      { type: 'required' },
      { type: 'email' },
    ],
  },
  firstName: {
    type: 'text',
    placeholder: 'form.fields.first-name',
    validation: [
      { type: 'required' },
    ],
  },
  lastName: {
    type: 'text',
    placeholder: 'form.fields.last-name',
    validation: [
      { type: 'required' },
    ],
  },
  birthDate: {
    type: 'date',
    label: 'form.fields.birth-date',
    placeholder: 'form.fields.birth-date.mask',
    validation: [
      { type: 'required' },
      { type: 'birth-date' },
    ],
  },
  email: {
    type: 'text',
    placeholder: 'form.fields.email',
    validation: [
      { type: 'required' },
      { type: 'email' },
    ],
  },
  branchCode: {
    type: 'text',
    placeholder: 'form.fields.branchCode',
    validation: [
      { type: 'required' },
    ],
  },
  iban: {
    type: 'text',
    placeholder: 'form.fields.iban',
    validation: [
      { type: 'latin-and-number' },
      { type: 'required' },
      { type: 'iban-gb' },
    ],
  },
  bankName: {
    type: 'text',
    placeholder: 'form.fields.bankName',
    validation: [
      { type: 'latin-and-number' },
      { type: 'required' },
    ],
  },
  bankAddress: {
    type: 'text',
    placeholder: 'form.fields.bankAddress',
    validation: [
      { type: 'latin-address' },
      { type: 'required' },
    ],
  },
  swift: {
    type: 'text',
    placeholder: 'form.fields.swift',
    validation: [
      { type: 'latin-and-number' },
      { type: 'required' },
    ],
  },
  MB_cryptoName: {
    type: 'select',
    placeholder: 'form.fields.crypto-name',
    validation: [
      { type: 'required' },
    ],
    isReadOnly: true,
  },
  MB_walletAddress: {
    type: 'text',
    placeholder: 'form.fields.wallet-address',
    validation: [
      { type: 'required' },
    ],
  },
  MB_bankName: {
    type: 'select',
    placeholder: 'form.fields.bank-name',
    validation: [
      { type: 'required' },
    ],
    isReadOnly: true,
  },
  MB_iban: {
    type: 'text',
    placeholder: 'form.fields.iban',
    validation: [
      { type: 'latin-and-number' },
      { type: 'required' },
    ],
  },
  MB_branchName: {
    type: 'text',
    placeholder: 'form.fields.branch-name',
    validation: [
      { type: 'required' },
    ],
  },
  MB_branchCode: {
    type: 'text',
    placeholder: 'form.fields.branch-code',
    validation: [
      { type: 'required' },
    ],
  },
  MB_city: {
    type: 'text',
    placeholder: 'form.fields.city',
    validation: [
      { type: 'required' },
    ],
  },
  MB_idNumber: {
    type: 'text',
    placeholder: 'form.fields.id-number',
    validation: [
      { type: 'required' },
    ],
  },
  MB_accountNumber: {
    type: 'text',
    placeholder: 'form.fields.account-number',
    validation: [
      { type: 'required' },
    ],
  },
  MB_ReceiverDateOfBirth: {
    type: 'date',
    label: 'form.fields.birth-date',
    placeholder: 'form.fields.birth-date.mask',
    validation: [
      { type: 'required' },
      { type: 'birth-date' },
    ],
  },
  MB_mobileNumber: {
    type: 'tel',
    placeholder: 'form.fields.mobile-from',
    validation: [
      { type: 'required' },
    ],
  },
  tr_bankName: {
    type: 'select',
    placeholder: 'form.fields.mb-bank-name',
    validation: [
      { type: 'required' },
    ],
    isReadOnly: true,
  },
  tr_accountId: {
    type: 'text',
    placeholder: 'form.fields.account-id',
    validation: [
      { type: 'required' },
    ],
  },
  tr_ibanNumber: {
    type: 'text',
    placeholder: 'form.fields.iban',
    validation: [
      { type: 'latin-and-number' },
      { type: 'required' },
      { type: 'iban-gb' },
    ],
  },
  tr_branchCode: {
    type: 'text',
    placeholder: 'form.fields.branchCode',
    validation: [
      { type: 'required' },
    ],
  },
  tr_officeCode: {
    type: 'text',
    placeholder: 'form.fields.branchCode',
    validation: [
      { type: 'required' },
    ],
  },
  tr_accountNumber: {
    type: 'text',
    placeholder: 'form.fields.account-number',
    validation: [
      { type: 'required' },
    ],
  },
  tr_customerName: {
    type: 'text',
    placeholder: 'form.fields.customer-name',
    validation: [
      { type: 'required' },
    ],
  },
};
