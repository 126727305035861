import * as R from 'ramda';
import { MOBILE } from '../constants';

/**
 * Return formatted placeholder
 * @param {String|Number} placeholder
 * @param {Function} formatMessage (received from "intl" prop)
 * @return {String}
 */

export const getPlaceholder = (placeholder, formatMessage) => {
  const targetPlaceholder = R.when(R.is(String), R.trim())(placeholder);

  if (targetPlaceholder || targetPlaceholder === 0) {
    return (typeof Number(targetPlaceholder) === 'number' && !Number.isNaN(Number(targetPlaceholder)))
      ? targetPlaceholder
      : formatMessage({ id: targetPlaceholder });
  }

  return '';
};

export const hideNavigationOnInputFocus = (cookies = '', navigation = '') => {
  setTimeout(() => {
    if (cookies) {
      cookies.style.cssText = 'display: none !important';
    }

    if (navigation) {
      navigation.style.display = 'none';
    }
  }, 10);
};

export const showNavigationOnInputBlur = (deviceType, cookies = '', navigation = '') => {
  if (deviceType === MOBILE) {
    setTimeout(() => {
      if (cookies) {
        cookies.style.cssText = 'display: flex';
      }

      if (navigation && navigation.style.display === 'none') {
        navigation.style.display = 'grid';
      }
    }, 10);
  }
};
