import React from 'react';
import { ModalWindow } from 'components/modal-window/modal-window';
import { FormSetupOtp } from 'components/forms/form-setup-otp/form-setup-otp';

import { MODAL_IDS } from '../../../constants';

import './setup-otp-modal.scss';

export const SetupOtpModal = () => (
  <ModalWindow
    id={MODAL_IDS.SETUP_OTP}
    coreProps={{ id: MODAL_IDS.SETUP_OTP }}
    bodyClassName="pt-4 px-2 pb-3"
    className="setup-otp-modal"
    isModalCentered
    fade
  >
    <FormSetupOtp />
  </ModalWindow>
);

