import React from 'react';
import PropTypes from 'prop-types';

export const IconSuccess = ({ className, outlined }) =>
  outlined ? (
    <svg
      className={className}
      width="44"
      height="44"
      viewBox="0 0 44 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22 44C9.86855 44 0 34.1314 0 22C0 9.86855 9.86855 0 22 0C34.1315 0 44 9.86855 44 22C44 34.1314 34.1315 44 22 44Z"
        fill="#42F121"
        fillOpacity="0.12"
      />
      <path
        d="M22 38.632C12.8294 38.632 5.36835 31.1709 5.36835 22.0003C5.36835 12.8297 12.8294 5.36865 22 5.36865C31.1706 5.36865 38.6316 12.8297 38.6316 22.0003C38.6316 31.1709 31.1706 38.632 22 38.632Z"
        stroke="url(#icon-success-paint0_linear_4406_25233)"
        strokeWidth="1.375"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M29.143 18.9746C29.5904 18.5272 29.5904 17.8017 29.143 17.3542C28.6955 16.9067 27.97 16.9067 27.5225 17.3542L20.4253 24.4514L16.6228 20.6487C16.1754 20.2012 15.4499 20.2012 15.0024 20.6486C14.5549 21.0961 14.5549 21.8216 15.0023 22.2691L19.6145 26.8816C19.9223 27.1894 20.3616 27.2855 20.7516 27.1699C20.929 27.1175 21.0963 27.0213 21.2363 26.8813L29.143 18.9746Z"
        fill="white"
      />
    </svg>
  ) : (
    <svg
      className={className}
      width="42"
      height="42"
      viewBox="0 0 42 42"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21 36C12.7157 36 6 29.2843 6 21C6 12.7157 12.7157 6 21 6C29.2843 6 36 12.7157 36 21C36 29.2843 29.2843 36 21 36ZM29.2075 16.1767C29.598 16.5673 29.598 17.2004 29.2075 17.5909L20.2075 26.5909C20.0738 26.7246 19.9117 26.8125 19.7406 26.8547C19.4115 26.9363 19.049 26.8485 18.7918 26.5913L13.5418 21.3409C13.1513 20.9504 13.1513 20.3172 13.5418 19.9267C13.9324 19.5362 14.5655 19.5362 14.956 19.9268L19.4995 24.4705L27.7933 16.1767C28.1838 15.7862 28.8169 15.7862 29.2075 16.1767Z"
        fill="url(#icon_success)"
      />
    </svg>
  );

IconSuccess.propTypes = {
  className: PropTypes.string,
  outlined: PropTypes.bool,
};

IconSuccess.defaultProps = {
  className: null,
  outlined: false,
};
