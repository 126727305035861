import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Alert from 'reactstrap/lib/Alert';
import Button from 'reactstrap/lib/Button';
import colors from 'customizations/js/color-variables';
import { NOTIFICATION_TYPES } from 'core/constants';
import {
  GlobalNotificationPopup as GlobalNotificationPopupCore,
  FormattedTag,
  RouteLink,
} from 'core/components';
import { withCoreComponent } from 'core/hocs';
import { IconSuccess } from '../icons/icon-notification/icon-success';
import { IconAlert } from '../icons/icon-alert/icon-alert';
import { IconClose } from '../icons/icon-close/icon-close';

const ICONS = {
  [NOTIFICATION_TYPES.SUCCESS]: <IconSuccess className="global-notification-type-icon" outlined />,
  [NOTIFICATION_TYPES.ERROR]: <IconAlert className="global-notification-type-icon" outlined />,
};

// TODO: Temp hack. In order not to show notifications which are triggered in the CORE
const FORBIDDEN_NOTIFICATIONS_INTL_KEYS = [
  'notification.password-updated.title',
  'notification.unknown.title',
];

export const GlobalNotificationPopupUI = ({
  type,
  message,
  values,
  visible,
  toggle,
  data,
}) => (
  !FORBIDDEN_NOTIFICATIONS_INTL_KEYS.includes(message.title) && (
    <Alert
      className="bg-transparent border-0 p-0 w-100 mb-1_5"
      isOpen={visible}
    >
      <div className={classNames(
        'global-notification d-flex flex-column flex-sm-row align-items-sm-center shadow text-base text-white py-1_25 px-2 rounded', {
          'bg-success': type === NOTIFICATION_TYPES.SUCCESS,
          'bg-danger': type === NOTIFICATION_TYPES.ERROR,
        }
      )}
      >
        <div className="d-flex align-items-center flex-grow-1 mr-sm-2">
          <div className="mr-1_25">
            {ICONS[type]}
          </div>
          <div className="d-flex flex-column justify-content-between font-weight-bold global-notification-text">
            {data?.hasTitle && (<FormattedTag id={message.title} />)}
            <FormattedTag className="font-weight-normal" values={values || {}} id={message.text} />
          </div>
        </div>

        {data?.btnIntlKey && (
          <div className="mt-1_5 mt-sm-0 mr-sm-1">
            <Button
              tag={RouteLink}
              to={data.link || '/'}
              onClick={toggle}
              color="primary"
              className="w-100"
            >
              <FormattedTag id={data.btnIntlKey || 'missing-key'} />
            </Button>
          </div>
        )}

        <div
          role="button"
          tabIndex="0"
          onKeyPress={toggle}
          onClick={toggle}
          className={classNames('close-btn-container d-flex align-items-center cursor-pointer mr-0', {
            'with-action-btn': data?.btnIntlKey,
          })}
        >
          <IconClose color={colors.white} />
        </div>
      </div>
    </Alert>
  )
);

GlobalNotificationPopupUI.propTypes = {
  type: PropTypes.string.isRequired,
  message: PropTypes.shape({
    title: PropTypes.string,
    text: PropTypes.string,
  }),
  visible: PropTypes.bool.isRequired,
  values: PropTypes.shape(),
  toggle: PropTypes.func.isRequired,
  data: PropTypes.shape(),
};

GlobalNotificationPopupUI.defaultProps = {
  message: null,
  values: null,
  data: {},
};

export const NotificationPopup = withCoreComponent(GlobalNotificationPopupCore, GlobalNotificationPopupUI);
