import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { MODAL_SHOWN_CLASS } from '../constants';

export const withModalAlignment = WrappedComponent =>
  class WithModalAlignment extends Component {
    static propTypes = {
      id: PropTypes.string,
      onOpened: PropTypes.func,
      onClosed: PropTypes.func,
      isModalCentered: PropTypes.bool,
      isModalAlignTop: PropTypes.bool,
      updateModalAlignment: PropTypes.func,
    };

    static defaultProps = {
      id: '',
      onOpened: null,
      onClosed: null,
      isModalCentered: null,
      isModalAlignTop: null,
      updateModalAlignment: null,
    }

    mediaBreakpoints = null;

    mobileBreakpoint = null;

    state = {
      isCentered: false,
      isAlignTop: false,
    };

    componentWillUnmount() {
      if (this.mediaBreakpoints) {
        this.mediaBreakpoints.map(breakpoint => breakpoint.removeListener(this.updateModalAlignment));
      }

      if (this.mobileBreakpoint) {
        this.mobileBreakpoint.removeListener(this.updateModalAlignment);
      }

      document.body.classList.remove(MODAL_SHOWN_CLASS);
    }

    setAlignment = () => {
      const isCenteredBreakpoint = this.mediaBreakpoints[0].matches;
      const isAlignTopBreakpoint = this.mediaBreakpoints[1].matches;

      this.setState({
        isCentered: isCenteredBreakpoint && !isAlignTopBreakpoint,
        isAlignTop: isAlignTopBreakpoint,
      });
    };

    updateModalAlignment = (e) => {
      const isMobile = e && e.matches;

      if (!isMobile) {
        const { id } = this.props;

        const modalDialog = document.querySelector(id ? `.dialog-${id}` : '.modal-dialog');

        if (modalDialog) {
          this.mediaBreakpoints = [
            window.matchMedia(`(max-height: ${modalDialog.clientHeight + modalDialog.offsetTop}px)`),
            window.matchMedia(`(max-height: ${modalDialog.clientHeight}px)`),
          ];
          this.mediaBreakpoints.map(breakpoint => breakpoint.addListener(this.setAlignment));
          this.setAlignment();
        }
      }
    };

    onOpened = () => {
      const { onOpened, updateModalAlignment } = this.props;

      if (onOpened) {
        onOpened();
      }

      if (!updateModalAlignment) {
        this.mobileBreakpoint = window.matchMedia('(max-width: 719px)');

        this.mobileBreakpoint.addListener(this.updateModalAlignment);

        this.updateModalAlignment();
      }
    };

    onClosed = () => {
      const { onClosed } = this.props;

      if (onClosed) {
        onClosed();
      }

      if (this.mobileBreakpoint) {
        this.mobileBreakpoint.removeListener(this.updateModalAlignment);
      }

      if (this.mediaBreakpoints) {
        this.mediaBreakpoints.map(breakpoint => breakpoint.removeListener(this.updateModalAlignment));
      }
    }

    render() {
      const { isModalCentered, isModalAlignTop, updateModalAlignment } = this.props;
      const { isCentered, isAlignTop } = this.state;

      return (
        <WrappedComponent
          {...this.props}
          onClosed={this.onClosed}
          onOpened={this.onOpened}
          isModalCentered={isModalCentered || isCentered}
          isModalAlignTop={isModalAlignTop || isAlignTop}
          updateModalAlignment={updateModalAlignment || this.updateModalAlignment}
        />
      );
    }
  };
