import React from 'react';
import PropTypes from 'prop-types';

export const IconArrowLeft = ({ className }) => (
  <svg className={className} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M5.43094 8.70729L9.43105 3.78184C9.56348 3.62436 9.7745 3.62888 9.90239 3.79194C10.0272 3.95101 10.0272 4.20317 9.90239 4.36222L6.13795 8.99747L9.90239 13.6327C10.0325 13.793 10.0325 14.0528 9.90239 14.2131C9.77222 14.3734 9.56121 14.3734 9.43105 14.2131L5.43094 9.28766C5.3008 9.12737 5.3008 8.86756 5.43094 8.70729Z"
      fill="white"
      stroke="white"
      strokeWidth="2"
    />
  </svg>
);

IconArrowLeft.propTypes = {
  className: PropTypes.string,
};
IconArrowLeft.defaultProps = {
  className: '',
};
