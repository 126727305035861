import React from 'react';
import PropTypes from 'prop-types';

export const IconError = ({ className }) => (
  <svg className={className} width="96" height="96" viewBox="0 0 96 96" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M48 96C21.5314 96 0 74.4686 0 48C0 21.5314 21.5314 0 48 0C74.4686 0 96 21.5314 96 48C96 74.4686 74.4686 96 48 96Z"
      fill="#FF2D59"
      fillOpacity="0.12"
    />
    <path
      d="M48.0001 84.2872C27.9916 84.2872 11.7129 68.0086 11.7129 48C11.7129 27.9914 27.9916 11.7128 48.0001 11.7128C68.0087 11.7128 84.2874 27.9914 84.2874 48C84.2874 68.0086 68.0087 84.2872 48.0001 84.2872Z"
      stroke="#FF2D59"
      strokeWidth="3"
    />
    <path
      d="M48.0034 31.1863C49.8606 31.1863 51.3661 32.6918 51.3661 34.549V48C51.3661 49.8572 49.8606 51.3627 48.0034 51.3627C46.1462 51.3627 44.6406 49.8572 44.6406 48V34.549C44.6406 32.6918 46.1462 31.1863 48.0034 31.1863Z"
      fill="#FF2D59"
    />
    <path
      d="M48.0034 58.0878C46.1462 58.0878 44.6406 59.5933 44.6406 61.4505C44.6406 63.3077 46.1462 64.8133 48.0034 64.8133H48.0356C49.8928 64.8133 51.3984 63.3077 51.3984 61.4505C51.3984 59.5933 49.8928 58.0878 48.0356 58.0878H48.0034Z"
      fill="#FF2D59"
    />
  </svg>
);

IconError.propTypes = {
  className: PropTypes.string,
};

IconError.defaultProps = {
  className: null,
};
