import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { FormattedHTMLMessage } from 'react-intl';
import Label from 'reactstrap/lib/Label';
import CustomInput from 'reactstrap/lib/CustomInput';
import classNames from 'classnames';

export const Checkbox = ({
  name, label, intlValues, value, onChange, error, id,
}) => (
  <Fragment>
    <CustomInput
      type="checkbox"
      id={`check-${id ? `${id}-` : ''}${name}`}
      name={name}
      onChange={onChange}
      className={classNames({ 'checkbox-error': !!error })}
      checked={value}
    >
      <Label for={`check-${name}`} className="d-inline text-small" check>
        <FormattedHTMLMessage id={label} values={{ ...intlValues }} />
      </Label>
    </CustomInput>
  </Fragment>
);

Checkbox.propTypes = {
  name: PropTypes.string.isRequired,
  error: PropTypes.shape(),
  value: PropTypes.bool,
  label: PropTypes.string,
  intlValues: PropTypes.shape({}),
  onChange: PropTypes.func,
  id: PropTypes.string,
};

Checkbox.defaultProps = {
  value: false,
  label: null,
  error: null,
  intlValues: null,
  onChange: null,
  id: null,
};
