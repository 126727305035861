import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Form from 'reactstrap/lib/Form';
import Button from 'reactstrap/lib/Button';
import { LOCAL } from 'core/constants';
import { getEnv } from 'core/helpers';

import { withCoreComponent, withModalActions, withRecaptcha } from 'core/hocs';
import { FormattedTag, FormSignIn as FormSignInCore } from 'core/components';

// import { SocialAuthNetworksList } from 'components/social-auth-networks-list/social-auth-networks-list';
import { ButtonWithLoader } from 'components/button-with-loader/button-with-loader';
import { FormElement } from 'components/form-element/form-element';
import { clearLocalStorageData } from 'helpers/local-storage';
// import { IconMail } from 'components/icons/icon-mail/icon-mail';
// import { IconLock } from 'components/icons/icon-lock/icon-lock';

import { MODAL_IDS } from '../../../constants';

import './form-sign-in.scss';

export class FormSignInUI extends Component {
  static propTypes = {
    onSubmit: PropTypes.func.isRequired,
    openModalAndCloseOthers: PropTypes.func.isRequired,
    closeModal: PropTypes.func.isRequired,
    fields: PropTypes.shape().isRequired,
    isInProgress: PropTypes.bool.isRequired,
    isSuccess: PropTypes.bool.isRequired,
    executeRecaptcha: PropTypes.func.isRequired,
  };

  componentDidUpdate({ isSuccess: isPrevSuccess }) {
    const { isSuccess, closeModal } = this.props;

    clearLocalStorageData('vipKYCshown');

    if (isSuccess && !isPrevSuccess) {
      closeModal(MODAL_IDS.SIGN_IN);
    }
  }

  openSignUpModal = () => {
    const { openModalAndCloseOthers } = this.props;
    openModalAndCloseOthers(MODAL_IDS.SIGN_UP);
  };

  openResetPasswordModal = () => {
    const { openModalAndCloseOthers } = this.props;
    openModalAndCloseOthers(MODAL_IDS.RESET_PASSWORD);
  };

  onSubmit = (e) => {
    const { onSubmit, executeRecaptcha } = this.props;

    const isLocal = getEnv('PROJECT_ENV') === LOCAL;

    onSubmit(e, executeRecaptcha, isLocal);
  }

  render() {
    const {
      fields, isInProgress
    } = this.props;

    return (
      <Form
        onSubmit={this.onSubmit}
        className="form-sign-in d-flex flex-column justify-content-between h-100"
      >
        <div>
          <FormattedTag tag="div" id="sign-in" className="modal-custom-title text-center mb-2_5 text-blue-dark" />
          <FormElement
            coreProps={{ item: fields.username }}
            // icon={IconMail}
            autoFocus
          />
          <FormElement
            coreProps={{ item: fields.password }}
            // icon={IconLock}
          />

          <FormattedTag
            tag={ButtonWithLoader}
            isLoading={isInProgress}
            color="secondary"
            className="w-100 mt-2_25"
            type="submit"
            id="sign-in"
          />
        </div>

        <div className="mt-3_5">
          <div className="d-flex pb-1_5">
            <FormattedTag
              tag={Button}
              id="forgot-password"
              color="link-secondary"
              className="flex-grow-1 justify-content-center d-flex"
              onClick={this.openResetPasswordModal}
            />
          </div>

          <div className="d-flex justify-content-center mt-1_5 form-sign-in-link-container align-items-center">
            <FormattedTag className="text-gray-20 font-weight-medium mr-0_5" id="no-account" />
            <FormattedTag
              tag={Button}
              id="sign-up"
              color="link-secondary"
              className="d-flex"
              onClick={this.openSignUpModal}
            />
          </div>
        </div>
      </Form>
    );
  }
}

export const FormSignIn = withCoreComponent(FormSignInCore,
  withModalActions(
    withRecaptcha(FormSignInUI)
  ));
