import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Button from 'reactstrap/lib/Button';
import { Loader } from './loader';

import './button-with-loader.scss';

export const ButtonWithLoader = ({
  isLoading, className, contentClassName, children, ...props
}) => (
  <Button className={classNames('position-relative', className, { 'btn-loading': isLoading })} {...props}>
    {isLoading && <Loader width={40} height={20} className="position-absolute" />}
    <div className={classNames('btn-content', contentClassName)}>{children}</div>
  </Button>
);

ButtonWithLoader.propTypes = {
  className: PropTypes.string,
  contentClassName: PropTypes.string,
  children: PropTypes.node.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

ButtonWithLoader.defaultProps = {
  className: '',
  contentClassName: '',
};

