import React from 'react';
import PropTypes from 'prop-types';
import { ChangeRoute } from 'core/components';
import { withUser } from 'core/hocs';
import { USER_FIELDS } from 'core/constants';

import { HISTORY_STATE_ACTIONS, MODAL_IDS } from '../../constants';

const SignUp = ({ isUserLoggedIn, location: { search } }) => (
  <ChangeRoute
    to={`/${search}`}
    state={{
      action: isUserLoggedIn ? null : HISTORY_STATE_ACTIONS.OPEN_MODAL,
      modalId: MODAL_IDS.SIGN_UP,
    }}
  />
);

SignUp.propTypes = {
  isUserLoggedIn: PropTypes.bool.isRequired,
  location: PropTypes.shape().isRequired,
};

export default withUser(SignUp, [USER_FIELDS.IS_USER_LOGGED_IN]);

