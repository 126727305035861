import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withUser } from 'core/hocs';
import { USER_FIELDS } from 'core/constants';

import { SideModal } from 'components/side-modal/side-modal';
import { SidebarMobile } from 'components/sidebar/sidebar-mobile';
import { SIDE_MODAL_IDS } from '../../constants';

import './side-modal-bar.scss';

const {
  USER_DATA, IS_USER_LOGGED_IN, USER_BALANCE, LOYALTY_POINTS,
} = USER_FIELDS;

const SideModalBarUI = ({
  isUserLoggedIn,
  isSportsbookPage,
  userData,
  userBalance,
  loyaltyPoints,
  pageName,
  params,
}) => (
  <SideModal
    coreProps={{ id: SIDE_MODAL_IDS.SIDE_MODAL_BAR }}
    overlayClassName="d-md-none"
    className={classNames('side-modal-bar', {
      'd-lg-none': isSportsbookPage,
      'd-md-none': !isSportsbookPage,
    })}
    closeClassName="d-none"
  >
    <SidebarMobile
      className="d-flex"
      isUserLoggedIn={isUserLoggedIn}
      userData={userData}
      params={params}
      userBalance={userBalance}
      loyaltyPoints={loyaltyPoints}
      pageName={pageName}
    />
  </SideModal>
);

SideModalBarUI.propTypes = {
  isUserLoggedIn: PropTypes.bool.isRequired,
  isSportsbookPage: PropTypes.bool.isRequired,
  userData: PropTypes.shape(),
  userBalance: PropTypes.shape(),
  loyaltyPoints: PropTypes.shape(),
  pageName: PropTypes.string.isRequired,
  params: PropTypes.string,
};

SideModalBarUI.defaultProps = {
  userData: null,
  userBalance: null,
  loyaltyPoints: null,
  params: '',
};

export const SideModalBar = withUser(SideModalBarUI, [
  USER_DATA, IS_USER_LOGGED_IN, USER_BALANCE, LOYALTY_POINTS,
]);
