import React from 'react';
import PropTypes from 'prop-types';
import colors from 'src/customizations/js/color-variables';

export const IconAlert = ({ className, color, outlined }) =>
  outlined ? (
    <svg
      className={className}
      width="44"
      height="44"
      viewBox="0 0 44 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22 44C9.86855 44 0 34.1314 0 22C0 9.86855 9.86855 0 22 0C34.1315 0 44 9.86855 44 22C44 34.1314 34.1315 44 22 44Z"
        fill="#FF2D59"
        fillOpacity="0.12"
      />
      <path
        d="M22 38.6315C12.8294 38.6315 5.36835 31.1704 5.36835 21.9998C5.36835 12.8292 12.8294 5.36816 22 5.36816C31.1706 5.36816 38.6316 12.8292 38.6316 21.9998C38.6316 31.1704 31.1706 38.6315 22 38.6315Z"
        stroke="#FF2D59"
        strokeWidth="1.375"
      />
      <g clipPath="url(#icon-alert-clip0_4406_25242)">
        <path
          d="M22.0018 14.2939C22.853 14.2939 23.5431 14.984 23.5431 15.8352V22.0002C23.5431 22.8515 22.853 23.5415 22.0018 23.5415C21.1506 23.5415 20.4606 22.8515 20.4606 22.0002V15.8352C20.4606 14.984 21.1506 14.2939 22.0018 14.2939Z"
          fill="#FF2D59"
        />
        <path
          d="M22.0018 26.6238C21.1506 26.6238 20.4606 27.3138 20.4606 28.1651C20.4606 29.0163 21.1506 29.7063 22.0018 29.7063H22.0166C22.8678 29.7063 23.5579 29.0163 23.5579 28.1651C23.5579 27.3138 22.8678 26.6238 22.0166 26.6238H22.0018Z"
          fill="#FF2D59"
        />
      </g>
    </svg>
  ) : (
    <svg
      className={className}
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle r="10" transform="matrix(-1 0 0 1 10 10.5)" fill={color} />
      <g clipPath="url(#icon-alert)">
        <path
          d="M10.0012 4.61523C10.6512 4.61523 11.1781 5.14218 11.1781 5.7922V10.5C11.1781 11.1501 10.6512 11.677 10.0012 11.677C9.35116 11.677 8.82422 11.1501 8.82422 10.5V5.7922C8.82422 5.14218 9.35116 4.61523 10.0012 4.61523Z"
          fill="white"
        />
        <path
          d="M10.0012 14.0308C9.35116 14.0308 8.82422 14.5577 8.82422 15.2077C8.82422 15.8577 9.35116 16.3847 10.0012 16.3847H10.0125C10.6625 16.3847 11.1894 15.8577 11.1894 15.2077C11.1894 14.5577 10.6625 14.0308 10.0125 14.0308H10.0012Z"
          fill="white"
        />
      </g>
    </svg>
  );

IconAlert.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string,
  outlined: PropTypes.bool,
};

IconAlert.defaultProps = {
  className: null,
  color: colors.statusRed,
  outlined: false,
};
