import React from 'react';
import PropTypes from 'prop-types';

export const IconChecked = ({ className }) => (
  <svg className={className} xmlns="http://www.w3.org/2000/svg" width="8" height="6" viewBox="0 0 8 6">
    <path fill="#FFF" fillRule="nonzero" d="M2.867 3.723L1.202 2.146 0 3.285 2.867 6 8 1.138 6.798 0z" />
  </svg>
);

IconChecked.propTypes = {
  className: PropTypes.string,
};
IconChecked.defaultProps = {
  className: '',
};
