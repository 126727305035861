module.exports = {
  email: {
    type: 'text',
    placeholder: 'form.fields.email',
    validation: [
      { type: 'required' },
      { type: 'email' },
    ],
  },
};
