import React from 'react';
import PropTypes from 'prop-types';

export const IconSlots = ({ className }) => (
  <svg className={className} width="37" height="36" viewBox="0 0 37 36" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#icons-header-clip-path-1)">
      <path d="M23.6433 28C21.3218 28 19.4331 26.1113 19.4331 23.7898C19.4331 22.6572 19.8864 21.7403 20.7439 21.1384C21.468 20.63 22.4706 20.3614 23.6433 20.3614C24.8159 20.3614 25.8185 20.63 26.5426 21.1384C27.4002 21.7403 27.8535 22.6572 27.8535 23.7898C27.8535 26.1113 25.9648 28 23.6433 28Z" fill="url(#icons-header-linear-gradient-1)" />
      <path d="M13.3571 28C11.0356 28 9.14697 26.1113 9.14697 23.7898C9.14697 22.6572 9.60025 21.7403 10.4579 21.1384C11.1819 20.63 12.1845 20.3614 13.3572 20.3614C14.5298 20.3614 15.5324 20.63 16.2565 21.1384C17.1141 21.7403 17.5674 22.6572 17.5674 23.7898C17.5674 26.1113 15.6787 28 13.3571 28Z" fill="url(#icons-header-linear-gradient-1)" />
      <path d="M16.4312 13.8646C16.2674 13.8107 16.1165 13.7346 15.9808 13.6409C15.5592 15.0437 14.8198 17.18 13.7339 19.198C14.169 19.2173 14.585 19.2702 14.978 19.3555C15.9895 17.393 16.6806 15.4002 17.0904 14.0379C16.724 13.9583 16.4834 13.8818 16.4312 13.8646Z" fill="url(#icons-header-linear-gradient-1)" />
      <path d="M19.9861 14.1603C19.6445 14.2128 19.2883 14.2398 18.9177 14.2398C18.7381 14.2398 18.5635 14.2333 18.395 14.2223C18.7001 14.5176 19.0278 14.8484 19.3642 15.21C20.3267 16.2445 21.4998 17.6852 22.3288 19.2959C22.7278 19.2282 23.1483 19.1925 23.5864 19.1896C22.8597 17.6361 21.7338 16.0353 20.2228 14.4112C20.1432 14.3257 20.0644 14.2423 19.9861 14.1603Z" fill="url(#icons-header-linear-gradient-1)" />
      <path d="M18.9179 13.0671C17.781 13.0671 16.86 12.7711 16.7977 12.7506C16.5145 12.6575 16.3463 12.3667 16.4068 12.0747C16.428 11.9726 16.9467 9.56204 18.8518 8.5272C20.7405 7.50124 23.1737 8.28262 23.2764 8.3164C23.5596 8.40955 23.7279 8.70037 23.6674 8.99232C23.6462 9.09442 23.1275 11.505 21.2224 12.5399C20.4803 12.943 19.6538 13.0671 18.9179 13.0671Z" fill="url(#icons-header-linear-gradient-1)" />
    </g>
  </svg>
);

IconSlots.propTypes = {
  className: PropTypes.string,
};

IconSlots.defaultProps = {
  className: '',
};
