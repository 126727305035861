import { Component } from 'react';
import * as R from 'ramda';
import PropTypes from 'prop-types';
import { FORM_ACTIONS } from 'core/constants';
import { getEnv } from 'core/helpers';
import { getTimezone } from 'helpers/date';
import { withFormsData } from '../../hocs/with-forms-data';

const CIO_SITE_ID = getEnv('CIO_SITE_ID');

const DATA_FIELDS = {
  ID: 'id',
  EMAIl: 'email',
  MOBILE_PHONE: 'mobilePhone',
  CREATED_AT: 'created_at',
  USER_TIMEZONE: 'user_timezone',
};

class CioTrackerUI extends Component {
  static propTypes = {
    userData: PropTypes.shape().isRequired,
    isUserLoggedIn: PropTypes.bool.isRequired,
    isUserRegistered: PropTypes.bool.isRequired,
    signUp: PropTypes.shape().isRequired,
  };

  componentDidUpdate(prevProps) {
    const { isUserRegistered: isUserRegisteredPrev, isUserLoggedIn: isUserLoggedInPrev } = prevProps;
    const {
      isUserRegistered,
      isUserLoggedIn,
      signUp: {
        formData: { email: signUpEmail, mobile: signUpMobile },
      },
      userData: { partyId, email, mobilePhone },
    } = this.props;

    const dataToSend = {
      [DATA_FIELDS.ID]: partyId || '',
      [DATA_FIELDS.CREATED_AT]: Math.round(Date.now() / 1000), // timestamp in seconds
      [DATA_FIELDS.EMAIl]: signUpEmail || email || '',
      [DATA_FIELDS.MOBILE_PHONE]: signUpMobile || mobilePhone || '',
      [DATA_FIELDS.USER_TIMEZONE]: getTimezone(),
    };

    if (isUserLoggedIn && isUserLoggedIn !== isUserLoggedInPrev) {
      const signInData = R.omit([DATA_FIELDS.CREATED_AT], dataToSend);

      this.trackUser(signInData);

      return;
    }

    if (isUserRegistered && isUserRegistered !== isUserRegisteredPrev) {
      this.trackUser(dataToSend);
    }

    if (!isUserLoggedIn && isUserLoggedIn !== isUserLoggedInPrev) {
      this.resetUser();
    }
  }

  getCioInstance = () => {
    if (!CIO_SITE_ID) {
      console.log('Customer IO: site id not found!');

      return;
    }

    let cioInstance;

    if (window && window._cio) {
      try {
        cioInstance = window._cio;
      } catch (err) {
        console.log(err);
      }
    }

    // eslint-disable-next-line consistent-return
    return cioInstance;
  };

  trackUser = (userInfo) => {
    // if (!userInfo?.[DATA_FIELDS.ID]) {
    //   console.log('Customer IO: field partyId is empty!');
    // }
    if (!userInfo?.[DATA_FIELDS.EMAIl]) {
      console.log('Customer IO: field email is empty!');
    }

    try {
      this.getCioInstance().identify(userInfo);
    } catch (err) {
      console.log(err);
    }
  };

  resetUser = () => {
    try {
      this.getCioInstance().reset();
    } catch (err) {
      console.log(err);
    }
  }

  render() {
    return null;
  }
}

export const CioTracker = withFormsData(CioTrackerUI, [FORM_ACTIONS.SIGN_UP]);
