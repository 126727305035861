import React from 'react';
import PropTypes from 'prop-types';

export const IconGiftGray = ({ className }) => (
  <svg className={className} width="64px" height="67px" viewBox="0 0 64 67" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
    <g id="icon-gift-gray" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="icon-gift-gray-group-12">
        <path d="M4,27 L60,27 L60,56.7444592 C60,60.3105342 59.6286976,61.6036791 58.9314692,62.9073828 C58.2342408,64.2110865 57.2110865,65.2342408 55.9073828,65.9314692 C54.6036791,66.6286976 53.3105342,67 49.7444592,67 L14.2555408,67 C10.6894658,67 9.39632089,66.6286976 8.09261719,65.9314692 C6.78891348,65.2342408 5.76575919,64.2110865 5.06853082,62.9073828 C4.37130244,61.6036791 4,60.3105342 4,56.7444592 L4,27 L4,27 Z" id="icon-gift-gray-rectangle-copy-3" fill="url(#icon-gift-gray-1)" />
        <rect id="icon-gift-gray-rectangle" fill="url(#icon-gift-gray-2)" x="28" y="27" width="8" height="40" />
        <rect id="icon-gift-gray-rectangle" fill="url(#icon-gift-gray-3)" x="4" y="27" width="56" height="13" />
        <g id="icon-gift-gray-group-11" transform="translate(0.000000, 0.657594)">
          <g id="icon-gift-gray-group-10" transform="translate(10.093267, 0.000000)" fill="url(#icon-gift-gray-4)">
            <path d="M40.8980881,1.67155122 C40.9531566,1.74892479 41.0045201,1.82886964 41.0520048,1.91111568 L43.7527158,6.58888432 C44.5811429,8.02376219 44.0895175,9.85853341 42.6546396,10.6869605 C42.5723936,10.7344453 42.4879391,10.7779979 42.4015616,10.8174711 L26.2158269,18.2141016 L24.5569531,15.3418783 L19.7509531,15.3418783 L18.0932667,18.2141016 L1.90753202,10.8174711 C1.82115447,10.7779979 1.73670002,10.7344453 1.65445398,10.6869605 C0.272719734,9.88921589 -0.234338756,8.15829073 0.469743482,6.7498894 L0.556377768,6.58888432 L3.25708875,1.91111568 C3.30457352,1.82886964 3.35593695,1.74892479 3.4110055,1.67155122 C4.33478907,0.373597376 6.10212118,0.0320174844 7.43673455,0.861752005 L7.59472942,0.966956212 L16.962532,7.6346671 C17.6935586,6.84021246 18.7420115,6.34240644 19.9067333,6.34240644 L23.9067333,6.34240644 C25.1753848,6.34240644 26.3060921,6.93301604 27.0389011,7.85428122 L36.7143642,0.966956212 C38.0642362,0.00622129139 39.9373532,0.321679222 40.8980881,1.67155122 Z" id="icon-gift-gray-path" />
          </g>
          <path d="M6,14.3424064 L58,14.3424064 C61.3137085,14.3424064 64,17.0286979 64,20.3424064 L64,24.3424064 C64,25.4469759 63.1045695,26.3424064 62,26.3424064 L2,26.3424064 C0.8954305,26.3424064 0,25.4469759 0,24.3424064 L0,20.3424064 C0,17.0286979 2.6862915,14.3424064 6,14.3424064 Z" id="icon-gift-gray-rectangle" fill="url(#icon-gift-gray-5)" />
          <rect id="icon-gift-gray-rectangle" fill="url(#icon-gift-gray-6)" x="28" y="14.3424064" width="8" height="12" />
        </g>
      </g>
    </g>
  </svg>
);

IconGiftGray.propTypes = {
  className: PropTypes.string,
};

IconGiftGray.defaultProps = {
  className: '',
};
