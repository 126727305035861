import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Button from 'reactstrap/lib/Button';
import { FormattedTag } from 'core/components';

import { PaymentMethodCard } from 'components/payment-method-card/payment-method-card';
import './deposit-method.scss';

export const DepositMethod = ({
  className, methodType, handleButtonClickMobile, handleButtonClickDesktop,
}) =>
  (
    <div
      className={classNames('deposit-method', className)}
    >
      <PaymentMethodCard
        paymentMethod={methodType}
        isSmall
      />
      <div className="pl-2 d-flex flex-column method-headline text-large">
        <FormattedTag id={`profile.deposit-new.payment-method.${methodType}`} isHtml />
        <Button color="link" onClick={handleButtonClickMobile} className="d-sm-none text-left p-0">
          <FormattedTag id="change" isHtml />
        </Button>
        <Button color="link" onClick={handleButtonClickDesktop} className="d-none d-sm-block text-left p-0">
          <FormattedTag id="change" isHtml />
        </Button>
      </div>
    </div>
  );

DepositMethod.propTypes = {
  className: PropTypes.string,
  methodType: PropTypes.string.isRequired,
  handleButtonClickMobile: PropTypes.func,
  handleButtonClickDesktop: PropTypes.func,
};

DepositMethod.defaultProps = {
  className: '',
  handleButtonClickMobile: () => null,
  handleButtonClickDesktop: () => null,

};
