import React from 'react';
import PropTypes from 'prop-types';

export const IconGreenDot = ({ className }) => (
  <svg className={className} width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="7.5" cy="7.5" r="3.5" fill="url(#icon-green-dot)" />
    <circle opacity="0.5" cx="7.5" cy="7.5" r="7" stroke="#254096" />
    <defs />
  </svg>
);

IconGreenDot.propTypes = {
  className: PropTypes.string,
};

IconGreenDot.defaultProps = {
  className: '',
};
