import React, { Component } from 'react';
import * as R from 'ramda';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { withCoreComponent } from 'core/hocs';
import { FormattedTag, TournamentsBannersList as TournamentsBannersListCore } from 'core/components';
import { isEmptyOrNil } from 'core/helpers';
import { TournamentMenuItem } from '../../tournament-menu-item/tournament-menu-item';
import { IconArrowUp } from '../../icons/icon-arrow-down-v2/icon-arrow-up';
import { IconGreenDot } from '../../icons/icon-green-dot/icon-green-dot';
import { IconTournamentsActive } from '../../icons/icon-tournaments-active/icon-tournaments-active';

export class TournamentsDropdownMenuItemUI extends Component {
  state = {
    isDropdownOpen: false,
  };

  toggleDropdown = () => {
    this.setState(({ isDropdownOpen }) => ({
      isDropdownOpen: !isDropdownOpen,
    }));
  };

  renderIcon = () => {
    const { Icon } = this.props;

    if (this.state.isDropdownOpen) {
      return <IconTournamentsActive className="mr-2" />;
    }

    return Icon ? <Icon className="menu-icon mr-2" /> : null;
  };

  render() {
    const { items, translationKey, onClick } = this.props;
    const { isDropdownOpen } = this.state;
    const tournamentsIds = R.values(items);

    if (isEmptyOrNil(items)) {
      return null;
    }

    return (
      <>
        <div
          className={classNames(
            'menu-item py-1_5 d-flex align-items-center justify-content-between font-weight-semi-bold cursor-pointer',
            { 'active-dropdown': isDropdownOpen },
          )}
          onClick={this.toggleDropdown}
        >
          <div>
            {this.renderIcon()}

            <FormattedTag
              id={translationKey}
              className="menu-item-dropdown"
            />
          </div>

          <IconArrowUp className={classNames({ 'rotate-180': !isDropdownOpen })} />
        </div>

        <div className={classNames('collapsable', { shown: isDropdownOpen })}>
          <div className="collapsable-inner tournaments-links-container">
            {tournamentsIds.map(id => (
              <TournamentMenuItem
                className="tournament-item text-decoration-none text-white d-block ml-3_75 mb-3"
                coreProps={{ id }}
                itemIcon={IconGreenDot}
                itemIconClassName="mr-1_5"
                onClick={onClick}
              />
            ))}
          </div>
        </div>
      </>
    );
  }
}

TournamentsDropdownMenuItemUI.propTypes = {
  Icon: PropTypes.func,
  items: PropTypes.shape(),
  translationKey: PropTypes.string,
  onClick: PropTypes.func,
};

TournamentsDropdownMenuItemUI.defaultProps = {
  Icon: undefined,
  items: {},
  translationKey: '',
  onClick: () => {
  },
};

export const TournamentsDropdownMenuItem = withCoreComponent(
  TournamentsBannersListCore, TournamentsDropdownMenuItemUI,
);
