import React from 'react';
import { ModalWindow } from 'components/modal-window/modal-window';
import { FormResetPassword } from 'components/forms/form-reset-password/form-reset-password';

import { MODAL_IDS } from '../../../constants';

import './reset-password-modal.scss';

export const ResetPasswordModal = () => (
  <ModalWindow
    id={MODAL_IDS.RESET_PASSWORD}
    coreProps={{ id: MODAL_IDS.RESET_PASSWORD }}
    autoFocus={false}
    className="reset-password-modal"
    isModalCentered
    fade
  >
    <FormResetPassword />
  </ModalWindow>
);
