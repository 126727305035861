import { addLocaleData } from 'react-intl';
// import * as R from 'ramda';
// import dayjs from 'dayjs';

import intlEN from 'react-intl/locale-data/en';
import intlES from 'react-intl/locale-data/es';

import 'dayjs/locale/en';
import 'dayjs/locale/es';

// import dayjsES from 'dayjs/locale/es';

// Hack! In order to add unsupported locales to `react-intl`
// example:
// const intlESLocaleEsMx = R.find(R.propEq('locale', 'es-MX'))(intlES || {});
// const intlESTarget = R.append({ ...intlESLocaleEsMx, locale: 'mx' })(intlES);

addLocaleData([
  ...intlEN,
  ...intlES,
]);

// Hack! In order to add unsupported locales to `dayjs`
// example:
// dayjs.locale('mx', { ...dayjsES, name: 'mx' });
