import React from 'react';
import PropTypes from 'prop-types';
import colors from 'customizations/js/color-variables';

export const IconEye = ({
  isOpen, className, color,
}) => isOpen
  ? (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="16"
    >
      <path
        fill={color}
        d="M10.898 0c4.237 0 7.799 2.421 10.643 7.164l.255.424-.56.77c-3.337 4.482-6.778 6.766-10.338 6.766-3.56 0-7.001-2.284-10.337-6.767L0 7.588l.255-.424C3.099 2.42 6.66 0 10.898 0zm0 1.5C7.36 1.5 4.34 3.474 1.8 7.512c3.066 4.103 6.103 6.112 9.097 6.112 2.906 0 5.852-1.893 8.826-5.756l.27-.356-.218-.341C17.28 3.363 14.333 1.5 10.898 1.5zm0 1.3a4.75 4.75 0 1 1 0 9.5 4.75 4.75 0 0 1 0-9.5zm0 1.5a3.25 3.25 0 1 0 0 6.5 3.25 3.25 0 0 0 0-6.5z"
      />
    </svg>
  ) : (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="18"
    >
      <path
        fill={color}
        d="M3.522.354l14.85 14.849-1.061 1.06-1.61-1.609c-1.578.944-3.178 1.42-4.803 1.42-3.56 0-7.001-2.284-10.337-6.766L0 8.538l.255-.424C1.48 6.07 2.84 4.456 4.33 3.282L2.46 1.414 3.522.354zm-1.72 8.108c3.065 4.103 6.102 6.112 9.096 6.112 1.227 0 2.462-.338 3.703-1.019l-1.088-1.089a4.75 4.75 0 0 1-6.58-6.581L5.399 4.35C4.107 5.334 2.908 6.702 1.8 8.462zM10.897.951c4.237 0 7.799 2.42 10.643 7.163l.255.425-.56.769c-1.051 1.411-2.112 2.604-3.183 3.577l-1.062-1.062c.908-.817 1.82-1.817 2.733-3.004l.27-.357-.218-.34c-2.495-3.81-5.442-5.672-8.877-5.672-.98 0-1.92.151-2.822.456L6.912 1.744A10.175 10.175 0 0 1 10.898.95zM7.648 8.5a3.25 3.25 0 0 0 4.77 2.873L8.026 6.979c-.24.454-.377.971-.377 1.521zm3.25-4.75a4.75 4.75 0 0 1 4.446 6.426l-1.227-1.228A3.25 3.25 0 0 0 10.45 5.28L9.222 4.055a4.74 4.74 0 0 1 1.676-.304z"
      />
    </svg>
  );

IconEye.propTypes = {
  isOpen: PropTypes.bool,
  className: PropTypes.string,
  color: PropTypes.string,
};

IconEye.defaultProps = {
  isOpen: true,
  className: '',
  color: colors.gray10,
};

