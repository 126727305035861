import React from 'react';
import * as R from 'ramda';
import urlParse from 'url-parse';
import {
  getEnv,
  capitalizeFirstLetter,
  removeLocaleFromUrlPath,
  convertStringifiedBoolean, isEmptyOrNil,
} from 'core/helpers';
import {
  GAME_CATEGORY_FILTER,
  GAMES_CATEGORY_PAGE,
  LOCALE_KEYS,
  LOCALES,
} from 'core/constants';

import { PAGE_NAMES } from '../../constants';

const domain = getEnv('DOMAIN');
const { hostname } = urlParse(domain);
const isMirrorDomain = convertStringifiedBoolean(getEnv('IS_MIRROR_DOMAIN') || false);

const domainText = isMirrorDomain ? 'crasher' : 'crasher.com';
const capitalizedDomainText = isMirrorDomain ? domainText : capitalizeFirstLetter(domainText);

const metaTypes = {
  TITLE: 'title',
  DESC: 'description',
};

const prepareIntlMessageObj = (
  intlKeyPart,
  metaType = metaTypes.TITLE,
  defaultMessage = '',
) => ({
  id: `seo.${metaType}.page.${intlKeyPart}`,
  ...(defaultMessage && { defaultMessage }),
});

const prepareMirrorDomainMaskIntlKey = (
  host,
  metaType = metaTypes.TITLE,
) => `seo.${metaType}.mirror-domain-mask.${host}`;

export const getMetaByPage = (
  page,
  locale,
  location,
  intl,
  values = {},
  options = {},
) => {
  const meta = [];
  const { pathname } = location;
  const isHomePage = pathname === '/';
  const {
    formatMessage,
    messages: intlMessages,
  } = intl;

  let pageCommonMetaIntlKeyPart = '';

  // If title and desc key parts are different then the following vars should be used
  let pageTitleMetaIntlKeyPart = '';
  let pageDescMetaIntlKeyPart = '';

  const targetValues = {
    ...values,
    domain: domainText,
    capitalizedDomain: capitalizedDomainText,
  };

  const metaInfo = {
    title: '',
    description: '',
    canonical: '',
    keywords: '',
    currentUrl: '',
    pageType: 'website',
    image: '',
  };

  const { DESC } = metaTypes;

  switch (page) {
    // HOME Page
    case PAGE_NAMES.HOME: {
      // If HOME page's url is with NO locale then uses special meta data
      if (isHomePage) {
        pageCommonMetaIntlKeyPart = 'main-without-locale';

        // Sets canonical which refers to the "Home page" with the "RU" locale
        metaInfo.canonical = `${hostname}/${LOCALE_KEYS.RU}`;
      } else {
        pageCommonMetaIntlKeyPart = 'main';
      }

      break;
    }

    // GAMES Page
    case PAGE_NAMES.GAMES: {
      const gamesCategory = targetValues.category || GAME_CATEGORY_FILTER.ALL;

      switch (gamesCategory) {
        // GAMES.ALL Page
        case GAME_CATEGORY_FILTER.ALL: {
          pageCommonMetaIntlKeyPart = 'games.all';
          break;
        }

        // GAMES.SLOTS Page
        case GAME_CATEGORY_FILTER.SLOTS: {
          pageCommonMetaIntlKeyPart = 'games.slot';
          break;
        }

        // GAMES.JACKPOTS Page
        case GAME_CATEGORY_FILTER.JACKPOTS: {
          pageCommonMetaIntlKeyPart = 'games.jackpots';
          break;
        }

        // GAMES.LIVE Page
        case GAME_CATEGORY_FILTER.LIVE_CASINO: {
          pageCommonMetaIntlKeyPart = 'games.live';
          break;
        }

        // GAMES.ROULETTE Page
        case GAME_CATEGORY_FILTER.ROULETTE: {
          pageCommonMetaIntlKeyPart = 'games.roulette';
          break;
        }

        // GAMES.CARD Page
        case GAME_CATEGORY_FILTER.CARD_GAMES: {
          pageCommonMetaIntlKeyPart = 'games.card';
          break;
        }

        case GAME_CATEGORY_FILTER.SPORT: {
          pageCommonMetaIntlKeyPart = 'games.sport';
          break;
        }

        // Other GAMES Pages
        default: {
          //
        }
      }

      break;
    }

    // CATEGORY Page
    case PAGE_NAMES.CATEGORY: {
      const { category } = targetValues;

      switch (category) {
        // CATEGORY.RECOMMENDED Page
        case GAMES_CATEGORY_PAGE.RECOMMENDED: {
          pageCommonMetaIntlKeyPart = 'games-category.recommended';
          break;
        }

        // CATEGORY.TOP_GAMES Page
        case GAMES_CATEGORY_PAGE.TOP_GAMES: {
          pageCommonMetaIntlKeyPart = 'games-category.top-games';
          break;
        }

        // CATEGORY.CRASH_GAMES Page
        case GAMES_CATEGORY_PAGE.CRASH_GAMES: {
          pageCommonMetaIntlKeyPart = 'games-category.crash-games';
          break;
        }

        // CATEGORY.NEWEST Page
        case GAMES_CATEGORY_PAGE.NEWEST: {
          pageCommonMetaIntlKeyPart = 'games-category.newest';
          break;
        }

        // CATEGORY.JACKPOTS Page
        case GAMES_CATEGORY_PAGE.JACKPOTS: {
          pageCommonMetaIntlKeyPart = 'games-category.jackpots';
          break;
        }

        // CATEGORY.RECENTLY_PLAYED Page
        case GAMES_CATEGORY_PAGE.RECENTLY_PLAYED: {
          pageCommonMetaIntlKeyPart = 'games-category.recently-played';
          break;
        }

        // Other CATEGORY Pages
        default: {
          //
        }
      }

      break;
    }

    // PROVIDER Page
    case PAGE_NAMES.PROVIDER: {
      const { providerSlug } = values;

      pageTitleMetaIntlKeyPart = 'provider';
      pageDescMetaIntlKeyPart = [
        'booming-games',
        'evolution',
        'ezugi',
      ].includes(providerSlug) ? `provider.${providerSlug}` : 'provider';

      break;
    }

    // PROMOS Page
    case PAGE_NAMES.PROMOS: {
      pageCommonMetaIntlKeyPart = 'promos';
      break;
    }

    // GAME TAGS PAGE
    case PAGE_NAMES.GAME_TAGS: {
      pageCommonMetaIntlKeyPart = 'game-tags';
      break;
    }

    // LOYALTY Page
    case PAGE_NAMES.LOYALTY: {
      pageCommonMetaIntlKeyPart = 'loyalty';
      break;
    }

    // PAYMENTS Page
    case PAGE_NAMES.PAYMENTS: {
      pageCommonMetaIntlKeyPart = 'payments';
      break;
    }

    // TERMS Page
    case PAGE_NAMES.TERMS: {
      pageCommonMetaIntlKeyPart = 'terms';
      break;
    }

    // RESPONSIBLE_GAMING Page
    case PAGE_NAMES.RESPONSIBLE_GAMING: {
      pageCommonMetaIntlKeyPart = 'responsible-gaming';
      break;
    }

    // RESTRICTED COUNTRY Page
    case PAGE_NAMES.RESTRICTED_COUNTRY: {
      pageCommonMetaIntlKeyPart = 'restricted-country';
      break;
    }

    // BONUS-TERMS Page
    case PAGE_NAMES.BONUS_TERMS: {
      pageCommonMetaIntlKeyPart = 'bonus-terms';
      break;
    }

    // BONUS-RESET_PASSWORD Page
    case PAGE_NAMES.RESET_PASSWORD: {
      pageCommonMetaIntlKeyPart = 'reset-password';
      break;
    }

    // PRIVACY-POLICY Page
    case PAGE_NAMES.PRIVACY: {
      pageCommonMetaIntlKeyPart = 'privacy-policy';
      break;
    }

    // GAME Page
    case PAGE_NAMES.GAME: {
      pageCommonMetaIntlKeyPart = 'game';
      break;
    }

    // PROFILE Page
    case PAGE_NAMES.PROFILE: {
      pageCommonMetaIntlKeyPart = 'profile';
      break;
    }

    // WALLET Page
    case PAGE_NAMES.WALLET: {
      pageCommonMetaIntlKeyPart = 'wallet';
      break;
    }

    // BANKING_HISTORY Page
    case PAGE_NAMES.BANKING_HISTORY: {
      pageCommonMetaIntlKeyPart = 'banking-history';
      break;
    }

    // GIFTS Page
    case PAGE_NAMES.GIFTS: {
      pageCommonMetaIntlKeyPart = 'gifts';
      break;
    }

    // TOURNAMENT Page
    case PAGE_NAMES.TOURNAMENT: {
      pageCommonMetaIntlKeyPart = 'tournament';
      break;
    }

    // PARTNERS Page
    case PAGE_NAMES.PARTNERS: {
      pageCommonMetaIntlKeyPart = 'partners';
      break;
    }

    // ABOUT Page
    case PAGE_NAMES.ABOUT: {
      pageCommonMetaIntlKeyPart = 'about';
      break;
    }

    // AFFILIATE Page
    case PAGE_NAMES.AFFILIATE: {
      pageCommonMetaIntlKeyPart = 'affiliate';
      break;
    }

    // TOURNAMENTS Page
    case PAGE_NAMES.TOURNAMENTS: {
      pageCommonMetaIntlKeyPart = 'tournaments.all';
      break;
    }

    // REFUND Page
    case PAGE_NAMES.REFUND: {
      pageCommonMetaIntlKeyPart = 'refund-policy';
      break;
    }

    // AML Page
    case PAGE_NAMES.AML: {
      pageCommonMetaIntlKeyPart = 'anti-money-laundering';
      break;
    }

    // NOT FOUND (404) Page
    case PAGE_NAMES.NOT_FOUND: {
      pageCommonMetaIntlKeyPart = 'not-found';
      break;
    }

    // MAINTENANCE Page
    case PAGE_NAMES.MAINTENANCE: {
      pageCommonMetaIntlKeyPart = 'maintenance';
      break;
    }

    // FAQ Page
    case PAGE_NAMES.FAQ: {
      pageCommonMetaIntlKeyPart = 'faq';
      break;
    }

    // VIP Page
    case PAGE_NAMES.VIP: {
      pageCommonMetaIntlKeyPart = 'vip';
      break;
    }

    case PAGE_NAMES.SPORTSBOOK: {
      pageCommonMetaIntlKeyPart = 'sportsbook';
      break;
    }

    case PAGE_NAMES.IN_PLAY: {
      pageCommonMetaIntlKeyPart = 'in-play';
      break;
    }

    // SELF-EXCLUSION Page
    case PAGE_NAMES.SELF_EXCLUSION: {
      pageCommonMetaIntlKeyPart = 'self-exclusion';
      break;
    }

    // DISPUTE-RESOLUTION Page
    case PAGE_NAMES.DISPUTE_RESOLUTION: {
      pageCommonMetaIntlKeyPart = 'dispute-resolution';
      break;
    }

    // FAIRNESS-AND-RNG Page
    case PAGE_NAMES.FAIRNESS_AND_RNG: {
      pageCommonMetaIntlKeyPart = 'fairness-and-rng';
      break;
    }

    // KYC Page
    case PAGE_NAMES.KYC: {
      pageCommonMetaIntlKeyPart = 'kyc';
      break;
    }

    // License Page
    case PAGE_NAMES.LICENSE: {
      pageCommonMetaIntlKeyPart = 'license';
      break;
    }

    // Other Pages
    default: {
      //
    }
  }

  if (pageCommonMetaIntlKeyPart) {
    metaInfo.title = formatMessage(
      prepareIntlMessageObj(pageCommonMetaIntlKeyPart),
      targetValues,
    );

    metaInfo.description = formatMessage(
      prepareIntlMessageObj(pageCommonMetaIntlKeyPart, DESC),
      targetValues,
    );
  }

  if (pageTitleMetaIntlKeyPart) {
    metaInfo.title = formatMessage(
      prepareIntlMessageObj(pageTitleMetaIntlKeyPart),
      targetValues,
    );
  }

  if (pageDescMetaIntlKeyPart) {
    metaInfo.description = formatMessage(
      prepareIntlMessageObj(pageDescMetaIntlKeyPart, DESC),
      targetValues,
    );
  }

  // Gets "title" and "description" mirror domain masks and applies them
  // to the corresponding existing meta data
  if (isMirrorDomain) {
    const [
      titleMirrorMask,
      descriptionMirrorMask,
    ] = R.compose(
      R.map(R.trim),
      R.juxt([
        R.propOr('', prepareMirrorDomainMaskIntlKey(hostname)),
        R.propOr('', prepareMirrorDomainMaskIntlKey(hostname, DESC)),
      ])
    )(intlMessages);

    if (!isEmptyOrNil(titleMirrorMask)) {
      metaInfo.title = `${titleMirrorMask} ${metaInfo.title}`;
    }

    if (!isEmptyOrNil(descriptionMirrorMask)) {
      metaInfo.description = `${descriptionMirrorMask} ${metaInfo.description}`;
    }
  }

  meta.push(...[
    // Main meta data
    <title key="title">{metaInfo.title}</title>,
    <meta key="description" name="description" content={metaInfo.description} />,
    <meta key="keywords" name="keywords" content={metaInfo.keywords} />,
  ]);

  // Adds `canonical` data
  if (metaInfo.canonical) {
    meta.push(<link key="canonical" rel="canonical" href={metaInfo.canonical} />);
  }

  // Adds `noindex` data
  if (options.indexing === false) {
    meta.push(<meta key="robots" name="robots" content="noindex" />);
  }

  const targetPathname = isHomePage ? '' : removeLocaleFromUrlPath(pathname);

  LOCALES.forEach(localeCode => (
    meta.push(
      <link
        key={`alternate-hrefLang-${localeCode}`}
        rel="alternate"
        hrefLang={localeCode}
        href={`${domain}/${localeCode}${targetPathname}`}
      />
    )
  ));

  return meta;
};
