import * as R from 'ramda';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { parse as parseQueryString } from 'qs';
import { withRouter } from 'react-router-dom';
import { ChangeRoute } from 'core/components';
import { withSignUpConfirmation } from 'core/hocs';
import { GA } from '../../helpers/ga';
import { HISTORY_STATE_ACTIONS } from '../../constants';
import { YM } from '../../helpers/ym';

class SignUpConfirmation extends Component {
  static propTypes = {
    location: PropTypes.shape().isRequired, // eslint-disable-line react/no-unused-prop-types
    confirmSignUp: PropTypes.func.isRequired, // eslint-disable-line react/no-unused-prop-types
    source: PropTypes.string,
    isSignUpConfirmed: PropTypes.bool,
  };

  static defaultProps = {
    isSignUpConfirmed: null,
    source: null,
  };

  state = {};

  static getDerivedStateFromProps(props) {
    const {
      location: { search },
      confirmSignUp,
      isSignUpConfirmed,
    } = props;
    const { confirmationCode, email } = parseQueryString(search, { ignoreQueryPrefix: true });

    if (R.isNil(isSignUpConfirmed)) {
      GA.event({ category: 'signup', action: 'click', label: 'SignComplete' });
      YM.event('SignComplete');
      confirmSignUp({ confirmationCode, email });
    }

    return null;
  }

  render() {
    const { isSignUpConfirmed, source } = this.props;

    if (R.isNil(isSignUpConfirmed)) {
      return null;
    }

    return (
      <ChangeRoute
        to={source ? '/from-pwa' : '/'}
        state={{
          action: HISTORY_STATE_ACTIONS.LS_ACTION_SIGN_IN,
          isSignUpConfirmation: true,
        }}
      />
    );
  }
}

export default withRouter(withSignUpConfirmation(SignUpConfirmation));
