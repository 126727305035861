import React from 'react';
import PropTypes from 'prop-types';
import colors from '../../../customizations/js/color-variables';

export const IconWallet = ({ className, color }) => (
  <svg className={className} width="27" height="28" viewBox="0 0 27 28" fill="none">
    <path className="icon-filled-path" d="M20.818 14.052A3.151 3.151 0 0 0 17.67 17.2a3.151 3.151 0 0 0 3.148 3.148h4.46l.052-.003.052.003c.58 0 1.05-.47 1.05-1.05v-8.236a4.193 4.193 0 0 0-1.74-3.4l-3.57-5.855a1.049 1.049 0 0 0-1.448-.346L17.04 3.094l-1.187-2.01a1.05 1.05 0 0 0-1.448-.362L5.298 6.865h-1.58A1.05 1.05 0 0 1 2.67 5.842a1.05 1.05 0 0 1 1.05-1.023h7.94a1.05 1.05 0 1 0 0-2.099H3.72A3.153 3.153 0 0 0 .655 5.14a1.045 1.045 0 0 0-.086.414v17.681a4.202 4.202 0 0 0 4.197 4.197h17.468c1.809 0 3.41-1.153 3.982-2.869.1-.3.215-5.263.215-5.263l-2.099 1.049s.076 3-.107 3.55a2.096 2.096 0 0 1-1.991 1.434H4.767a2.1 2.1 0 0 1-2.098-2.099V8.784c.328.117.681.18 1.05.18h18.516a2.1 2.1 0 0 1 2.098 2.099v2.99h-3.515zm3.515 4.198h-3.515a1.05 1.05 0 0 1 0-2.1h3.515v2.1zM9.338 6.865l5.25-3.798 1.117 1.89a1.06 1.06 0 0 0 .151.256l.976 1.652H9.338zm9.932 0L18.109 4.9l1.772-1.099 1.867 3.063H19.27z" fill={color} />
  </svg>
);

IconWallet.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string,
};

IconWallet.defaultProps = {
  className: '',
  color: colors.gray30,
};
