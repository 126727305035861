import React from 'react';
import PropTypes from 'prop-types';

export const IconSupport = ({ className }) => (
  <svg className={className} width="18" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M10.5882 7.47045C10.5882 5.84879 9.26927 4.52925 7.64702 4.52925C6.02477 4.52925 4.70583 5.84879 4.70583 7.47045C4.70583 9.09211 6.02477 10.4117 7.64702 10.4117C9.26927 10.4117 10.5882 9.09211 10.5882 7.47045ZM8.82351 13.9411C12.3909 13.9411 15.2941 11.0383 15.2941 7.47045C15.2941 7.06945 15.2562 6.6817 15.1792 6.29508C14.5153 2.60884 11.1006 0.49992 7.64709 0.499878C3.77724 0.499831 0 3.20527 0 7.47045C0 9.64642 0.854769 10.7546 1.54179 11.645C2.01285 12.2556 2.35291 12.6968 2.35291 13.3528C2.35291 14.1116 1.62795 15.009 1.34762 15.2904C1.18099 15.4593 1.13046 15.7121 1.22238 15.9315C1.31318 16.1509 1.52682 16.2941 1.76464 16.2941C3.40523 16.2941 4.8494 15.3358 5.50662 13.8613C5.78927 13.9032 6.1247 13.9411 6.47053 13.9411L8.82351 13.9411ZM3.5294 7.47045C3.5294 5.20024 5.37682 3.35276 7.64709 3.35276C9.91728 3.35276 11.7647 5.20018 11.7647 7.47045C11.7647 9.74072 9.91728 11.5881 7.64709 11.5881C5.37682 11.5881 3.5294 9.74066 3.5294 7.47045ZM17.6471 18.0587C17.6471 17.4027 17.9872 16.9615 18.4582 16.3508C19.1452 15.4605 20 14.3524 20 12.1764C20 9.65774 18.5452 7.45588 16.3828 6.39548C16.4357 6.74382 16.4706 7.09919 16.4706 7.47045C16.4706 11.6869 13.04 15.1175 8.82358 15.1175C8.82358 15.1175 6.26497 15.1146 6.15815 15.1095C6.0298 15.303 5.88795 15.4851 5.73702 15.6583C6.89887 17.4686 8.9155 18.6469 11.1766 18.6469H13.5295C13.8305 18.6469 14.1442 18.6205 14.4923 18.5642C15.1483 20.0405 16.5936 20.9998 18.2354 20.9998C18.4732 20.9998 18.687 20.8568 18.7777 20.6373C18.8696 20.4179 18.8191 20.1651 18.6525 19.9962C18.372 19.7149 17.6471 18.8176 17.6471 18.0587Z" fill="url(#support_icon)" />
  </svg>

);

IconSupport.propTypes = {
  className: PropTypes.string,
};

IconSupport.defaultProps = {
  className: '',
};
