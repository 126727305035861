import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Button from 'reactstrap/lib/Button';
import { withModalActions } from 'core/hocs';
import { FormattedTag } from 'core/components';
import { FormattedHTMLMessage } from 'react-intl';
import { ModalWindow } from 'components/modal-window/modal-window';
import { IconGiftGray } from 'components/icons/icon-gift-gray/icon-gift-gray';
import { IconGift } from 'components/icons/icon-gift/icon-gift';
import { MODAL_IDS } from '../../../constants';
import './free-spins-modal.scss';

const spinsAmountPerDay = 20;
const daysAmount = 4;
const timelineIds = ['today', 'tomorrow', 'third-day', 'fourth-day', 'fifth-day'];

class FreeSpinsModalUI extends Component {
  static propTypes = {
    closeModal: PropTypes.func.isRequired,
  };

  onButtonClick = () => {
    const { closeModal } = this.props;
    // here logic of giving a gift to the user
    closeModal(MODAL_IDS.FREE_SPINS);
  }

  createFreeSpinsGifts = () => (
    <div className="free-spins-gifts d-flex flex-wrap justify-content-center">
      {timelineIds.map((period, index) => (
        <div
          key={period}
          id={period}
          className={classNames('timeline-period position-relative d-flex flex-column text-white-45',
            { 'period-today mr-0 mr-sm-2 mb-0_5 mb-sm-0': index === 0 })}
        >
          {index === 0 ? <IconGift className="position-absolute" /> : <IconGiftGray />}
          <FormattedTag tag="div" id={period} className={classNames('pt-1 mt-0_25 font-weight-medium period-label', { 'text-white': index === 0 })} />
          <div className={classNames('position-absolute spins-amount w-100 h6', { 'text-white font-weight-bold': index === 0 })}>{spinsAmountPerDay} FS</div>
        </div>
      ))}
    </div>
  )

  render() {
    return (
      <ModalWindow
        id={MODAL_IDS.FREE_SPINS}
        coreProps={{ id: MODAL_IDS.FREE_SPINS }}
        bodyClassName="d-flex d-sm-block align-items-center flex-column justify-content-sm-center text-center free-spins-modal"
        isModalCentered
        fade
      >
        <FormattedTag tag="div" id="congratulations" className="h6 mb-1_5 congratulations text-uppercase" />
        <FormattedTag tag="div" id="modal.free-spins.title" className="h3 mb-sm-6 pb-sm-0_25" />
        {this.createFreeSpinsGifts()}
        <div className="text-small text-white-65 subtitle mt-3 mt-sm-4">
          <FormattedHTMLMessage
            id="modal.free-spins.subtitle"
            values={{
              daysAmount,
              spinsAmount: spinsAmountPerDay,
            }}
          />
        </div>
        <div className="mt-5 w-100">
          <FormattedTag
            id="got-it"
            tag={Button}
            className="w-100"
            onClick={this.onButtonClick}
          />
        </div>
      </ModalWindow>
    );
  }
}

export const FreeSpinsModal = withModalActions(FreeSpinsModalUI);
