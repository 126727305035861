import React from 'react';
import { withUser } from 'core/hocs';
import PropTypes from 'prop-types';
import { SignInButtons } from '../header/sign-in-buttons/sign-in-buttons';
import { CasinoNavigation } from '../casino-navigation/casino-navigation';
import './bottom-mobile-actions.scss';

export const BottomMobileActionsUI = ({
  isUserLoggedIn,
  isSportsbookPage,
  isCashierPage,
  isSignUpLandPage,
  isResetPasswordPage,
  hasNavigation,
  pageName,
  category,
}) => (
  <div className="fixed-bottom bottom-actions">
    {!isUserLoggedIn && !isSportsbookPage && !isCashierPage && !isSignUpLandPage && !isResetPasswordPage && (
      <SignInButtons isBottomPosition />
    )}

    {hasNavigation && (
      <CasinoNavigation
        pageName={pageName}
        params={category}
      />
    )}
  </div>
);

BottomMobileActionsUI.propTypes = {
  isUserLoggedIn: PropTypes.bool.isRequired,
  isSportsbookPage: PropTypes.bool.isRequired,
  isCashierPage: PropTypes.bool.isRequired,
  isSignUpLandPage: PropTypes.bool.isRequired,
  isResetPasswordPage: PropTypes.bool.isRequired,
  hasNavigation: PropTypes.bool.isRequired,
  pageName: PropTypes.string.isRequired,
  category: PropTypes.string.isRequired,
};

export const BottomMobileActions = withUser(BottomMobileActionsUI);
