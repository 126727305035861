import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedTag } from 'core/components';
import * as R from 'ramda';
import classNames from 'classnames';
import {
  encodeSVG,
  getPaymentMethodIcon,
  isEmptyOrNil
} from 'core/helpers';

import { PAYMENT_METHODS } from 'core/constants';
import { formatAmountWithCurrency } from 'helpers/numbers';

import './payment-method-card.scss';

const getMethodsWithoutDefaultIcons = currencies => [
  {
    paymentMethod: PAYMENT_METHODS.IS_MBWAYHP,
    allowedCurrencies: currencies,
    icon: '/assets/public/images/interac-e-transfer.svg',
  },
  {
    paymentMethod: 'IS_NETBANKING_5',
    allowedCurrencies: ['INR'],
    icon: '/assets/public/images/netbanking.svg',
  },
];

export class PaymentMethodCard extends Component {
  static propTypes = {
    locale: PropTypes.string,
    className: PropTypes.string,
    currency: PropTypes.string,
    onClick: PropTypes.func,
    paymentMethod: PropTypes.string.isRequired,
    minimum: PropTypes.number,
    maximum: PropTypes.number,
    isSmall: PropTypes.bool,
    disabled: PropTypes.bool,
    isIconOnly: PropTypes.bool,
    isPaymentsPage: PropTypes.bool,
    isWithdrawal: PropTypes.bool,
    currencies: PropTypes.arrayOf(PropTypes.shape()),
  };

  static defaultProps = {
    locale: null,
    className: '',
    isSmall: false,
    onClick: null,
    minimum: null,
    maximum: null,
    currency: '',
    disabled: false,
    isIconOnly: false,
    isPaymentsPage: false,
    isWithdrawal: false,
    currencies: [],
  };

  state = {
    isMounted: false,
  };

  componentDidMount() {
    this.setState({ isMounted: true });
  }

  render() {
    const {
      paymentMethod,
      onClick,
      currency,
      isSmall,
      minimum,
      locale,
      maximum,
      isIconOnly,
      disabled,
      isPaymentsPage,
      className,
      isWithdrawal,
      currencies
    } = this.props;

    const { isMounted } = this.state;

    if (!isMounted) {
      return null;
    }

    const withoutDefaultPaymentIcon = isWithdrawal
      ? R.compose(
        R.propOr(null, 'icon'),
        R.last,
        R.filter(
          R.where({
            paymentMethod: R.equals(paymentMethod),
            allowedCurrencies: R.includes(currency),
          }),
        ),
        getMethodsWithoutDefaultIcons,
      )(currencies)
      : null;

    const icon = getPaymentMethodIcon(paymentMethod) || null;
    const iconSrc = !R.isNil(icon) && icon.includes('<svg') ? `data:image/svg+xml;utf8,${encodeSVG(icon)}` : icon;

    if (isIconOnly && !R.isNil(icon)) {
      return (
        <img
          alt={paymentMethod}
          className="payment-icon-scale"
          src={!isEmptyOrNil(withoutDefaultPaymentIcon) ? withoutDefaultPaymentIcon : iconSrc}
        />
      );
    }

    return (
      <button
        type="button"
        onClick={onClick}
        color="transparent"
        disabled={disabled}
        data-name={paymentMethod}
        className={classNames('payment-card d-flex flex-column align-items-center justify-content-around position-relative px-1', {
          small: isSmall,
          'without-action': !onClick,
          disabled,
          'justify-content-around rounded py-2 px-sm-3_25 ': !isPaymentsPage,
          'payments-table-method-card justify-content-center px-md-1_25 bg-old-light rounded-sm': isPaymentsPage
        }, className)}
      >
        <div
          className={classNames('payment-card-icon-container d-flex flex-column align-items-center position-absolute w-100 h-100', {
            'justify-content-end': currency && minimum && !isPaymentsPage,
            'justify-content-center': isPaymentsPage,
          })}
        >
          {!R.isNil(icon) && (
            <img
              alt={paymentMethod}
              src={!isEmptyOrNil(withoutDefaultPaymentIcon) ? withoutDefaultPaymentIcon : iconSrc}
              className={classNames('mw-100', {
                'flex-grow-1 w-100': currency && minimum && !isPaymentsPage,
                'm-0': isPaymentsPage,
              })}
            />
          )}

          {!!currency && !!minimum && !!maximum && !isPaymentsPage && (
            <div
              className="d-flex justify-content-between text-blue-dark text-nowrap text-capitalize h6 w-100 flex-wrap"
            >
              <div>
                <FormattedTag className="threshold font-weight-normal" id="min" isHtml />:
                <span>&#32;</span>
                <span className="threshold-value d-inline-flex text-blue-dark font-weight-semi-bold">
                  {formatAmountWithCurrency({
                    amount: minimum,
                    currency,
                    locale,
                  })}
                </span>
              </div>

              <div>
                <FormattedTag className="threshold font-weight-normal" id="max" isHtml />:
                <span>&#32;</span>
                <span className="threshold-value d-inline-flex text-blue-dark font-weight-semi-bold">
                  {formatAmountWithCurrency({
                    amount: maximum,
                    currency,
                    locale,
                  })}
                </span>
              </div>
            </div>
          )}
        </div>
      </button>
    );
  }
}
