import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import FormGroup from 'reactstrap/lib/FormGroup';
import Label from 'reactstrap/lib/Label';
import Input from 'reactstrap/lib/Input';

export const Radio = ({
  onChange, options, name, value, isOptionsLocalized, classNames,
}) => (
  <Fragment>
    {options.map(({ value: optValue, label: optLabel }) => (
      <FormGroup key={optValue} check className={classNames}>
        <Label check>
          <Input
            type="radio"
            name={name}
            id={optValue}
            value={optValue}
            onChange={onChange}
            checked={value === optValue}
          />
          {' '}
          {isOptionsLocalized
            ? <span>{optLabel}</span>
            : <FormattedMessage id={optLabel} />}
        </Label>
      </FormGroup>
    ))}
  </Fragment>
);

Radio.propTypes = {
  options: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
  })).isRequired,
  isOptionsLocalized: PropTypes.bool,
  value: PropTypes.string,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  classNames: PropTypes.string,
};

Radio.defaultProps = {
  value: null,
  isOptionsLocalized: false,
  classNames: '',
};
