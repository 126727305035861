import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { fromEvent } from 'rxjs';
import { filter, map, debounceTime } from 'rxjs/operators';

/**
 * Using that to ensure user hits the end of document
 */
const SAFE_TRIGGER_OFFSET = 150;
const INFINITE_SCROLL_DEBOUNCE = 100;

/**
 * Having current inner height of browser window and YOffset (scrolled height from top), check if we hit end of document
 */
const isEndOfDocument = () =>
  (window.innerHeight + window.pageYOffset) >= (document.body.offsetHeight - SAFE_TRIGGER_OFFSET);

const isEndOfTargetRef = ({ current }) =>
  (current.clientHeight + current.scrollTop) >= current.scrollHeight - SAFE_TRIGGER_OFFSET;

export const withInfiniteScroll = WrappedComponent =>
  class WithInfiniteScroll extends Component {
    static propTypes = {
      loadNext: PropTypes.func.isRequired,
      targetRef: PropTypes.shape(),
    };

    static defaultProps = {
      targetRef: null,
    };

    scrollSubscription;

    componentDidMount() {
      const { targetRef } = this.props;

      this.scrollSubscription = fromEvent(targetRef ? targetRef.current : window, 'scroll')
        .pipe(
          debounceTime(INFINITE_SCROLL_DEBOUNCE),
          filter(() => targetRef ? isEndOfTargetRef(targetRef) : isEndOfDocument()),
          map(() => window.pageYOffset)
        ).subscribe(this.props.loadNext);
    }

    componentWillUnmount() {
      if (this.scrollSubscription) {
        this.scrollSubscription.unsubscribe();
      }
    }

    render() {
      return <WrappedComponent {...this.props} />;
    }
  };
