/**
 * It excepts a phone number as a prop and return modified field value
 * Example:
 * This input number {+529392392949}
 * convert into {+52(939)239-29-49}
 */

export const phoneFormatter = (value) => {
  // Remove everything except digits
  const cleanValue = value.replace(/^\+52|\D/g, '');

  // Apply the formatting: (xxx)xxx-xx-xx
  if (cleanValue.length <= 3) {
    return `(${cleanValue}`;
  }

  if (cleanValue.length <= 6) {
    return `(${cleanValue.slice(0, 3)})${cleanValue.slice(3)}`;
  }

  if (cleanValue.length <= 8) {
    return `(${cleanValue.slice(0, 3)})${cleanValue.slice(3, 6)}-${cleanValue.slice(6)}`;
  }

  return `(${cleanValue.slice(0, 3)})${cleanValue.slice(3, 6)}-${cleanValue.slice(6, 8)}-${cleanValue.slice(8, 10)}`;
};
