import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Spinner from 'reactstrap/lib/Spinner';
import classNames from 'classnames';
import {
  withGlobalEvents, withModalActions, withNotificationsActions, withUserAgent
} from 'core/hocs';
import { isPaymentMethodIFrame } from 'core/helpers';
import { GLOBAL_EVENTS, NOTIFICATION_MESSAGES } from 'core/constants';
import { ModalWindow } from 'components/modal-window/modal-window';

import './payment-container.scss';

const MODAL_ID = 'pwa-payment-modal';

class PaymentContainerUI extends Component {
  static propTypes = {
    globalEvents: PropTypes.shape().isRequired,
    closeModal: PropTypes.func.isRequired,
    openModal: PropTypes.func.isRequired,
    isPwa: PropTypes.bool.isRequired,
    showSuccessNotification: PropTypes.func.isRequired,
  };

  state = {
    paymentUrl: null,
    isLoaderShowed: true,
  };

  componentDidMount() {
    const { globalEvents } = this.props;

    globalEvents.on(GLOBAL_EVENTS.PAYMENT_REDIRECT, this.openPaymentIframe);
  }

  openPaymentIframe = ({ payload: { url, paymentMethod } }) => {
    if (isPaymentMethodIFrame(paymentMethod)) {
      const { openModal } = this.props;

      window.addEventListener('message', this.onIframeMessage);
      this.setState({ paymentUrl: url });
      openModal(MODAL_ID);
    }
  };

  onIframeMessage = ({ data }) => {
    const { showSuccessNotification } = this.props;
    const { type, search = '' } = data || {};

    if (search.includes('payment_id')) {
      showSuccessNotification(NOTIFICATION_MESSAGES.PAYMENT_COMPLETED);
    }

    if (type === 'CLOSE_IFRAME') {
      this.closeIframe();
    }
  };

  closeIframe = () => {
    const { closeModal } = this.props;

    window.removeEventListener('message', this.onIframeMessage);
    closeModal(MODAL_ID);
  }

  onClosed = () => {
    this.setState({
      paymentUrl: null,
      isLoaderShowed: true,
    });
  };

  onLoad = () => {
    this.setState({ isLoaderShowed: false });
  };

  render() {
    const { paymentUrl, isLoaderShowed } = this.state;
    const { isPwa } = this.props;

    return (
      <ModalWindow
        id={MODAL_ID}
        coreProps={{ id: MODAL_ID }}
        onClosed={this.onClosed}
        bodyClassName={classNames({ 'pb-0 px-0 pt-7 pwa-payment-container': isPwa, 'payment-container': !isPwa })}
        className={classNames({ 'payment-modal': !isPwa })}
        fade
        scrollable={false}
      >
        {isLoaderShowed && (
          <div className="pwa-payment-container-loader d-flex align-items-center justify-content-center w-100 h-100 position-absolute">
            <Spinner color="white" />
          </div>
        )}

        {paymentUrl && (
          /* eslint-disable-next-line jsx-a11y/iframe-has-title */
          <iframe
            onLoad={this.onLoad}
            src={paymentUrl}
            scrolling="yes"
            allowFullScreen
            allow="clipboard-write; clipboard-read"
          />
        )}
      </ModalWindow>
    );
  }
}

export const PaymentContainer = withUserAgent(
  withGlobalEvents(
    withModalActions(
      withNotificationsActions(PaymentContainerUI)
    )
  )
);
