import React, { Component, createRef } from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import { fromEvent } from 'rxjs';
import { map } from 'rxjs/operators';
import { getPlaceholder } from '../../../helpers/form-elements';
import { InputText } from '../../input-text/input-text';
import { IconEye } from '../../icons/icon-eye/icon-eye';

export class InputUI extends Component {
  static propTypes = {
    type: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    intl: intlShape.isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    placeholder: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    className: PropTypes.string,
    isAnimatedPlaceholder: PropTypes.bool,
    onChange: PropTypes.func,
    isReadOnly: PropTypes.bool,
    isDisabled: PropTypes.bool,
    isNotEditable: PropTypes.bool,
    icon: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
    length: PropTypes.shape(),
    size: PropTypes.string,
    error: PropTypes.shape(),
    leftContent: PropTypes.node,
    rightContent: PropTypes.node,
    wrapperClassName: PropTypes.string,
    autoFocus: PropTypes.bool,
    innerRef: PropTypes.shape(),
    secondaryPlaceholder: PropTypes.string,
    onBlur: PropTypes.func,
    hasEye: PropTypes.bool,
  };

  static defaultProps = {
    size: null,
    value: '',
    placeholder: null,
    isAnimatedPlaceholder: false,
    className: '',
    onChange: null,
    isReadOnly: false,
    isDisabled: false,
    isNotEditable: false,
    length: {},
    icon: null,
    error: null,
    leftContent: null,
    rightContent: null,
    wrapperClassName: null,
    autoFocus: false,
    innerRef: null,
    secondaryPlaceholder: null,
    onBlur: null,
    hasEye: true,
  };

  state = {
    isTextShown: false,
  };

  iconClickSubscription = null;

  iconRef = createRef();

  componentWillUnmount() {
    if (this.iconClickSubscription) {
      this.iconClickSubscription.unsubscribe();
    }
  }

  handleClick = () => {
    this.setState(({ isTextShown }) => ({ isTextShown: !isTextShown }));
  };

  handleBlur = (e) => {
    const { currentTarget } = e;
    const { onBlur } = this.props;

    if (onBlur) {
      onBlur(e);
    }

    // save focus on input when icon clicked
    if (this.iconRef.current) {
      this.iconClickSubscription = fromEvent(document, 'click').pipe(map(({ target }) => {
        if (target.parentNode === this.iconRef.current || target.parentNode.parentNode === this.iconRef.current) {
          currentTarget.focus();
        }
      })).subscribe(() => {
        this.iconClickSubscription.unsubscribe();
      });
    }
  };

  render() {
    const {
      name, value, placeholder, className,
      onChange, type, length, isReadOnly, icon, error, leftContent,
      isDisabled, rightContent, autoFocus, secondaryPlaceholder, hasEye,
      intl, size, isNotEditable, isAnimatedPlaceholder, wrapperClassName, innerRef, ...props
    } = this.props;

    const { isTextShown } = this.state;

    return (
      <InputText
        {...props}
        autoFocus={autoFocus}
        type={isTextShown ? 'text' : type}
        id={`input-${name}`}
        name={name}
        value={value}
        onChange={onChange}
        isError={!!error}
        className={className}
        placeholder={getPlaceholder(!isAnimatedPlaceholder ? placeholder : secondaryPlaceholder, intl.formatMessage)}
        animatedPlaceholder={isAnimatedPlaceholder && !!placeholder ? intl.formatMessage({ id: placeholder }) : ''}
        minLength={length.min}
        maxLength={length.max}
        readOnly={isReadOnly}
        disabled={isDisabled}
        onBlur={this.handleBlur}
        icon={icon}
        // size={size}
        leftContent={leftContent}
        rightContent={type === 'password' && hasEye ? (
          <div
            role="button"
            tabIndex="0"
            onKeyPress={this.handleClick}
            ref={this.iconRef}
            onClick={this.handleClick}
          >
            <IconEye
              className="icon-eye"
              isOpen={!isTextShown}
            />
          </div>
        ) : rightContent}
        isNotEditable={isNotEditable}
        wrapperClassName={wrapperClassName}
        innerRef={innerRef}
      />
    );
  }
}

export const Input = injectIntl(InputUI);
