import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withModalActions } from 'core/hocs';
import { ModalWindow } from 'components/modal-window/modal-window';
import { Deposit } from 'components/deposit/deposit';

import { MODAL_IDS } from '../../../constants';
import { withModalAlignment } from '../../../hocs/with-modal-alignment';

import './deposit-modal.scss';

export class DepositModalUI extends Component {
  static propTypes = {
    closeModal: PropTypes.func.isRequired,
    isModalCentered: PropTypes.bool.isRequired,
    isModalAlignTop: PropTypes.bool.isRequired,
    updateModalAlignment: PropTypes.func.isRequired,
  };

  closeModal() {
    const { closeModal } = this.props;
    closeModal(MODAL_IDS.DEPOSIT);
  }

  render() {
    const {
      isModalCentered, isModalAlignTop, updateModalAlignment
    } = this.props;

    return (
      <ModalWindow
        id={MODAL_IDS.DEPOSIT}
        coreProps={{ id: MODAL_IDS.DEPOSIT }}
        className="deposit-modal"
        bodyClassName="p-0"
        isModalCentered={isModalCentered}
        isModalAlignTop={isModalAlignTop}
        fade
      >
        <Deposit updateModalAlignment={updateModalAlignment} isModal />
      </ModalWindow>
    );
  }
}

export const DepositModal = withModalActions(withModalAlignment(DepositModalUI));
