import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { FormattedTag, RouteLink } from 'core/components';
import { profileMenuItems } from 'src/components/sidebar/sidebar-menu/sidebar-menu';
import { PAGE_NAMES } from '../../constants';

import './profile-tabs.scss';

export const ProfileTabs = ({ pageName }) => (
  <div className="position-relative">
    <div className="profile-tabs d-flex border-bottom border-white-20">
      {profileMenuItems.map(({ id, tab }) => (
        <div className="pr-3 profile-tab-container" key={tab}>
          <FormattedTag
            tag={RouteLink}
            id={id}
            to={`/profile/${tab}`}
            className={classNames('profile-tab text-capitalize position-relative pt-1_5 pb-1_75 d-block font-weight-semi-bold text-nowrap', {
              'active text-blue-dark': tab === pageName || (pageName === PAGE_NAMES.PROFILE && !tab)
            })}
          />
        </div>
      ))}
    </div>
  </div>
);

ProfileTabs.propTypes = {
  pageName: PropTypes.string.isRequired,
};
