import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { memoizeWith, identity } from 'ramda';
import { TelInputInner } from './tel-input-inner';

import countryData from './country-data';

const KEYS = {
  ENTER: 13,
};

const LENGTH_FOR_GUESS = 12;
const NUMBER_DIGITS_LIMIT = 15;

export class TelInput extends Component {
  static propTypes = {
    autoFormat: PropTypes.bool,
    enableLongNumbers: PropTypes.bool,
    onChange: PropTypes.func,
    onFocus: PropTypes.func,
    onBlur: PropTypes.func,
    onClick: PropTypes.func,
    onKeyDown: PropTypes.func,
    onEnterKeyPress: PropTypes.func,
  }

  static defaultProps = {
    autoFormat: true,
    onChange: () => { },
    onFocus: () => { },
    onBlur: () => { },
    onClick: () => { },
    onKeyDown: () => { },
    enableLongNumbers: false,
    onEnterKeyPress: () => { },
  }

  numberInputRef;

  guessSelectedCountry = memoizeWith(identity, ([inputNumber, currentIso2]) => {
    const bestGuess = countryData.allCountries.reduce((selectedCountry, country) => {
      const {
        priority, dialCode, format, iso2,
      } = country;
      const { priority: selectedPriority, dialCode: selectedDialCode } = selectedCountry;

      if (currentIso2 && iso2 === (currentIso2 || '').toLowerCase()) {
        return country;
      }

      if (inputNumber.startsWith(country.dialCode)) {
        if (dialCode.length > selectedDialCode.length) {
          return country;
        }

        if (dialCode.length === selectedDialCode.length && (format && format.split('.').length - 1 <= inputNumber.length)) {
          return country;
        }

        if (dialCode.length === selectedDialCode.length && priority < selectedPriority) {
          return country;
        }
      }

      return selectedCountry;
    }, { dialCode: '', priority: 10001 }, this);

    if (!bestGuess.name) return {};

    return bestGuess;
  });

  constructor(props) {
    super(props);

    /* eslint-disable-next-line */
    const inputNumber = props.value.replace(/[^0-9\.]+/g, '') || '';

    let countryGuess;

    if (inputNumber.length > 1) {
      // Country detect by value field
      countryGuess = this.guessSelectedCountry([inputNumber.substring(0, LENGTH_FOR_GUESS)]) || 0;
    } else {
      // Empty params
      countryGuess = 0;
    }

    const dialCode = (
      inputNumber.length < 2
      && countryGuess
      && !inputNumber.replace(/\D/g, '').startsWith(countryGuess.dialCode)
    ) ? countryGuess.dialCode : '';

    const formattedNumber = (inputNumber === '' && countryGuess === 0)
      ? ''
      : this.formatNumber(`${dialCode}${inputNumber.replace(/\D/g, '')}`, countryGuess.name ? countryGuess.format : undefined);

    this.state = {
      formattedNumber,
      selectedCountry: countryGuess,
    };
  }

  updateFormattedNumber = (value, currentIso2) => {
    const { onChange } = this.props;
    let newSelectedCountry;
    let inputNumber = value;
    let formattedNumber = value;

    // if inputNumber does not start with '+', then use default country's dialing prefix,
    if (!inputNumber.startsWith('+')) {
      newSelectedCountry = this.state.selectedCountry;
      const dialCode = newSelectedCountry && !inputNumber.replace(/\D/g, '').startsWith(newSelectedCountry.dialCode) ? newSelectedCountry.dialCode : '';
      formattedNumber = this.formatNumber(
        dialCode + inputNumber.replace(/\D/g, ''),
        newSelectedCountry ? newSelectedCountry.format : undefined
      );
    } else {
      inputNumber = inputNumber.replace(/\D/g, '');
      newSelectedCountry = this.guessSelectedCountry([inputNumber.substring(0, LENGTH_FOR_GUESS), currentIso2]);
      formattedNumber = this.formatNumber(inputNumber, newSelectedCountry.format);
    }

    this.setState({ selectedCountry: newSelectedCountry, formattedNumber }, () => {
      if (onChange) {
        onChange(formattedNumber);
      }
    });
  }

  formatNumber = (text, pattern) => {
    const { enableLongNumbers, autoFormat } = this.props;

    if (!text || text.length === 0) {
      return '';
    }

    // for all strings with length less than 3, just return it (1, 2 etc.)
    // also return the same text if the selected country has no fixed format
    if ((text && text.length < 2) || !pattern || !autoFormat) {
      return `+${text}`;
    }

    // if country has more than 1 pattern
    let actualPattern = pattern;

    if (Array.isArray(pattern)) {
      [actualPattern] = pattern;

      pattern.forEach((item) => {
        if (item.split('.').length - 1 <= text.length) {
          actualPattern = item;
        }
      });
    }

    const formattedObject = actualPattern.split('').reduce((acc, character) => {
      if (acc.remainingText.length === 0) {
        return acc;
      }

      if (character !== '.') {
        return {
          formattedText: acc.formattedText + character,
          remainingText: acc.remainingText,
        };
      }

      const [head, ...tail] = acc.remainingText;

      return {
        formattedText: acc.formattedText + head,
        remainingText: tail,
      };
    }, {
      formattedText: '',
      remainingText: text.split(''),
    });

    let formattedNumber;

    if (enableLongNumbers) {
      formattedNumber = formattedObject.formattedText + formattedObject.remainingText.join('');
    } else {
      formattedNumber = formattedObject.formattedText;
    }

    // Always close brackets
    if (formattedNumber.includes('(') && !formattedNumber.includes(')')) formattedNumber += ')';

    return formattedNumber;
  }

  // Put the cursor to the end of the input (usually after a focus event)
  cursorToEnd = () => {
    const input = this.numberInputRef;
    input.focus();
    const len = input.value.length;
    input.setSelectionRange(len, len);
  }

  // return country data from state
  getCountryData = () => {
    const { selectedCountry } = this.state;

    if (!selectedCountry) return {};

    return {
      name: selectedCountry.name || '',
      dialCode: selectedCountry.dialCode || '',
      countryCode: selectedCountry.iso2 || '',
    };
  }

  handleInput = (e) => {
    const { onChange } = this.props;
    const {
      selectedCountry, formattedNumber: oldFormattedText,
    } = this.state;

    let formattedNumber = '+';
    let newSelectedCountry = selectedCountry;

    // Does not exceed ${NUMBER_DIGITS_LIMIT} digit phone number limit
    if (e.target.value.replace(/\D/g, '').length > NUMBER_DIGITS_LIMIT) return;

    // if the input is the same as before, must be some special key like enter etc.
    if (e.target.value === oldFormattedText) return;

    // ie hack
    if (e.preventDefault) {
      e.preventDefault();
    } else {
      e.returnValue = false;
    }

    if (e.target.value.length > 0) {
      // before entering the number in new format, lets check if the dial code now matches some other country
      const inputNumber = e.target.value.replace(/\D/g, '');
      newSelectedCountry = this.guessSelectedCountry([inputNumber.substring(0, LENGTH_FOR_GUESS)]);

      // let us remove all non numerals from the input
      formattedNumber = this.formatNumber(inputNumber, newSelectedCountry.format);
    }

    let caretPosition = e.target.selectionStart;
    const diff = formattedNumber.length - oldFormattedText.length;

    this.setState({
      formattedNumber,
      selectedCountry: newSelectedCountry.dialCode
        ? newSelectedCountry
        : selectedCountry,
    }, () => {
      if (diff > 0) {
        caretPosition -= diff;
      }

      const lastChar = formattedNumber.charAt(formattedNumber.length - 1);

      if (lastChar === ')') {
        this.numberInputRef.setSelectionRange(formattedNumber.length - 1, formattedNumber.length - 1);
      } else if (caretPosition > 0 && oldFormattedText.length >= formattedNumber.length) {
        this.numberInputRef.setSelectionRange(caretPosition, caretPosition);
      }

      if (onChange) {
        onChange(formattedNumber);
      }
    });
  }

  handleInputClick = (e) => {
    const { onClick } = this.props;

    if (onClick) onClick(e, this.getCountryData());
  }

  handleInputFocus = (e) => {
    const { selectedCountry } = this.state;
    const { onFocus } = this.props;
    // if the input is blank, insert dial code of the selected country;

    if (this.numberInputRef) {
      if (this.numberInputRef.value === '+' && selectedCountry) {
        this.setState({
          formattedNumber: `+${selectedCountry.dialCode}`,
        }, () => setTimeout(this.cursorToEnd, 10));
      }
    }

    if (onFocus) {
      onFocus(e, this.getCountryData());
    }

    setTimeout(this.cursorToEnd, 10);
  }

  handleInputBlur = (e) => {
    const { onBlur } = this.props;

    if (onBlur) {
      onBlur(e, this.getCountryData());
    }
  }

  handleInputKeyDown = (e) => {
    const { onEnterKeyPress, onKeyDown } = this.props;

    if (e.which === KEYS.ENTER) {
      onEnterKeyPress(e);
    }

    if (onKeyDown) onKeyDown(e);
  }

  setRef = (el) => {
    this.numberInputRef = el;
  }

  render() {
    const { formattedNumber, selectedCountry } = this.state;

    return (
      <TelInputInner
        {...this.props}
        selectedCountry={selectedCountry}
        onFormat={this.handleInput}
        updateFormattedNumber={this.updateFormattedNumber}
        onClick={this.handleInputClick}
        onFocus={this.handleInputFocus}
        onBlur={this.handleInputBlur}
        formattedValue={formattedNumber}
        setRef={this.setRef}
        onKeyDown={this.handleInputKeyDown}
      />
    );
  }
}
