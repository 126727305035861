import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';
import Input from 'reactstrap/lib/Input';
import Label from 'reactstrap/lib/Label';

export const Textarea = ({
  name, label, value, placeholder, onChange,
  isReadOnly, isDisabled, className, error,
}) => (
  <Fragment>
    {label && (
      <Label for={`textarea-${name}`}>
        <FormattedMessage id={label} />
      </Label>
    )}
    <FormattedMessage id={placeholder}>
      {txt => (
        <Input
          type="textarea"
          id={`textarea-${name}`}
          name={name}
          value={value}
          onChange={onChange}
          placeholder={txt}
          readOnly={isReadOnly}
          disabled={isDisabled}
          className={classNames(className, 'w-100', {
            'border-danger': !!error,
          })}
        />
      )}
    </FormattedMessage>
  </Fragment>
);

Textarea.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string,
  label: PropTypes.string,
  className: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  error: PropTypes.shape(),
  isReadOnly: PropTypes.bool,
  isDisabled: PropTypes.bool,
};

Textarea.defaultProps = {
  value: '',
  label: null,
  placeholder: null,
  error: null,
  onChange: null,
  className: null,
  isReadOnly: null,
  isDisabled: null,
};
