import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { LOCALE_KEYS } from 'core/constants';

dayjs.extend(utc);
dayjs.extend(customParseFormat);

export const DATE_LOCALE_FORMATS = {
  [LOCALE_KEYS.EN]: 'D MMM',
  [LOCALE_KEYS.IT]: 'D MMM',
  [LOCALE_KEYS.PT]: 'D MMM',
  [LOCALE_KEYS.AU]: 'D MMM',
  [LOCALE_KEYS.TR]: 'D MMM',
  [LOCALE_KEYS.ES]: 'D MMM',
};

export const FULL_DATE_LOCALE_FORMATS = {
  [LOCALE_KEYS.EN]: 'D MMM YYYY',
  [LOCALE_KEYS.IT]: 'D MMM YYYY',
  [LOCALE_KEYS.PT]: 'D MMM YYYY',
  [LOCALE_KEYS.AU]: 'D MMM YYYY',
  [LOCALE_KEYS.TR]: 'D MMM YYYY',
  [LOCALE_KEYS.ES]: 'D MMM YYYY',
};

export const DATE_LOCALE_INVERSE_FORMATS = {
  [LOCALE_KEYS.EN]: 'MMM D',
  [LOCALE_KEYS.IT]: 'MMM D',
  [LOCALE_KEYS.PT]: 'MMM D',
  [LOCALE_KEYS.AU]: 'MMM D',
  [LOCALE_KEYS.TR]: 'MMM D',
  [LOCALE_KEYS.ES]: 'MMM D',
};

export const DATE_FORMAT_TRANSACTION_HISTORY = 'D MMM H:mm:ss';

export const DEFAULT_LOCALE = LOCALE_KEYS.EN;

export const isDateInPast = date => dayjs().isAfter(dayjs(date, 'DD-MM-YYYY'), 'day');

export const isDateInFuture = date => dayjs().isBefore(dayjs(date, 'DD-MM-YYYY'), 'day');

export const formatDate = (dateTime, localeFormats, locale) => {
  const localeDateTimeFormat = localeFormats[locale] || localeFormats[DEFAULT_LOCALE];

  return dayjs(dateTime, { locale }).format(localeDateTimeFormat);
};

export const toDateFormat = (dateTime, locale = DEFAULT_LOCALE) => formatDate(
  dateTime,
  DATE_LOCALE_FORMATS,
  locale,
);

export const toDatePickerFormat = (dateFrom, dateTo, locale = DEFAULT_LOCALE) => {
  const yearFrom = dateFrom.getFullYear();
  const yearTo = dateTo.getFullYear();
  const monthFrom = dateFrom.getMonth();
  const monthTo = dateTo.getMonth();
  const dayFrom = dateFrom.getDate();
  const dayTo = dateTo.getDate();

  if (yearFrom !== yearTo) {
    return `${formatDate(dateFrom, FULL_DATE_LOCALE_FORMATS, locale)} - ${formatDate(dateTo, FULL_DATE_LOCALE_FORMATS, locale)}`;
  }

  if (monthFrom === monthTo) {
    if (dayFrom === dayTo) {
      return formatDate(dateFrom, FULL_DATE_LOCALE_FORMATS, locale);
    }

    return `${formatDate(dateFrom, DATE_LOCALE_INVERSE_FORMATS, locale)} - ${dayTo}`;
  }

  return `${toDateFormat(dateFrom, locale)} - ${toDateFormat(dateTo, locale)}`;
};

export const convertStringDateToTransactionFormat = (date, locale = DEFAULT_LOCALE) =>
  dayjs(date, 'DD-MM-YYYY h-m-s').locale(locale).format(DATE_FORMAT_TRANSACTION_HISTORY);

export const getTimezone = () => {
  const date = new Date();
  const hoursOffset = date.getTimezoneOffset() / 60; // eastern regions than UTC have negative offset

  return `GMT${hoursOffset <= 0 ? '+' : '-'}${Math.abs(hoursOffset)}`;
};
