module.exports = {
  confirmationCode: {
    type: 'text',
    label: 'form.fields.confirmation-code.label',
    placeholder: 'form.fields.confirmation-code',
    length: { max: 10 },
    validation: [
      { type: 'required' },
      { type: 'min-length', value: 4 },
      { type: 'max-length', value: 4 },
    ],
  },
};
