import React from 'react';
import PropTypes from 'prop-types';

export const IconLangHu = ({ className }) => (
  <svg className={className} xmlns="http://www.w3.org/2000/svg" width="30" height="24" viewBox="0 0 30 24">
    <defs>
      <filter id="icon-lang-hu-c" width="116.7%" height="120.8%" x="-8.3%" y="-10.4%" filterUnits="objectBoundingBox">
        <feGaussianBlur in="SourceAlpha" result="shadowBlurInner1" stdDeviation="1.5" />
        <feOffset dy="2" in="shadowBlurInner1" result="shadowOffsetInner1" />
        <feComposite in="shadowOffsetInner1" in2="SourceAlpha" k2="-1" k3="1" operator="arithmetic" result="shadowInnerInner1" />
        <feColorMatrix in="shadowInnerInner1" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.3 0" />
      </filter>
      <rect id="icon-lang-hu-d" width="30" height="24" x="0" y="0" rx="4" />
      <path id="icon-lang-hu-a" d="M5.128 0h19.744c1.783 0 2.43.186 3.082.534.652.349 1.163.86 1.512 1.512.348.652.534 1.299.534 3.082v13.744c0 1.783-.186 2.43-.534 3.082-.349.652-.86 1.163-1.512 1.512-.652.348-1.299.534-3.082.534H5.128c-1.783 0-2.43-.186-3.082-.534-.652-.349-1.163-.86-1.512-1.512C.186 21.302 0 20.655 0 18.872V5.128c0-1.783.186-2.43.534-3.082.349-.652.86-1.163 1.512-1.512C2.698.186 3.345 0 5.128 0z" />
    </defs>
    <g fill="none" fillRule="evenodd">
      <mask id="icon-lang-hu-b" fill="#fff">
        <use xlinkHref="#icon-lang-hu-a" />
      </mask>
      <g fillRule="nonzero" mask="url(#icon-lang-hu-b)">
        <path fill="#F0F0F0" d="M0 0H30V24H0z" />
        <path fill="#D80027" d="M0 0H30V8H0z" />
        <path fill="#6DA544" d="M0 16H30V24H0z" />
      </g>
      <g fill="#000" mask="url(#icon-lang-hu-b)">
        <use filter="url(#icon-lang-hu-c)" xlinkHref="#icon-lang-hu-d" />
      </g>
    </g>
  </svg>
);

IconLangHu.propTypes = {
  className: PropTypes.string,
};

IconLangHu.defaultProps = {
  className: null,
};
