import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FormattedTag } from 'core/components';
import { IconArrowLeft } from 'components/icons/icon-arrow-left/icon-arrow-left';

import './payment-previous-step.scss';

export const PaymentPreviousStep = ({
  className,
  handlerClick,
  isModal,
}) =>
  (
    <div
      className={classNames('card-width d-flex align-items-center font-weight-semi-bold', className)}
    >
      <div
        className={classNames({ 'h3 d-inline mb-0 mr-3': isModal })}
        tabIndex="0"
        role="button"
        onClick={handlerClick}
        onKeyPress={handlerClick}
      >
        {isModal ? <span>&times;</span> : <div className="previous-step-icon d-flex align-items-center justify-content-center mr-1 mr-sm-1_5"><IconArrowLeft /></div>}
      </div>

      <FormattedTag id="payment-method.change" isHtml />
    </div>
  );

PaymentPreviousStep.propTypes = {
  className: PropTypes.string,
  handlerClick: PropTypes.func.isRequired,
  isModal: PropTypes.bool,
};

PaymentPreviousStep.defaultProps = {
  className: '',
  isModal: false,
};
