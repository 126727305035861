import React from 'react';
import PropTypes from 'prop-types';

export const IconTiktok = ({ className }) => (
  <svg className={className} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M16.4 1.6H3.6c-1.103 0-2 .897-2 2v12.8c0 1.103.897 2 2 2h12.8c1.103 0 2-.897 2-2V3.6c0-1.103-.897-2-2-2Zm-1.597 7.33a2.997 2.997 0 0 1-2.784-1.341v4.614a3.411 3.411 0 1 1-3.41-3.41c.07 0 .14.006.21.01v1.68c-.07-.008-.139-.02-.21-.02a1.74 1.74 0 1 0 0 3.481c.96 0 1.81-.758 1.81-1.72l.017-7.837h1.606a2.995 2.995 0 0 0 2.76 2.674V8.93Z" fill="#A3ACB3" />
  </svg>
);

IconTiktok.propTypes = {
  className: PropTypes.string,
};

IconTiktok.defaultProps = {
  className: '',
};
