module.exports = {
  convertAmount: {
    type: 'text',
    placeholder: '0',
    length: { max: 8 },
    validation: [
      { type: 'required' },
    ],
  },
  resultAmount: {
    type: 'text',
    placeholder: '0',
    isDisabled: true,
  },
  chargeAmount: {
    type: 'text',
    placeholder: '0',
    isDisabled: true,
  },
};
