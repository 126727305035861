/* eslint-disable react/jsx-curly-newline */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Button from 'reactstrap/lib/Button';
import { withUser } from 'core/hocs';
import { Greeting } from 'components/sidebar/greeting/greeting';
import { GA } from 'helpers/ga';
import { YM } from 'helpers/ym';

export class NotLoggedInUI extends Component {
  static propTypes = {
    // openSignInModal: PropTypes.func.isRequired,
    openSignUpModal: PropTypes.func.isRequired,
  };

  openSignUpModalHandler = () => {
    const { openSignUpModal } = this.props;
    GA.event({ category: 'signup', action: 'click', label: 'SignFirst' });
    YM.event('SignFirst');
    openSignUpModal();
  };

  render() {
    // const { openSignInModal } = this.props;

    return (
      <div className="casino-sidebar-not-logged-in mt-2_5 mb-2 text-center w-100 border-bottom border-white-15">
        <Greeting />

        <Button
          color="primary"
          onClick={this.openSignUpModalHandler}
          className="no-effects mb-3_5 mt-2_75 py-1_25 px-3_25"
        >
          <FormattedMessage id="sign-up" />
        </Button>

        {/* <Button
          outline
          color="white-20"
          onClick={openSignInModal}
          className="no-effects py-1_25 btn-outline-gray-5"
          block
        >
          <FormattedMessage id="login" />
        </Button> */}
      </div>
    );
  }
}

export const NotLoggedIn = withUser(NotLoggedInUI);
