import React, { Component } from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import Button from 'reactstrap/lib/Button';
import { withUser, withModalActions } from 'core/hocs';
import { USER_FIELDS } from 'core/constants';
import { FormattedTag } from 'core/components';
import { isEmptyOrNil } from 'core/helpers';

import { ModalWindow } from 'components/modal-window/modal-window';
import { UserInfoAndLoyalty } from 'components/user-info-and-loyalty/user-info-and-loyalty';
import { SidebarMenu } from 'components/sidebar/sidebar-menu/sidebar-menu';
import { MODAL_IDS } from '../../../constants';

import './profile-modal.scss';

const {
  USER_DATA, IS_USER_LOGGED_IN, USER_BALANCE, LOYALTY_POINTS,
} = USER_FIELDS;

export class ProfileModalUI extends Component {
  static propTypes = {
    userData: PropTypes.shape(),
    userBalance: PropTypes.shape(),
    loyaltyPoints: PropTypes.shape(),
    closeModal: PropTypes.func.isRequired,
    signOut: PropTypes.func.isRequired,
    pageName: PropTypes.string,
  };

  static defaultProps = {
    userData: null,
    userBalance: null,
    loyaltyPoints: null,
    pageName: null,
  };

  closeModal = () => {
    const { closeModal } = this.props;
    closeModal(MODAL_IDS.PROFILE);
  };

  render() {
    const {
      userData,
      userBalance,
      loyaltyPoints,
      signOut,
      pageName,
    } = this.props;
    const userInfoIsFetched = R.none(isEmptyOrNil, [userData, userBalance, loyaltyPoints]);

    return (
      <ModalWindow
        id={MODAL_IDS.PROFILE}
        coreProps={{ id: MODAL_IDS.PROFILE }}
        bodyClassName="d-flex flex-column align-items-center pt-2_25 casino-profile-modal bg-light"
        isModalCentered
        fade
        scrollable
        onClosed={this.onClosed}
      >
        <div className="w-100">
          <UserInfoAndLoyalty
            userBalance={userBalance}
            userInfoIsFetched={userInfoIsFetched}
          />

          <SidebarMenu
            pageName={pageName}
            isGridView
          />

          <FormattedTag
            tag={Button}
            id="sign-out"
            color="gray-10-50"
            onClick={signOut}
            block
            outline
          />
        </div>
      </ModalWindow>
    );
  }
}

export const ProfileModal = withModalActions(
  withUser(ProfileModalUI, [
    USER_DATA, IS_USER_LOGGED_IN, USER_BALANCE, LOYALTY_POINTS,
  ])
);
