import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Form from 'reactstrap/lib/Form';
import { withCoreComponent, withModalActions, withRecaptcha } from 'core/hocs';
import { getEnv } from 'core/helpers';
import { FormConfirmOtp as FormConfirmOtpCore, FormattedTag } from 'core/components';
import { LOCAL } from 'core/constants';
import { ButtonWithLoader } from 'components/button-with-loader/button-with-loader';
import { FormElement } from 'components/form-element/form-element';

import { MODAL_IDS } from '../../../constants';

import './form-confirm-otp.scss';

export class FormConfirmOtpUI extends Component {
  static propTypes = {
    onSubmit: PropTypes.func.isRequired,
    fields: PropTypes.shape().isRequired,
    isInProgress: PropTypes.bool.isRequired,
    isFormFilled: PropTypes.bool.isRequired,
    closeModal: PropTypes.func.isRequired,
    executeRecaptcha: PropTypes.func.isRequired,
  };

  closeModal = () => {
    const { closeModal } = this.props;
    closeModal(MODAL_IDS.CONFIRM_OTP);
  };

  onSubmit = (e) => {
    e.preventDefault();

    const { onSubmit, executeRecaptcha } = this.props;
    const isLocal = getEnv('PROJECT_ENV') === LOCAL;

    onSubmit(e, executeRecaptcha, isLocal);
  };

  render() {
    const {
      fields,
      isInProgress,
      isFormFilled
    } = this.props;

    return (
      <Form onSubmit={this.onSubmit}>
        <FormattedTag
          tag="div"
          id="form.confirm-otp.title"
          className="form-confirm-otp-title text-center px-0_75 mb-2"
        />

        <FormattedTag
          tag="div"
          id="form.confirm-otp.description"
          className="form-confirm-otp-desc text-center text-gray-10 mb-2"
        />

        <div className="input-confirm-code position-relative mb-2">
          <FormElement
            coreProps={{ item: fields.confirmationCode }}
            labelClassName="text-gray-10 mb-0_5"
            size="lg"
            isDisabled={isInProgress}
          />
        </div>

        <FormattedTag
          tag={ButtonWithLoader}
          id="verify"
          isLoading={isInProgress}
          color="primary"
          type="submit"
          disabled={!isFormFilled}
          className="text-capitalize"
          block
        />
      </Form>
    );
  }
}

export const FormConfirmOtp = withCoreComponent(
  FormConfirmOtpCore,
  withModalActions(
    withRecaptcha(
      FormConfirmOtpUI
    )
  )
);
