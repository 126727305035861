import React from 'react';
import PropTypes from 'prop-types';

export const IconLogo = ({ className }) => (
  <svg
    className={className}
    width="220"
    height="53"
    viewBox="0 0 220 53"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#icon-logo-clip0_226_11148)">
      <path
        d="M93.7766 22.6915C89.9441 13.5444 83.2026 4.13429 80.5231 0.567389C80.3918 0.391665 80.2197 0.248683 80.0208 0.150101C79.822 0.0515183 79.602 0.00012207 79.3789 0.00012207C79.1558 0.00012207 78.9359 0.0515183 78.737 0.150101C78.5382 0.248683 78.3661 0.391665 78.2348 0.567389C75.5553 4.13429 68.8137 13.5444 64.9813 22.6915C61.4289 31.17 60.0489 39.9975 59.5216 44.9066C59.4912 45.1904 59.5518 45.4764 59.695 45.7253C59.8382 45.9742 60.057 46.1738 60.3213 46.2967C60.5857 46.4195 60.8826 46.4596 61.1712 46.4114C61.4599 46.3632 61.7262 46.2291 61.9335 46.0275C64.2696 43.7669 66.7271 41.6285 69.2957 39.6214C72.2595 37.3412 75.399 35.2866 78.6864 33.4758C78.8982 33.3597 79.1372 33.2987 79.3802 33.2987C79.6233 33.2987 79.8622 33.3597 80.0741 33.4758C83.3601 35.2874 86.4987 37.342 89.4622 39.6214C92.0313 41.6279 94.4888 43.7663 96.8243 46.0275C97.0316 46.2291 97.298 46.3632 97.5866 46.4114C97.8753 46.4596 98.1722 46.4195 98.4366 46.2967C98.7009 46.1738 98.9197 45.9742 99.0629 45.7253C99.2061 45.4764 99.2667 45.1904 99.2363 44.9066C98.7115 39.9975 97.329 31.17 93.7766 22.6915Z"
        fill="url(#icon-logo-paint0_linear_226_11148)"
      />
      <path
        d="M82.873 39.1175C81.8411 38.4021 80.2718 37.5368 79.6511 37.2C79.57 37.1566 79.4789 37.1338 79.3863 37.1338C79.2936 37.1338 79.2024 37.1566 79.1213 37.2C78.5006 37.5368 76.9288 38.4021 75.8969 39.1175C75.019 39.7325 74.1846 40.4043 73.3991 41.1283C73.3097 41.2087 73.2501 41.3158 73.2301 41.4328C73.2101 41.5498 73.2306 41.6699 73.2885 41.7742C73.3464 41.8785 73.4383 41.9611 73.5497 42.0088C73.6611 42.0566 73.7857 42.0668 73.9037 42.0379L79.2702 40.8333C79.353 40.8125 79.4398 40.8125 79.5225 40.8333L84.8915 42.0379C85.0095 42.0668 85.134 42.0566 85.2454 42.0088C85.3568 41.9611 85.4487 41.8785 85.5066 41.7742C85.5644 41.6699 85.585 41.5498 85.565 41.4328C85.5449 41.3158 85.4855 41.2087 85.3961 41.1283C84.6025 40.4034 83.7596 39.7316 82.873 39.1175Z"
        fill="white"
      />
      <mask id="icon-logo-mask0_226_11148" style={{ maskType: 'alpha' }} maskUnits="userSpaceOnUse" x="59" y="0" width="41" height="47">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M80.5236 0.567389C83.2031 4.13429 89.9446 13.5444 93.7771 22.6915C97.3295 31.17 98.712 39.9975 99.2368 44.9066C99.2672 45.1904 99.2066 45.4764 99.0634 45.7253C98.9201 45.9742 98.7014 46.1738 98.437 46.2967C98.1727 46.4195 97.8758 46.4596 97.5871 46.4114C97.2985 46.3632 97.0321 46.2291 96.8248 46.0275C94.4893 43.7663 92.0318 41.6279 89.4627 39.6214C86.4992 37.342 83.3606 35.2874 80.0746 33.4758C79.8627 33.3597 79.6238 33.2987 79.3807 33.2987C79.1377 33.2987 78.8987 33.3597 78.6869 33.4758C75.3995 35.2866 72.26 37.3412 69.2962 39.6214C66.7276 41.6285 64.2701 43.7669 61.9339 46.0275C61.7267 46.2291 61.4604 46.3632 61.1717 46.4114C60.8831 46.4596 60.5862 46.4195 60.3218 46.2967C60.0575 46.1738 59.8387 45.9742 59.6955 45.7253C59.5523 45.4764 59.4917 45.1904 59.522 44.9066C60.0494 39.9975 61.4294 31.17 64.9818 22.6915C68.8142 13.5444 75.5558 4.13429 78.2353 0.567389C78.3666 0.391665 78.5387 0.248683 78.7375 0.150101C78.9364 0.0515183 79.1563 0.00012207 79.3794 0.00012207C79.6025 0.00012207 79.8225 0.0515183 80.0213 0.150101C80.2202 0.248683 80.3923 0.391665 80.5236 0.567389ZM79.6518 37.2001C80.2724 37.5369 81.8418 38.4022 82.8737 39.1175C83.7602 39.7317 84.6032 40.4035 85.3967 41.1283C85.4862 41.2087 85.5456 41.3159 85.5656 41.4328C85.5856 41.5498 85.5651 41.6699 85.5072 41.7742C85.4493 41.8786 85.3574 41.9611 85.246 42.0089C85.1346 42.0566 85.0101 42.0668 84.8921 42.0379L79.5232 40.8334C79.4404 40.8126 79.3536 40.8126 79.2709 40.8334L73.9044 42.0379C73.7864 42.0668 73.6617 42.0566 73.5503 42.0089C73.4389 41.9611 73.347 41.8786 73.2892 41.7742C73.2313 41.6699 73.2107 41.5498 73.2308 41.4328C73.2508 41.3159 73.3103 41.2087 73.3998 41.1283C74.1852 40.4043 75.0197 39.7326 75.8976 39.1175C76.9295 38.4022 78.5013 37.5369 79.1219 37.2001C79.2031 37.1566 79.2943 37.1338 79.3869 37.1338C79.4795 37.1338 79.5706 37.1566 79.6518 37.2001Z"
          fill="white"
        />
      </mask>
      <g mask="url(#icon-logo-mask0_226_11148)">
        <rect opacity="0.2" x="79.2908" y="-0.901123" width="21.0833" height="47.7599" fill="black" />
      </g>
      <path
        d="M6.00855 16.2753H26.884V20.7296H10.6559C9.35658 20.7296 8.29688 21.9587 8.29688 23.4828V35.2529C8.29688 36.7696 9.35658 38.0036 10.6559 38.0036H26.884V42.4628H6.00855C2.69582 42.4628 0.0012207 39.4318 0.0012207 35.7052V23.0281C0.0012207 19.3038 2.69582 16.2753 6.00855 16.2753Z"
        fill="white"
      />
      <path
        d="M56.9139 20.1569C57.2919 21.07 57.4847 22.0459 57.4816 23.0305V24.8398C57.4816 26.8605 56.4724 28.6009 55.004 29.3678C56.4623 30.1348 57.4816 31.8753 57.4816 33.8959V42.4629H49.1885V33.4436C49.1885 32.4259 48.545 31.5975 47.7579 31.5975H38.8945V42.4629H30.6013V16.2728H51.4743C53.7929 16.2728 55.9274 17.7969 56.9139 20.1569ZM38.8945 20.7321V27.1382H47.7579C48.545 27.1382 49.1885 26.3098 49.1885 25.2921V23.4828C49.1885 21.9661 48.1288 20.7321 46.8295 20.7321H38.8945Z"
        fill="white"
      />
      <path
        d="M107.329 16.2753H128.204V20.7296H111.976C110.677 20.7296 109.617 21.9587 109.617 23.4828V24.3874C109.617 25.9042 110.677 27.1358 111.976 27.1358H122.197C123.594 27.1494 124.935 27.6761 125.951 28.6107C126.648 29.2634 127.205 30.0441 127.591 30.9078C127.976 31.7715 128.182 32.701 128.197 33.6427C128.197 33.714 128.197 33.8025 128.197 33.8885V35.7002C128.197 35.7814 128.197 35.8576 128.197 35.9461C128.171 36.8498 127.981 37.7419 127.637 38.5813C127.262 39.4952 126.686 40.3177 125.949 40.9904C124.934 41.9258 123.594 42.4527 122.197 42.4653H101.322V38.0085H117.55C118.849 38.0085 119.909 36.7794 119.909 35.2578V34.3556C119.909 32.8364 118.849 31.6024 117.55 31.6024H107.329C105.931 31.5916 104.589 31.0645 103.575 30.1274C102.878 29.4753 102.321 28.6954 101.936 27.8326C101.551 26.9698 101.344 26.0412 101.329 25.1003C101.329 25.0118 101.329 24.9332 101.329 24.8545V23.0428C101.329 22.9592 101.329 22.8732 101.329 22.797C101.356 21.899 101.546 21.0126 101.889 20.1789C102.263 19.2662 102.84 18.4452 103.577 17.7748C104.588 16.8304 105.929 16.2945 107.329 16.2753Z"
        fill="white"
      />
      <path
        d="M150.511 16.2728H158.804V42.4629H150.511V31.5975H140.217V42.4629H131.921V16.2728H140.217V27.1382H150.511V16.2728Z"
        fill="white"
      />
      <path
        d="M168.529 16.2753H189.404V20.732H173.176C171.877 20.732 170.819 21.9612 170.819 23.4853V27.1382H180.112V31.5975H170.819V38.0085H189.404V42.4678H162.521V23.033C162.521 19.3038 165.216 16.2753 168.529 16.2753Z"
        fill="white"
      />
      <path
        d="M219.434 20.1569C219.812 21.07 220.005 22.0459 220.002 23.0305V24.8398C220.002 26.8605 218.993 28.6009 217.524 29.3678C218.983 30.1348 220.002 31.8753 220.002 33.8959V42.4629H211.709V33.4436C211.709 32.4259 211.068 31.5975 210.278 31.5975H201.415V42.4629H193.122V16.2728H213.997C216.313 16.2728 218.448 17.7969 219.434 20.1569ZM201.415 20.7321V27.1382H210.278C211.068 27.1382 211.709 26.3098 211.709 25.2921V23.4828C211.709 21.9661 210.649 20.7321 209.35 20.7321H201.415Z"
        fill="white"
      />
    </g>
  </svg>
);

IconLogo.propTypes = {
  className: PropTypes.string,
};

IconLogo.defaultProps = {
  className: '',
};
