import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withCoreComponent } from 'core/hocs';
import { GameWinnerList as GameWinnerListCore, FormattedTag } from 'core/components';
import { isEmptyOrNil, getStaticUrl } from 'core/helpers';
import { ImageLazyLoad, LAZY_LOAD_TYPES } from 'components/image-lazy-load/image-lazy-load';
import { Carousel } from 'components/carousel/carousel';
import { formatAmountWithCurrency } from 'helpers/numbers';
import { setUsernameHidden } from 'helpers/username';
import { IconPlayCircle } from '../icons/icon-play-circle/icon-play-circle';
import './game-winner-carousel-mobile.scss';

const DEFAULT_THUMB_PATH = `${getStaticUrl()}/images/default-game-bg/thumb.jpg`;

const carouselSettings = {
  arrows: false,
  centerMode: true,
  infinite: true,
  // autoplay: true,
  // autoplaySpeed: 3000,
  responsive: [
    {
      breakpoint: 445,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        variableWidth: true,
      },
    },
    {
      breakpoint: 719,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 2,
        variableWidth: true,
      },
    },
    {
      breakpoint: 1023,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 2,
        variableWidth: true,
      },
    },
  ],
};

class GameWinnerCarouselMobileUI extends Component {
  static propTypes = {
    locale: PropTypes.string.isRequired,
    items: PropTypes.arrayOf(PropTypes.shape([])),
    openGame: PropTypes.func.isRequired,
  }

  static defaultProps = {
    items: [],
  }

  openGame = ({
    currentTarget: {
      dataset: { id: gameId, producer },
    },
  }) => {
    const { openGame } = this.props;

    openGame({ gameId, producer });
  };

  render() {
    const {
      locale,
      items,
    } = this.props;

    return (
      !isEmptyOrNil(items) && (
        <div className="game-winner-carousel d-md-none bg-primary border-bottom border-white-20 w-100">
          <Carousel settings={carouselSettings}>
            {items.map(({
              gameId, producer, playerName,
              amountWon, currency, title, thumbPath,
            }, idx) => (
              // eslint-disable-next-line react/no-array-index-key
              <div key={`${gameId}-${producer}-${idx}`} className="d-flex my-1">
                {/* <ImageLazyLoad */}
                {/*   src="/assets/public/images/transparent-pixel.png" */}
                {/*   className="game-winner-carousel-mobile-image flex-shrink-0" */}
                {/*   classNameLoaded="game-winner-carousel-mobile-loaded" */}
                {/*   classNameError="game-winner-carousel-mobile-error" */}
                {/*   srcLazy={thumbPath} */}
                {/*   type={LAZY_LOAD_TYPES.BACKGROUND} */}
                {/*   srcFallback={DEFAULT_THUMB_PATH} */}
                {/* > */}
                {/*   <div */}
                {/*     className="game-winner-carousel-mobile-overlay d-flex  */}
                {/*     justify-content-center align-items-center h-100" */}
                {/*     role="button" */}
                {/*     tabIndex="0" */}
                {/*     data-id={gameId} */}
                {/*     data-producer={producer} */}
                {/*     onClick={this.openGame} */}
                {/*     onKeyPress={this.openGame} */}
                {/*   /> */}
                {/* </ImageLazyLoad> */}
                <div className="bg-gray-40 rounded">
                  <ImageLazyLoad
                    src="/assets/public/images/transparent-pixel.png"
                    className="game-winner-list-image flex-shrink-0 rounded"
                    classNameLoaded="game-winner-list-image-loaded"
                    classNameError="game-winner-list-image-error"
                    srcLazy={thumbPath}
                    type={LAZY_LOAD_TYPES.BACKGROUND}
                    srcFallback={DEFAULT_THUMB_PATH}
                  >
                    <div
                      className="game-winner-list-image-overlay d-flex justify-content-center align-items-center rounded"
                      role="button"
                      tabIndex="0"
                      data-id={gameId}
                      data-producer={producer}
                      onClick={this.openGame}
                      onKeyPress={this.openGame}
                    >
                      <IconPlayCircle className="game-winner-list-play-icon" isSmall />
                    </div>
                  </ImageLazyLoad>
                </div>

                <div className="d-flex justify-content-between align-items-center overflow-hidden flex-grow-1 ml-2 h6">
                  <div className="d-flex flex-column">
                    <div className="d-flex">
                      <span className="text-capitalize mr-0_5 font-weight-normal">{setUsernameHidden(playerName, true)}</span>
                      <FormattedTag id="won" className="mr-0_5 font-weight-normal" />
                      <span className="font-weight-bold text-blue">
                        {formatAmountWithCurrency({
                          amount: amountWon, currency, locale, isIsoFormat: true,
                        })}
                      </span>
                    </div>

                    <div className="d-flex">
                      <FormattedTag id="in" className="mr-0_5 font-weight-normal" />

                      <span
                        className="text-truncate font-weight-bold"
                        title={title}
                        role="button"
                        tabIndex="0"
                        data-id={gameId}
                        data-producer={producer}
                        onClick={this.openGame}
                        onKeyPress={this.openGame}
                      >
                        {title}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </Carousel>
        </div>
      )
    );
  }
}

export const GameWinnerCarouselMobile = withCoreComponent(GameWinnerListCore, GameWinnerCarouselMobileUI);
