import React from 'react';
import PropTypes from 'prop-types';

export const IconLangTh = ({ className }) => (
  <svg className={className} xmlns="http://www.w3.org/2000/svg" width="30" height="24" viewBox="0 0 30 24">
    <g>
      <rect
        x="0"
        y="0"
        width="30"
        height="24"
        style={{
          fill: 'rgb(95.686275%,96.078431%,97.254902%)',
          fillOpacity: 1,
          stroke: 'none',
        }}
      />
      <path
        style={{
          stroke: 'none',
          fillRule: 'evenodd',
          fill: 'rgb(17.647059%,16.470588%,29.019608%)',
          fillOpacity: 1,
        }}
        d="M 0 8.125 L 30 8.125 L 30 16.125 L 0 16.125 Z M 0 8.125 "
      />
      <path
        style={{
          stroke: 'none',
          fillRule: 'evenodd',
          fill: 'rgb(64.705882%,9.803922%,19.215686%)',
          fillOpacity: 1,
        }}
        d="M 0 0 L 30 0 L 30 4.125 L 0 4.125 Z M 0 20 L 30 20 L 30 24 L 0 24 Z M 0 20 "
      />
    </g>
  </svg>
);

IconLangTh.propTypes = {
  className: PropTypes.string,
};

IconLangTh.defaultProps = {
  className: null,
};
