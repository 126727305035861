import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { fromEvent } from 'rxjs';
import { withUserAgent } from 'core/hocs';
import { MOBILE, MOBILE_SAFARI } from '../constants';

const getBottomSafeArea = (element) => {
  const bottomSafeAreaString = getComputedStyle(element).getPropertyValue('--sab');
  const bottomSafeArea = Number(bottomSafeAreaString.substring(0, bottomSafeAreaString.length - 2));

  return bottomSafeArea || 0;
};

export const withSafariSearchBar = (WrappedComponent) => {
  class WithSafariSearchBar extends Component {
    static propTypes = {
      deviceType: PropTypes.string.isRequired,
      browserName: PropTypes.string.isRequired,
    };

    state = {
      navBarHeight: 0,
      isShowComponent: false,
    };

    resizeSubscription = null;

    componentDidMount() {
      const { deviceType, browserName } = this.props;

      if (deviceType === MOBILE && browserName === MOBILE_SAFARI) {
        this.resizeSubscription = fromEvent(window, 'resize')
          .subscribe(() => {
            if (window.matchMedia('(orientation: landscape)').matches) {
              this.setState({
                navBarHeight:
                  document.documentElement.clientHeight
                  + getBottomSafeArea(document.documentElement)
                  - window.innerHeight
              });
            } else if (window.matchMedia('(orientation: portrait)').matches) {
              this.setState({ navBarHeight: 0 });
            }
          });

        setTimeout(() => {
          if (window.matchMedia('(orientation: landscape)').matches) {
            window.scrollBy(0, 1);
          }

          this.setState({
            isShowComponent: true,
            navBarHeight: document.documentElement.clientHeight - window.innerHeight
          });
        });
      } else {
        this.setState({ isShowComponent: true });
      }
    }

    componentWillUnmount() {
      if (this.resizeSubscription) {
        this.resizeSubscription.unsubscribe();
      }
    }

    render() {
      const { navBarHeight, isShowComponent } = this.state;

      return (
        isShowComponent ? (
          <WrappedComponent
            {...this.props}
            navBarHeight={navBarHeight}
          />
        ) : null
      );
    }
  }

  return withUserAgent(WithSafariSearchBar);
};
