import React from 'react';
import PropTypes from 'prop-types';

export const IconPinterest = ({ className }) => (
  <svg className={className} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M10 2a8 8 0 0 0-2.915 15.45c-.07-.633-.134-1.605.028-2.295.145-.624.938-3.977.938-3.977s-.24-.48-.24-1.187c0-1.113.645-1.943 1.448-1.943.682 0 1.012.513 1.012 1.127 0 .686-.437 1.712-.663 2.663-.189.797.4 1.446 1.185 1.446 1.422 0 2.514-1.5 2.514-3.663 0-1.916-1.376-3.255-3.341-3.255-2.276 0-3.612 1.707-3.612 3.472 0 .687.265 1.425.595 1.826a.24.24 0 0 1 .056.23c-.061.252-.196.795-.222.907-.035.146-.116.177-.268.106-.999-.465-1.624-1.926-1.624-3.099 0-2.523 1.834-4.841 5.286-4.841 2.776 0 4.932 1.977 4.932 4.62 0 2.758-1.738 4.976-4.151 4.976-.81 0-1.573-.421-1.833-.918 0 0-.402 1.527-.499 1.902-.18.694-.668 1.566-.995 2.097.749.23 1.544.356 2.369.356a8 8 0 0 0 0-16Z" fill="#A3ACB3" />
  </svg>
);

IconPinterest.propTypes = {
  className: PropTypes.string,
};

IconPinterest.defaultProps = {
  className: '',
};
