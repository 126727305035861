import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import Button from 'reactstrap/lib/Button';
import { withModalActions, withLocale } from 'core/hocs';
import { FormattedTag } from 'core/components';
import { wrapRoute2Locale } from 'core/helpers';

import { ModalWindow } from 'components/modal-window/modal-window';
import { IconError } from 'components/icons/icon-notification/icon-error';
import { MODAL_IDS } from '../../../constants';

import './kyc-notification-modal.scss';

class KycNotificationModalUI extends Component {
  static propTypes = {
    closeModal: PropTypes.func.isRequired,
    history: PropTypes.shape().isRequired,
    locale: PropTypes.string.isRequired,
  };

  closeModal = () => {
    const {
      closeModal, history, locale
    } = this.props;

    closeModal(MODAL_IDS.KYC_VERIFICATION_NOTIFICATION);

    setTimeout(() =>
      history.push(wrapRoute2Locale('/profile/verification', locale)),
    1);
  }

  render() {
    return (
      <ModalWindow
        id={MODAL_IDS.KYC_VERIFICATION_NOTIFICATION}
        coreProps={{ id: MODAL_IDS.KYC_VERIFICATION_NOTIFICATION }}
        bodyClassName="d-flex d-sm-block align-items-center flex-column justify-content-center text-center kyc-alert-modal"
        wrapClassName="wrapper"
        isModalCentered
        isModalAlignTop={false}
        centered
        fade
      >
        <IconError className="mb-2_5 icon-alert" />

        <FormattedTag
          tag="div"
          id="kyc-notification.title"
          className="h2 mb-1 text-center text-blue-dark font-family-oswald"
        />
        <FormattedTag
          tag="div"
          id="kyc-notification.subtitle"
          className="text-blue-dark mb-2_5 mb-sm-3 text-center text-small text-sm-base"
        />

        <FormattedTag
          tag={Button}
          color="primary"
          className="px-4_25 mt-1"
          id="verify"
          onClick={this.closeModal}
        />
      </ModalWindow>
    );
  }
}

export const KycNotificationModal = withModalActions(withRouter(withLocale(KycNotificationModalUI)));
