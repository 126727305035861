import React from 'react';
import PropTypes from 'prop-types';
import { withCoreComponent } from 'core/hocs';
import { Main as MainCore } from 'core/components';

export const MainUI = ({ className, children }) => (
  <main className={className}>
    {children}
  </main>
);

MainUI.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
};

MainUI.defaultProps = {
  className: '',
};

export const Main = withCoreComponent(MainCore, MainUI);
