import * as R from 'ramda';

const COUNT_CHARACTERS_TO_SHOW = 1;

export const getUsernameFromEmail = email => email.split('@')[0];

export const setUsernameHidden = (username) => {
  if (!username) {
    return '';
  }

  const repeatCount = (username || '').length - COUNT_CHARACTERS_TO_SHOW;
  const restNameEncrypted = '*'.repeat(R.when(R.gt(0), R.always(0))(repeatCount));

  return username.length > COUNT_CHARACTERS_TO_SHOW ? `${username.slice(0, COUNT_CHARACTERS_TO_SHOW)}${restNameEncrypted}` : username;
};
