module.exports = {
  bonusCode: {
    type: 'text-button',
    label: '',
    placeholder: 'gifts.redeem.placeholder',
    validation: [
      { type: 'required' },
    ],
  },
};
