import React from 'react';
import PropTypes from 'prop-types';

export const IconVip = ({ className }) => (
  <svg className={className} width="37" height="37" viewBox="0 0 37 37" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#vip-icon-clip-path)">
      <path d="M27.4717 12.3049C27.1672 12.1781 26.8318 12.1448 26.5083 12.2092C26.1847 12.2736 25.8877 12.4328 25.655 12.6666L23.5 14.8216L19.6783 10.9999C19.3658 10.6875 18.9419 10.512 18.5 10.512C18.0581 10.512 17.6342 10.6875 17.3217 10.9999L13.5 14.8216L11.345 12.6666C11.1119 12.4336 10.815 12.2749 10.4917 12.2106C10.1685 12.1464 9.83341 12.1794 9.52891 12.3055C9.22441 12.4316 8.96414 12.6452 8.781 12.9192C8.59786 13.1932 8.50007 13.5154 8.5 13.8449V22.6666C8.50132 23.7713 8.94073 24.8303 9.72185 25.6114C10.503 26.3925 11.562 26.832 12.6667 26.8333H24.3333C25.438 26.832 26.497 26.3925 27.2782 25.6114C28.0593 24.8303 28.4987 23.7713 28.5 22.6666V13.8449C28.5001 13.5153 28.4024 13.1931 28.2194 12.919C28.0364 12.6449 27.7761 12.4312 27.4717 12.3049Z" fill="url(#vip-icon-linear-paint)" />
    </g>
  </svg>
);

IconVip.propTypes = {
  className: PropTypes.string
};

IconVip.defaultProps = {
  className: ''
};
