import { getIntlKeyByEnv } from 'core/helpers';
import { LOCALE_KEYS, FORM_FIELDS, GAMES_SUBCATEGORY } from 'core/constants';

export const MODAL_IDS = {
  UPDATE_PASSWORD: 'update-password-modal',
  RESET_LINK_ERROR: 'link-error-modal',
  FREE_SPINS: 'free-spins-modal',
  SUPPORT: 'support-modal',
  PROFILE: 'profile-modal',
  PRODUCERS: 'producers-modal',
  DEPOSIT: 'deposit-modal',
  COMPLETE_SIGN_UP: 'complete-sign-up-modal',
  AUTH: 'auth-modal',
  RESET_PASSWORD: 'reset-password-modal',
  CONFIRM_PHONE: 'confirm-phone-modal',
  NEW_LEVEL: 'new-level-modal',
  EXCHANGE_LOYALTY_POINTS: 'exchange-loyalty-points-modal',
  CANCEL_BONUS: 'cancel-bonus-modal',
  WITHDRAWAL_ALERT: 'withdrawal-alert-modal',
  PWA_INSTALL: 'pwa-install-modal',
  PWA_INSTALL_MOBILE: 'pwa-install-mobile-modal',
  GAMES_CATEGORIES: 'games-categories-modal',
  SIGN_IN: 'sign-in-modal',
  SIGN_UP: 'sign-up-modal',
  PROMO_INFO: 'promo-info-modal',
  CONFIRM_OTP: 'confirm-otp-modal',
  SETUP_OTP: 'setup-otp-modal',
  REMOVE_OTP: 'remove-otp-modal',
  PAYMENT_SUCCESS: 'payment-success',
  KYC_VERIFICATION_WARNING: 'kyc-verification-warning',
  KYC_VERIFICATION_NOTIFICATION: 'kyc-notification-modal',
};

export const AUTH_TYPES = {
  SIGN_IN: 'sign-in',
  SIGN_UP: 'sign-up',
};

export const EMPTY_FIELD_DEFAULT = '-';

export const TOURNAMENTS_EMPTY_FIELDS = {
  USER: EMPTY_FIELD_DEFAULT,
  POINTS: EMPTY_FIELD_DEFAULT,
  PRIZE: EMPTY_FIELD_DEFAULT,
  NOT_LISTED: EMPTY_FIELD_DEFAULT,
  ROW: '...',
};

export const BREAKPOINT_SM = 720;
export const BREAKPOINT_MD = 1024;
export const BREAKPOINT_LG = 1280;

export const IFRAME_SETTINGS = {
  GAME_CONTAINER_OFFSET_Y: 204, // 244
  GAME_CONTAINER_OFFSET_YS: 184,
  GAME_CONTAINER_OFFSET_X: 80,
  GAME_CONTAINER_OFFSET_XS: 40,
  WIDTH_TO_HEIGHT_IFRAME_HD: 16 / 9,
  HEIGHT_TO_WIDTH_IFRAME_HD: 9 / 16,
  WIDTH_TO_HEIGHT_IFRAME_NOT_HD: 4 / 3,
  HEIGHT_TO_WIDTH_IFRAME_NOT_HD: 3 / 4,
};

export const CURRENCY_SYMBOLS = {
  EUR: '€',
  USD: '$',
  RUB: '₽',
  CAD: '$',
  KZT: '₸',
  AUD: '$',
  NOK: 'KR',
  SEK: 'KR',
  PLN: 'zł',
  HUF: 'Ft',
  TRY: '₺',
  BOB: 'Bs',
  CLP: '$',
  COP: '$',
  CRC: '₡',
  DOP: '$',
  GBP: '£',
  INP: '₹',
  JPY: '¥',
  KRW: '₩',
  LKR: 'Rs',
  MXN: '$',
  PEN: 'S',
  PKR: '₨',
  PYG: '₲',
  UYU: '$',
  VES: 'Bs',
  THB: '฿',
};

const hindiToEnglishKeyboard = {
  'ौ': 'q',
  औ: 'Q',
  'ै': 'w',
  ऐ: 'W',
  'ा': 'e',
  आ: 'E',
  'ी': 'r',
  ई: 'R',
  'ू': 't',
  ऊ: 'T',
  ब: 'y',
  भ: 'Y',
  ह: 'u',
  ङ: 'U',
  ग: 'i',
  घ: 'I',
  द: 'o',
  ध: 'O',
  ज: 'p',
  झ: 'P',
  'ो': 'a',
  ओ: 'A',
  'े': 's',
  ए: 'S',
  '्': 'd',
  अ: 'D',
  'ि': 'f',
  इ: 'F',
  'ु': 'g',
  उ: 'G',
  प: 'h',
  फ: 'H',
  र: 'j',
  ऱ: 'J',
  क: 'k',
  ख: 'K',
  त: 'l',
  थ: 'L',
  'ं': 'x',
  'ँ': 'X',
  म: 'c',
  ण: 'C',
  न: 'v',
  व: 'b',
  ल: 'n',
  स: 'm',
  श: 'M',
  '१': '1',
  ऍ: '!',
  '२': '2',
  '३': '3',
  '४': '4',
  '५': '5',
  '६': '6',
  '७': '7',
  '८': '8',
  '९': '9',
  '०': '0',
};

const spainToEnglishKeyboard = {
  "'": '-',
};
export const gamesSearchAvailableLanguages = ['hin', 'spa', 'por'];

export const keyboardLayoutsMappingCollection = {
  hin: hindiToEnglishKeyboard,
  por: spainToEnglishKeyboard,
  spa: spainToEnglishKeyboard,
};

export const PAGE_NAMES = {
  HOME: 'home',
  PROFILE: 'profile',
  BANKING_HISTORY: 'banking-history',
  GIFTS: 'gifts',
  PAYMENTS: 'payments',
  WALLET: 'wallet',
  GAMES: 'games',
  LOYALTY: 'loyalty',
  GAME: 'game',
  PROMOS: 'promos',
  TOURNAMENT: 'tournament',
  SEARCH: 'search',
  RESET_PASSWORD: 'reset-password',
  TERMS: 'terms',
  BONUS_TERMS: 'bonus-terms',
  AML: 'anti-money-laundering',
  REFUND: 'refund-policy',
  PRIVACY: 'privacy-policy',
  ABOUT: 'about',
  AFFILIATE: 'affiliate',
  CATEGORY: 'category',
  PROVIDER: 'provider',
  PARTNERS: 'partners',
  TOURNAMENTS: 'tournaments',
  NOT_FOUND: 'not-found',
  FROM_PWA: 'from-pwa',
  FAQ: 'faq',
  RESPONSIBLE_GAMING: 'responsible-gaming',
  BONUS: 'bonus',
  SPORTSBOOK: 'sportsbook',
  IN_PLAY: 'in-play',
  MAINTENANCE: 'maintenance',
  VIP: 'vip',
  RESTRICTED_COUNTRY: 'restricted-country',
  SELF_EXCLUSION: 'self-exclusion',
  DISPUTE_RESOLUTION: 'dispute-resolution',
  FAIRNESS_AND_RNG: 'fairness-and-rng',
  KYC: 'kyc',
  GAME_TAGS: 'game-tags',
  DEPOSIT: 'deposit',
  WITHDRAWAL: 'withdrawal',
  VERIFICATION: 'verification',
  LIMITS: 'limits',
  PLAY_HISTORY: 'play-history',
  CASHIER: 'cashier',
  LICENSE: 'license',
  CRASH_GAMES: 'crash-games',
  SUBCATEGORY: 'subcategory',
};

export const LOCATION_PATH_PREV = 'LOCATION_PATH_PREV';

export const LS_ACTIONS_KEY = 'BETWEEN_TABS_ACTION';
export const LS_ACTIONS = {
  SIGN_IN: 'SIGN_IN',
  SIGN_OUT: 'SIGN_OUT',
  SET_CHARACTER: 'SET_CHARACTER',
};

export const HISTORY_STATE_ACTIONS = {
  OPEN_MODAL: 'OPEN_MODAL',
  LS_ACTION_SIGN_IN: 'LS_ACTION_SIGN_IN',
};

export const SUBCATEGORIES_BY_PAGE = {
  GAMES: [
    GAMES_SUBCATEGORY.CRASH_GAMES,
    GAMES_SUBCATEGORY.TOP_GAMES,
    GAMES_SUBCATEGORY.LIVE_CASINO,
    GAMES_SUBCATEGORY.NEW_GAMES,
    GAMES_SUBCATEGORY.ALL_GAMES,
    GAMES_SUBCATEGORY.JACKPOT,
  ],
};

export const TRANSLATION_KEY_FOR_SIGN_UP_BANNER_BONUS = getIntlKeyByEnv('form.sign-up.bonus');

export const SIDE_MODAL_IDS = {
  SIDE_MODAL_BAR: 'side-modal-bar',
};

export const MODAL_SHOWN_CLASS = 'modal-shown';
export const MODAL_OPEN_CLASS = 'modal-open';
export const MODAL_SIGN_IN_CONTENT_CLASS = 'modal-sign-in-content';
export const MODAL_CONFIRM_PHONE_CLASS = 'confirm-phone-modal';
export const MODAL_CONFIRM_OTP_CLASS = 'confirm-otp-modal';

export const IFRAME_FULL_SCREEN = 'iframe-full-screen';

export const IFRAME_FULL_SCREEN_PROVIDER = 'iframe-full-screen-provider';

export const DATE_MASK_DIVIDERS = {
  [LOCALE_KEYS.EN]: '/',
  [LOCALE_KEYS.ES]: '/',
  [LOCALE_KEYS.RU]: '.',
};

export const FREESPINS_INFO_MOBILE = [
  'freespinsIdMobile',
  'freespinsNameMobile',
  'freespinsProducerMobile',
];

export const FREESPINS_INFO_DESKTOP = [
  'freespinsIdDesktop',
  'freespinsNameDesktop',
  'freespinsProducerDesktop',
];

export const MOBILE = 'mobile';
export const MOBILE_SAFARI = 'Mobile Safari';

export const MIN_DIGITS_CELL_PHONE_VALIDATION = 4;

export const ELEMENTS_SHOWN_AMOUNT = { // Payments and Producers Promo
  elementsShownXs: 5,
  elementsShownXsLarge: 7,
  elementsShownSm: 4,
  elementsShownMd: 5,
  elementsShownLg: 6,
};

export const ELEMENTS_SHOWN_MAX = 7;
export const PAYMENT_METHODS_ALL_AMOUNT = 17;

export const LIVECHAT_INC_GROUPS = {
  en: 0,
  ru: 1,
};

export const LIVECHAT_INC_STATS_TYPES = {
  SATISFACTION: 'satisfaction',
  REVIEWS: 'reviews',
  AVG_REPLY_TIME: 'avgReplyTime',
};

export const BONUSES_ACTION_TYPES = {
  MODAL: 'MODAL',
  LINK: 'LINK',
};

export const SPORTSBOOK_POST_MESSAGE_TYPES = {
  UNAUTHORIZED: 'SPORTSBOOK_UNAUTHORIZED',
  OUT_OF_BALANCE: 'SPORTSBOOK_OUT_OF_BALANCE',
  MOUNTED: 'SPORTSBOOK_MOUNTED',
  DEPOSIT: 'SPORTSBOOK_DEPOSIT',
  OPEN_CHAT: 'SPORTSBOOK_OPEN_CHAT',
  CHANGE_LOCATION: 'SPORTSBOOK_CHANGE_LOCATION',
  CLICK_FOOTER_LINK: 'SPORTSBOOK_CLICK_FOOTER_LINK',
  CHANGE_LOCALE: 'SPORTSBOOK_CHANGE_LOCALE',
  SIGN_UP: 'SPORTSBOOK_SIGN_UP',
  SIGN_IN: 'SPORTSBOOK_SIGN_IN',
  CLICK_LOGO: 'SPORTSBOOK_CLICK_LOGO',
  CLICK_HEADER_LINK: 'SPORTSBOOK_CLICK_HEADER_LINK',
  CLICK_MENU: 'SPORTSBOOK_CLICK_MENU',
  CLICK_USER_ACTION: 'SPORTSBOOK_CLICK_USER_ACTION',
  CLICK_SEARCH: 'SPORTSBOOK_CLICK_SEARCH',
  CLICK_CHECK_IN: 'SPORTSBOOK_CLICK_CHECK_IN',
  CLICK_PAYMENTS: 'SPORTSBOOK_CLICK_PAYMENTS',
  CLICK_BANNER: 'SPORTSBOOK_CLICK_BANNER',
  CLICK_DMCA: 'SPORTSBOOK_CLICK_DMCA',
};

export const NOT_EDITABLE_FIELDS = [
  FORM_FIELDS.FIRST_NAME,
  FORM_FIELDS.LAST_NAME,
  FORM_FIELDS.EMAIL,
  FORM_FIELDS.MOBILE_PHONE,
  FORM_FIELDS.COUNTRY,
  FORM_FIELDS.GENDER,
  FORM_FIELDS.BIRTH_DATE,
  FORM_FIELDS.YEAR,
  FORM_FIELDS.MONTH,
  FORM_FIELDS.DAY,
  FORM_FIELDS.ADDRESS,
  FORM_FIELDS.CITY,
  FORM_FIELDS.POSTAL_CODE,
  FORM_FIELDS.DOC_NUMBER,
];

export const AVAILABLE_BONUS_TYPE = 'AVAILABLE_BONUS_TYPE';
export const USED_BONUS_TYPE = 'USED_BONUS_TYPE';

export const GAME_SCREEN_MODE = {
  SINGLE: 'SINGLE',
  TWIN: 'TWIN',
  QUARTER: 'QUARTER',
};

export const IMAGES_DATA_CATEGORIES = {
  TOURNAMENT: 'TOURNAMENT',
  BONUS_SELECTION: 'BONUS_SELECTION',
  SIGN_UP: 'SIGN_UP',
  VIP_DESCRIPTION: 'VIP_DESCRIPTION',
};

export const SPECIFIC_LIVE_GAMES_PROVIDERS = ['TvBet', 'TVBET', 'Spribe', 'Kiron', 'Aviatrix', 'BETGAMES'];

export const SPECIFIC_LIVE_GAMES_PROVIDERS_FIXED_HEIGHT = ['Kiron'];

export const BANNER_CATEGORIES = {
  HOME_PAGE: 'HOME_PAGE',
  CASINO_PAGE: 'CASINO_PAGE',
  LIVE_PAGE: 'LIVE_PAGE',
  COMMON: 'COMMON',
};

export const DOCUMENT_SIDES = {
  BACK: 'BACK',
  FRONT: 'FRONT',
};

export const WITHDRAWAL_LIMIT_BY_CURRENCY = {
  USD: 50,
  JPY: 6000,
};

export const KYC_STATUS = {
  PASS: 'PASS',
};

export const WITHDRAWAL_EXCEPTION_BY_CURRENCY = {
  MXN: 8000,
};

export const GTM_EVENT_FIELDS = {
  USER_ID: 'userId',
  TRANSACTION_ID: 'transaction_id',
  VALUE: 'value',
  CURRENCY: 'currency',
};
