import React from 'react';
import PropTypes from 'prop-types';

import colors from '../../../customizations/js/color-variables';

export const IconLock = ({ color }) => (

  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24">
    <path fill={color} d="M6 10.17V8a6 6 0 0 1 6-6 6 6 0 0 1 6 6v2.17c1.165.413 2 1.524 2 2.83v6a3 3 0 0 1-3 3H7a3 3 0 0 1-3-3v-6c0-1.306.835-2.417 2-2.83zM8 10h8V8a4 4 0 1 0-8 0v2zm-1 2a1 1 0 0 0-1 1v6a1 1 0 0 0 1 1h10a1 1 0 0 0 1-1v-6a1 1 0 0 0-1-1H7zm5 6a1 1 0 0 1-1-1v-2a1 1 0 1 1 2 0v2a1 1 0 0 1-1 1z" />
  </svg>
);

IconLock.propTypes = {
  color: PropTypes.string,
};

IconLock.defaultProps = {
  color: colors.white65,
};
