import React from 'react';
import PropTypes from 'prop-types';
import { ModalWindow } from 'components/modal-window/modal-window';
import { FormConfirmPhone } from 'components/forms/form-confirm-phone/form-confirm-phone';
import { MODAL_CONFIRM_PHONE_CLASS, MODAL_IDS } from '../../../constants';

export const ConfirmPhoneModal = () => (
  <ModalWindow
    id={MODAL_IDS.CONFIRM_PHONE}
    coreProps={{ id: MODAL_IDS.CONFIRM_PHONE }}
    classForDocumentBody={MODAL_CONFIRM_PHONE_CLASS}
    bodyClassName="pb-6"
    isModalCentered
    fade
  >
    <FormConfirmPhone />
  </ModalWindow>
);

ConfirmPhoneModal.propTypes = {
  currentModal: PropTypes.shape({ data: PropTypes.any })
};

ConfirmPhoneModal.defaultProps = {
  currentModal: null
};
