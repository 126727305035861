import { getStaticUrl, isEmptyOrNil } from 'core/helpers';

const staticUrl = getStaticUrl();

export const getCharacterImageUrl = ({
  characterId = 1,
  levelId = 1,
  devicePixelRatio = '',
  fileExtension = 'png',
} = {}) => {
  if (isEmptyOrNil(characterId)) {
    return '';
  }

  return `${staticUrl}/images/characters/new/character${characterId}/level${levelId || 1}/image${devicePixelRatio || ''}.${fileExtension}`;
};
