import React from 'react';
import PropTypes from 'prop-types';

export const IconLangEn = ({ className }) => (
  <svg className={className} width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M13 26C20.1797 26 26 20.1797 26 13C26 5.8203 20.1797 0 13 0C5.8203 0 0 5.8203 0 13C0 20.1797 5.8203 26 13 26Z"
      fill="#F0F0F0"
    />
    <path
      d="M2.68748 5.08569C1.66633 6.41428 0.896342 7.94559 0.447998 9.60914H7.21089L2.68748 5.08569Z"
      fill="#0052B4"
    />
    <path d="M25.5524 9.60909C25.1041 7.9456 24.334 6.41428 23.3129 5.08569L18.7896 9.60909H25.5524Z" fill="#0052B4" />
    <path
      d="M0.447998 16.3909C0.896398 18.0544 1.6664 19.5857 2.68751 20.9142L7.21081 16.3909H0.447998Z"
      fill="#0052B4"
    />
    <path d="M20.9145 2.68706C19.5859 1.6659 18.0547 0.895908 16.3911 0.44751V7.2104L20.9145 2.68706Z" fill="#0052B4" />
    <path
      d="M5.08569 23.3124C6.41426 24.3335 7.94555 25.1035 9.60901 25.5519V18.7891L5.08569 23.3124Z"
      fill="#0052B4"
    />
    <path
      d="M9.60909 0.44751C7.94558 0.895908 6.41425 1.6659 5.08569 2.68701L9.60909 7.21035V0.44751Z"
      fill="#0052B4"
    />
    <path d="M16.3911 25.5519C18.0546 25.1035 19.586 24.3335 20.9145 23.3124L16.3911 18.7891V25.5519Z" fill="#0052B4" />
    <path d="M18.7896 16.3909L23.3129 20.9143C24.334 19.5857 25.1041 18.0544 25.5524 16.3909H18.7896Z" fill="#0052B4" />
    <path
      d="M25.89 11.3044H14.6957H14.6957V0.110043C14.1406 0.0377813 13.5747 0 13 0C12.4252 0 11.8594 0.0377813 11.3044 0.110043V11.3043V11.3043H0.110043C0.0377813 11.8594 0 12.4253 0 13C0 13.5748 0.0377813 14.1406 0.110043 14.6956H11.3043H11.3043V25.89C11.8594 25.9622 12.4252 26 13 26C13.5747 26 14.1406 25.9623 14.6956 25.89V14.6957V14.6957H25.89C25.9622 14.1406 26 13.5748 26 13C26 12.4253 25.9622 11.8594 25.89 11.3044Z"
      fill="#D80027"
    />
    <path
      d="M16.3911 16.3917L22.1922 22.1927C22.459 21.926 22.7135 21.6472 22.9564 21.3581L17.9898 16.3916H16.3911V16.3917Z"
      fill="#D80027"
    />
    <path
      d="M9.60861 16.3916H9.60851L3.80737 22.1926C4.07408 22.4594 4.35293 22.7139 4.64198 22.9568L9.60861 17.9901V16.3916Z"
      fill="#D80027"
    />
    <path
      d="M9.60854 9.60858V9.60848L3.80738 3.80737C3.54057 4.07408 3.28605 4.35292 3.04321 4.64196L8.00986 9.60853H9.60854V9.60858Z"
      fill="#D80027"
    />
    <path
      d="M16.3911 9.60896L22.1923 3.80781C21.9256 3.541 21.6467 3.28649 21.3577 3.0437L16.3911 8.01026V9.60896Z"
      fill="#D80027"
    />
  </svg>
);

IconLangEn.propTypes = {
  className: PropTypes.string,
};

IconLangEn.defaultProps = {
  className: null,
};
