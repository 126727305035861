import React, { Component, createContext } from 'react';
import PropTypes from 'prop-types';
import { withUserAgent, withModalActions } from 'core/hocs';

export const withPwaInstallContext = createContext({});

export const withPwaInstall = (WrappedComponent) => {
  class withPwaInstallUI extends Component {
    static propTypes = {
      openModal: PropTypes.func.isRequired,
      os: PropTypes.string,
      isPwa: PropTypes.bool,
      browserName: PropTypes.string
    };

    static defaultProps = {
      os: null,
      browserName: null,
      isPwa: false,
    };

    state = {
      isDeferredPromptReady: false,
      isPwaGuideReady: false,
      isIncognito: false,
    };

    deferredPrompt;

    componentDidMount() {
      const { os, browserName } = this.props;

      window.addEventListener('beforeinstallprompt', (e) => {
        this.deferredPrompt = e;
        this.setState({ isDeferredPromptReady: true });
      });

      window.onload = () => {
        if ((os === 'ios' && browserName !== 'Mobile Safari') || (os === 'android' && browserName !== 'Chrome')) {
          this.setState({ isPwaGuideReady: true });
        } else if ('serviceWorker' in navigator) {
          this.setState({ isPwaGuideReady: true });
        }

        if ('storage' in navigator && 'estimate' in navigator.storage) {
          navigator.storage.estimate().then(({ quota }) => this.setState({ isIncognito: quota < 120000000 }));
        } else {
          console.error('Incognito cannot be detected');
        }
      };
    }

    onInstallClick = (modalId) => {
      const { os, browserName, openModal } = this.props;
      const { isIncognito } = this.state;

      if (os === 'android' && browserName === 'Chrome') {
        if (this.deferredPrompt) {
          this.deferredPrompt.prompt();

          return;
        }

        if (!isIncognito) {
          return;
        }
      }

      openModal(modalId);
    }

    render() {
      const {
        openModal, os, browserName, isPwa
      } = this.props;

      const { isDeferredPromptReady, isPwaGuideReady, isIncognito } = this.state;

      return (
        <withPwaInstallContext.Provider
          value={{
            openModal,
            os,
            browserName,
            isDeferredPromptReady,
            isPwaGuideReady,
            isIncognito,
            isPwa,
            onInstallClick: this.onInstallClick,
          }}
        >
          <WrappedComponent {...this.props} />
        </withPwaInstallContext.Provider>
      );
    }
  }

  return withUserAgent(withModalActions(withPwaInstallUI));
};
