import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Form from 'reactstrap/lib/Form';
import { FormSupport as FormSupportCore, FormattedTag } from 'core/components';
import {
  withCoreComponent,
  withModalActions,
  withRecaptcha
} from 'core/hocs';
import { LOCAL } from 'core/constants';
import { getEnv } from 'core/helpers';
import { ButtonWithLoader } from 'components/button-with-loader/button-with-loader';
import { FormElement } from 'components/form-element/form-element';

import { MODAL_IDS } from '../../../constants';

export class FormSupportUI extends Component {
  static propTypes = {
    onSubmit: PropTypes.func.isRequired,
    closeModal: PropTypes.func.isRequired,
    fields: PropTypes.shape().isRequired,
    isInProgress: PropTypes.bool.isRequired,
    isSuccess: PropTypes.bool,
    executeRecaptcha: PropTypes.func.isRequired,
  };

  static defaultProps = {
    isSuccess: false,
  }

  componentDidUpdate = ({ isSuccess: isPrevSuccess }) => {
    const { closeModal, isSuccess } = this.props;

    if (isSuccess && !isPrevSuccess) {
      closeModal(MODAL_IDS.SUPPORT);
    }
  }

  onSubmit = (e) => {
    e.preventDefault();
    const { onSubmit, executeRecaptcha } = this.props;

    const isLocal = getEnv('PROJECT_ENV') === LOCAL;

    onSubmit(e, executeRecaptcha, isLocal);
  };

  render() {
    const { fields, isInProgress } = this.props;

    return (
      <Form onSubmit={this.onSubmit} className="d-flex flex-column text-center">
        <FormattedTag tag="h2" id="form.support.title" className="mb-1 text-blue-dark font-family-oswald text-uppercase" />
        <FormattedTag id="form.support.description" className="font-weight-normal text-blue-dark mb-4" />

        <FormElement coreProps={{ item: fields.email }} size="lg" />
        <FormElement coreProps={{ item: fields.message }} className="rounded-lg" />

        <FormattedTag
          tag={ButtonWithLoader}
          isLoading={isInProgress}
          color="primary"
          className="mt-1 w-100"
          type="submit"
          id="send"
        />
      </Form>
    );
  }
}

export const FormSupport = withCoreComponent(FormSupportCore, withRecaptcha(
  withModalActions(FormSupportUI)
));
