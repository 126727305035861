import React from 'react';
import { connect } from 'react-redux';
import * as R from 'ramda';
import { isEmptyOrNil } from 'core/helpers';
import { FORM_ACTIONS } from 'core/constants';

export const withFormsData = (WrappedComponent, fields) => {
  const WithFormsDataUi = props => <WrappedComponent {...props} />;

  const fieldsToMap = isEmptyOrNil(fields) ? R.values(FORM_ACTIONS) : fields;

  const mapStateToProps = ({ forms }) => R.pick(fieldsToMap, forms);

  return connect(mapStateToProps)(WithFormsDataUi);
};
