import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { withCoreComponent } from 'core/hocs';
import { TournamentItem as TournamentItemCore, FormattedTag, RouteLink } from 'core/components';

const TournamentMenuItemUI = ({
  item: { slugUrl, id },
  tournamentParams,
  isTournamentPage,
  onClick,
  itemIcon,
  itemIconClassName,
  className,
}) => {
  const isActive = isTournamentPage && String(id) === tournamentParams.id;
  const Icon = itemIcon || null;

  return (
    <RouteLink
      to={`/tournament/${slugUrl}`}
      onClick={onClick}
      className={classNames(className, { active: isActive })}
    >
      {Icon && <Icon className={itemIconClassName} />}

      <FormattedTag
        id={`tournament.title.${id}`}
        className={classNames('text-capitalize', { active: isActive })}
        isHtml
      />
    </RouteLink>
  );
};

TournamentMenuItemUI.propTypes = {
  item: PropTypes.shape(),
  itemIcon: PropTypes.func,
  tournamentParams: PropTypes.shape(),
  isTournamentPage: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  itemIconClassName: PropTypes.string,
  className: PropTypes.string,
};

TournamentMenuItemUI.defaultProps = {
  item: null,
  tournamentParams: null,
  isTournamentPage: false,
  itemIcon: null,
  itemIconClassName: '',
  className: '',
};

export const TournamentMenuItem = withCoreComponent(
  TournamentItemCore,
  TournamentMenuItemUI,
);
