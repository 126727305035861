/* eslint-disable react/jsx-curly-newline */
/* eslint-disable max-len */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import * as R from 'ramda';
import Dropdown from 'reactstrap/lib/Dropdown';
import DropdownMenu from 'reactstrap/lib/DropdownMenu';
import DropdownItem from 'reactstrap/lib/DropdownItem';
import DropdownToggle from 'reactstrap/lib/DropdownToggle';
// import Collapse from 'reactstrap/lib/Collapse';
import { FormattedTag, RouteLink, TournamentsBannersList as TournamentsBannersListCore } from 'core/components';
import { withCoreComponent } from 'core/hocs';
import { isEmptyOrNil } from 'core/helpers';
import { LangSwitcher } from 'components/lang-switcher/lang-switcher';
import { Logo } from 'components/logo/logo';
// import { IconCheckIn } from 'components/icons/icon-check-in/icon-check-in';
import { IconSports } from 'components/icons/icons-header/icon-sports';
// import { IconCasino } from 'components/icons/icons-header/icon-casino';
import { IconInPlay } from 'components/icons/icons-header/icon-in-play';
import { IconCrashGames } from 'components/icons/icon-crash-games/icon-crash-games';
import { IconSlotMachine } from 'components/icons/icon-slot-machine/icon-slot-machine';
import { IconLiveCasino } from 'components/icons/icons-header/icon-live-casino';
import { IconPromo } from 'components/icons/icons-header/icon-promo';
import { IconSlots } from 'components/icons/icons-header/icon-slots';
import { IconVip } from 'components/icons/icons-vip/icon-vip';
// import { IconJackpots } from 'components/icons/icons-header/icon-jackpots';
// import { IconMore } from 'components/icons/icons-header/icon-more';
import { IconTournaments } from 'components/icons/icons-header/icon-tournaments';
import { IconArrowDown } from 'components/icons/icon-arrow-down/icon-arrow-down';
import { TournamentMenuItem } from 'components/tournament-menu-item/tournament-menu-item';
import colors from '../../customizations/js/color-variables';

import { SignInButtons } from './sign-in-buttons/sign-in-buttons';
import { PAGE_NAMES, MODAL_IDS, AUTH_TYPES } from '../../constants';

import './header.scss';

const {
  GAMES,
  PROMOS,
  IN_PLAY,
  VIP,
  TOURNAMENT,
  TOURNAMENTS,
  SPORTSBOOK,
  CRASH_GAMES,
} = PAGE_NAMES;

const TOURNAMENTS_PAGES = [TOURNAMENTS, TOURNAMENT];
const TOURNAMENTS_COLLAPSE_BUTTON_CLASS = 'tournaments-collapse';

const menuItems = [
  {
    id: 'header.sports',
    to: '/sportsbook',
    Icon: IconSports,
    pages: [SPORTSBOOK, IN_PLAY],
    classNameHeader: 'ml-auto'
  },
  {
    id: 'header.in-play',
    to: '/in-play?page=/live',
    Icon: IconInPlay,
    pages: [`/${SPORTSBOOK}?page=/live`, `/${IN_PLAY}?page=/live`],
  },
  {
    id: 'header.crash-games',
    to: `/category/${CRASH_GAMES}`,
    Icon: IconCrashGames,
    pages: [CRASH_GAMES],
  },
  {
    id: 'header.games',
    to: '/games',
    Icon: IconSlotMachine,
    pages: [GAMES, `${GAMES}/all`, `${GAMES}/slot`, `${GAMES}/roulette`, `${GAMES}/card`, `${GAMES}/sport`],
  },
  {
    id: 'header.live',
    to: '/games/live',
    Icon: IconLiveCasino,
    pages: [`${GAMES}/live`],
  },
  {
    id: 'header.slots',
    to: '/games/slot',
    Icon: IconSlots,
    pages: [`${GAMES}/slot`],
  },
  {
    id: 'header.promos',
    to: '/promos',
    Icon: IconPromo,
    pages: [PROMOS],
  },
  {
    id: 'header.vip',
    to: '/vip',
    Icon: IconVip,
    pages: [VIP],
  },
  {
    id: 'header.tournaments',
    Icon: IconTournaments,
    pages: TOURNAMENTS_PAGES,
    withHide: true,
  },
];

const menuItemsHidden = R.filter(R.propEq('withHide', true))(menuItems);
const menuItemsMain = R.difference(menuItems, menuItemsHidden);

const isActivePage = (pageName, pages, categoryRouteParam) => (
  pageName !== PAGE_NAMES.GAMES
    ? pages.includes(pageName)
    : pages.includes(`${pageName}/${categoryRouteParam}`)
);

export class HeaderContentUI extends Component {
  static propTypes = {
    pageName: PropTypes.string,
    openModal: PropTypes.func.isRequired,
    isUserRegistered: PropTypes.bool,
    isUserLoggedIn: PropTypes.bool,
    // isTransparentHeader: PropTypes.bool,
    categoryRouteParam: PropTypes.string,
    items: PropTypes.shape(),
    tournamentParams: PropTypes.shape(),
    isSportsPage: PropTypes.bool,
  };

  static defaultProps = {
    pageName: null,
    isUserRegistered: false,
    isUserLoggedIn: false,
    // isTransparentHeader: false,
    categoryRouteParam: '',
    items: {},
    tournamentParams: null,
    isSportsPage: false,
  };

  state = {
    isMoreDropdownOpen: false,
    isTournamentsCollapseOpen: false,
    isNavTournamentDropdownOpen: false
  };

  componentDidUpdate(prevProps, { isMoreDropdownOpen: prevIsMoreDropdownOpen }) {
    const { pageName } = this.props;
    const { isMoreDropdownOpen, isTournamentsCollapseOpen } = this.state;

    if (TOURNAMENTS_PAGES.includes(pageName) && !prevIsMoreDropdownOpen && isMoreDropdownOpen && !isTournamentsCollapseOpen) {
      this.setState({ isTournamentsCollapseOpen: true }); // eslint-disable-line react/no-did-update-set-state
    }

    if (prevIsMoreDropdownOpen && !isMoreDropdownOpen) {
      this.setState({ isTournamentsCollapseOpen: false }); // eslint-disable-line react/no-did-update-set-state
    }
  }

  toggleMoreDropdown = ({ currentTarget: { classList } }) => {
    // do not toggle MORE dropdown if the handler is executed in the bubbling phase from the collapsing tournaments button
    if (!classList || !classList.contains(TOURNAMENTS_COLLAPSE_BUTTON_CLASS)) {
      this.setState(({ isMoreDropdownOpen }) => ({
        isMoreDropdownOpen: !isMoreDropdownOpen
      }));
    }
  };

  toggleNavTournamentDropdown = () => {
    this.setState(({ isNavTournamentDropdownOpen }) => ({
      isNavTournamentDropdownOpen: !isNavTournamentDropdownOpen
    }));
  };

  toggleTournamentCollapse = () => {
    this.setState(({ isTournamentsCollapseOpen }) => ({
      isTournamentsCollapseOpen: !isTournamentsCollapseOpen,
    }));
  };

  openCheckInModal = () => {
    const {
      isUserRegistered,
      isUserLoggedIn,
      openModal,
    } = this.props;

    let modalData;

    if (isUserLoggedIn) {
      modalData = MODAL_IDS.CHECK_IN;
    } else if (isUserRegistered) {
      modalData = { id: MODAL_IDS.AUTH, data: AUTH_TYPES.SIGN_IN };
    } else {
      modalData = { id: MODAL_IDS.AUTH, data: AUTH_TYPES.SIGN_UP };
    }

    openModal(modalData);
  };

  render() {
    const {
      pageName,
      isUserLoggedIn,
      items: tournamentItems,
      tournamentParams,
      categoryRouteParam,
      isSportsPage,
    } = this.props;

    const {
      // isMoreDropdownOpen,
      // isTournamentsCollapseOpen,
      isNavTournamentDropdownOpen
    } = this.state;
    const isResetPasswordPage = pageName === PAGE_NAMES.RESET_PASSWORD;

    const isTournamentsItemActive = TOURNAMENTS_PAGES.includes(pageName);

    // const isMoreBtnActive = R.compose(
    //   pages => (
    //     isActivePage(pageName, pages, categoryRouteParam)
    //   ),
    //   R.unnest,
    //   R.pluck('pages'),
    // )(menuItemsHidden);

    // const isHiddenItemTournamentsOnly = menuItemsHidden.length === 1 && menuItemsHidden[0].id === 'sidebar.tournaments';
    // const isMoreBtnVisible = !isEmptyOrNil(menuItemsHidden) && (!isHiddenItemTournamentsOnly || !isEmptyOrNil(tournamentItems));

    return (
      <>
        {isResetPasswordPage && <Logo className="logo ml-5" />}

        <>
          {/* <div
            role="button"
            tabIndex="0"
            onClick={this.openCheckInModal}
            onKeyPress={this.openCheckInModal}
            className="btn-check-in d-flex flex-shrink-0 align-items-center pl-1_5 pr-2_5"
          >
            <IconCheckIn className="flex-shrink-0 mr-0_5" />

            <FormattedTag
              id="header.check-in"
              className="ml-0_25 "
            />
            </div> */}
          <div />

          <div className={classNames('d-flex header-items mx-auto', {
            'not-logged-in': !isUserLoggedIn,
          })}
          >
            <div className="d-flex align-items-center">
              {menuItemsMain.map(({
                id, to, Icon, pages
              } = {}) => {
                const isActive = isActivePage(pageName, pages, categoryRouteParam);

                return (
                  <RouteLink
                    key={id}
                    to={to}
                    tag="div"
                    className={classNames('casino-header-link d-flex flex-column align-items-center justify-content-center text-decoration-none text-base font-weight-semi-bold', {
                      'ml-3': id !== 0,
                      active: isActive,
                    })}
                  >
                    {Icon && <Icon />}

                    <FormattedTag
                      id={id}
                      className={classNames('text-white-80 header-link-text', { active: isActive })}
                      isHtml
                    />
                  </RouteLink>
                );
              })}

              {/* MORE DROPDOWN BUTTON (1280 - 1680) */}
              {/* {isMoreBtnVisible && (
                <Dropdown
                  key="casino-header-dropdown-more"
                  isOpen={isMoreDropdownOpen}
                  toggle={this.toggleMoreDropdown}
                  className="casino-header-dropdown-more"
                >
                  <DropdownToggle
                    key="casino-header-dropdown-more-toggle"
                    tag="div"
                    className={classNames('casino-header-toggle d-flex flex-column align-items-center bg-transparent cursor-pointer p-0 ml-3 text-decoration-none', {
                      active: isMoreBtnActive,
                    })}
                  >
                    <IconMore />

                    <FormattedTag
                      id="header.more"
                      className={classNames('font-weight-bold', { active: isMoreBtnActive })}
                      isHtml
                    />
                  </DropdownToggle>

                  <DropdownMenu className={classNames('casino-header-dropdown-more-menu ', {
                    'pb-0_5': !isTournamentsCollapseOpen,
                    'pb-1_25': isTournamentsCollapseOpen,
                  })}
                  >
                    {menuItemsHidden.map(({
                      id, to, Icon, pages
                    } = {}) => {
                      const isTournamentsItem = id === 'sidebar.tournaments';
                      if (isTournamentsItem && isEmptyOrNil(tournamentItems)) return null;

                      const isActive = isActivePage(pageName, pages, categoryRouteParam);

                      return (
                        <>
                          <DropdownItem
                            key={id}
                            className={classNames('casino-header-dropdown-more-menu-item pl-1_5 py-0_25 position-relative', {
                              active: isTournamentsItem && isTournamentsItemActive || !isTournamentsItem && isActive,
                              [TOURNAMENTS_COLLAPSE_BUTTON_CLASS]: isTournamentsItem,
                            })}

                            {...(isTournamentsItem ? {
                              onClick: this.toggleTournamentCollapse,
                              onKeyPress: this.toggleTournamentCollapse,
                            } : {
                              tag: RouteLink,
                              to,
                            })}
                          >
                            {isTournamentsItem ? (
                              <>
                                <IconTournaments className="dropdown-item-icon flex-shrink-0" />
                                <FormattedTag id="tournaments.title" className="text-blue-dark ml-0_5" isHtml />
                                <IconArrowDown
                                  className={classNames('icon-arrow-down ml-0_75', { 'rotate-180': isTournamentsCollapseOpen })}
                                />
                              </>
                            ) : (
                              <>
                                <Icon className="dropdown-item-icon flex-shrink-0" />

                                <FormattedTag id={id} className="text-blue-dark ml-0_5" isHtml />
                              </>
                            )}
                          </DropdownItem>

                          {isTournamentsItem && (
                            <Collapse isOpen={isTournamentsCollapseOpen} className="bg-yellow position-relative">
                              <div className="d-flex flex-column h6 font-weight-bold ml-6 py-0_75 mr-2">
                                {R.values(tournamentItems).map(tournamentId => (
                                  <TournamentMenuItem
                                    key={`${tournamentId}`}
                                    className="collapse-menu-item mt-1_25 position-relative"
                                    tournamentParams={tournamentParams}
                                    isTournamentPage={TOURNAMENTS_PAGES.includes(pageName)}
                                    onClick={this.toggleMoreDropdown}
                                    coreProps={{ id: tournamentId }}
                                  />
                                ))}
                              </div>
                            </Collapse>
                          )}
                        </>
                      );
                    })}
                  </DropdownMenu>
                </Dropdown>
              )} */}
            </div>

            {/* items from MORE BUTTON (>=1680) */}
            <div className="casino-header-hidden-items align-items-center">
              {menuItemsHidden.map(({
                id, to, Icon, pages
              } = {}) => {
                const isTournamentsItem = id === 'header.tournaments';
                if (isTournamentsItem && isEmptyOrNil(tournamentItems)) return null;

                const isActive = isActivePage(pageName, pages, categoryRouteParam);

                if (isTournamentsItem) {
                  return (
                    <Dropdown
                      key={id}
                      isOpen={isNavTournamentDropdownOpen}
                      toggle={this.toggleNavTournamentDropdown}
                      className="casino-header-dropdown-tournament"
                    >
                      <DropdownToggle
                        tag="div"
                        className={classNames('casino-header-toggle d-flex flex-column align-items-center ml-3 cursor-pointer text-decoration-none', {
                          active: isTournamentsItemActive,
                        })}
                      >
                        {Icon && <Icon className="mt-0_25" />}

                        <div className="d-flex align-items-center">
                          <FormattedTag
                            id={id}
                            className={classNames('font-weight-semi-bold text-white-80', {
                              active: isTournamentsItemActive,
                            })}
                            isHtml
                          />

                          <IconArrowDown
                            color={colors.primary}
                            className={classNames('icon-arrow-down ml-0_75', {
                              'rotate-180': isNavTournamentDropdownOpen,
                            })}
                          />
                        </div>
                      </DropdownToggle>

                      <DropdownMenu className={classNames('dropdown-tournament-menu mt-1 bg-primary')}>
                        {R.values(tournamentItems).map(tournamentId => (
                          <DropdownItem key={`${tournamentId}`} className="px-2_25 py-0_75">
                            <TournamentMenuItem
                              className="tournament-item text-decoration-none h6 font-weight-bold text-white"
                              tournamentParams={tournamentParams}
                              isTournamentPage={TOURNAMENTS_PAGES.includes(pageName)}
                              onClick={this.toggleMoreDropdown}
                              coreProps={{ id: tournamentId }}
                            />
                          </DropdownItem>
                        ))}
                      </DropdownMenu>
                    </Dropdown>
                  );
                }

                return (
                  <RouteLink
                    key={id}
                    to={to}
                    tag="div"
                    className={classNames('casino-header-link d-flex flex-column align-items-center ml-3 text-decoration-none  font-weight-semi-bold', {
                      active: isActive,
                    })}
                  >
                    {Icon && <Icon />}

                    <FormattedTag id={id} className={classNames('header-link-text', { active: isActive })} isHtml />
                  </RouteLink>
                );
              })}
            </div>
          </div>

          <div className="d-flex align-items-center mr-5">
            {!isUserLoggedIn && <SignInButtons />}

            {!isSportsPage && (
              <LangSwitcher
                className={classNames('d-none d-lg-block', {
                  'd-md-block': isResetPasswordPage,
                })}
              />
            )}
          </div>
        </>
      </>
    );
  }
}
/* eslint-enable max-len */

export const HeaderContent = withCoreComponent(
  TournamentsBannersListCore, HeaderContentUI
);
