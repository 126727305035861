import React from 'react';
import PropTypes from 'prop-types';

export const IconStarGift = ({ className }) => (
  <svg className={className} width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M15.6528 6.79775C15.9677 6.49741 16.0789 6.05662 15.943 5.64687C15.8069 5.23711 15.4522 4.94461 15.0165 4.88254L11.1425 4.33171C10.9775 4.3082 10.8349 4.20693 10.7612 4.06053L9.02922 0.625758C8.83475 0.239832 8.44004 0 8.00016 0C7.5606 0 7.16589 0.239832 6.97142 0.625758L5.23912 4.06084C5.16543 4.20725 5.02254 4.30851 4.85755 4.33202L0.983502 4.88285C0.548105 4.94461 0.193123 5.23743 0.0569613 5.64718C-0.0788801 6.05693 0.032292 6.49772 0.347226 6.79806L3.15024 9.47164C3.26974 9.58575 3.32452 9.75003 3.29633 9.91055L2.63506 13.6858C2.57644 14.0181 2.6655 14.3413 2.88528 14.5962C3.22681 14.9934 3.82304 15.1144 4.29976 14.8693L7.76436 13.0867C7.90917 13.0124 8.09147 13.013 8.23596 13.0867L11.7009 14.8693C11.8694 14.9561 12.0491 15 12.2346 15C12.5733 15 12.8943 14.8527 13.115 14.5962C13.3351 14.3413 13.4239 14.0175 13.3653 13.6858L12.7037 9.91055C12.6755 9.74972 12.7303 9.58575 12.8498 9.47164L15.6528 6.79775Z" fill="#E15341" />
  </svg>
);

IconStarGift.propTypes = {
  className: PropTypes.string
};

IconStarGift.defaultProps = {
  className: ''
};
