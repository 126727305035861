import React from 'react';
import PropTypes from 'prop-types';

export const IconInstagram = ({ className }) => (
  <svg className={className} width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M18.981 4.038H9.02a4.981 4.981 0 00-4.981 4.981v9.962a4.981 4.981 0 004.981 4.981h9.962a4.981 4.981 0 004.981-4.98V9.018a4.981 4.981 0 00-4.98-4.981zm-4.5 6.966a2.985 2.985 0 10-.876 5.906 2.985 2.985 0 00.876-5.906zm-2.734-1.472a4.985 4.985 0 114.592 8.85 4.985 4.985 0 01-4.592-8.85zm7.732-2.011a1 1 0 000 2h.012a1 1 0 000-2h-.012z" fill="#A3ACB3" />
  </svg>
);

IconInstagram.propTypes = {
  className: PropTypes.string,
};

IconInstagram.defaultProps = {
  className: '',
};
