import React from 'react';
import PropTypes from 'prop-types';

export const IconPwaClose = ({ className, onClick }) => (
  <svg className={className} onClick={onClick} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    {/* <rect width="20" height="20" fill="#E5E5E5" /> */}
    {/* <rect x="-1018" y="-14" width="1078" height="48" fill="#0A84FF" /> */}
    <circle r="10" transform="matrix(-1 0 0 1 10 10)" fill="#C7D0E27A" />
    <path d="M13.001 7L7.00098 13" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M7.00098 7L13.001 13" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

IconPwaClose.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func
};

IconPwaClose.defaultProps = {
  className: '',
  onClick: null
};
