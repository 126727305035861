// DO NOT change config object's keys because they are used for payment methods in CORE!!!
module.exports = {
  accountId: {
    type: 'text',
    placeholder: 'form.fields.account-id',
    validation: [
      { type: 'required' },
    ],
  },
  secureId: {
    type: 'text',
    placeholder: 'form.fields.secure-id',
    validation: [
      { type: 'required' },
    ],
  },
  payFromEmail: {
    type: 'text',
    placeholder: 'form.fields.pay-from-email',
    validation: [
      { type: 'required' },
      { type: 'email' },
    ],
  },
  amount: {
    type: 'text',
    placeholder: 'form.fields.amount',
    validation: [
      { type: 'required' },
      { type: 'amount' },
    ],
  },
  requestedBonusPlanId: {
    type: 'radio',
    placeholder: 'form.fields.bonus-plan',
    validation: [
      { type: 'required' },
    ],
  },
  // mobileFrom: {
  //   type: 'tel',
  //   placeholder: 'form.fields.mobile-from',
  //   validation: [
  //     { type: 'required' },
  //   ],
  // },
  // mobileTo: {
  //   type: 'tel',
  //   placeholder: 'form.fields.mobile-to',
  //   validation: [
  //     { type: 'required' },
  //   ],
  // },
  bankReference: {
    type: 'text',
    placeholder: 'form.fields.bank-reference',
    validation: [
      { type: 'required' },
    ],
  },
  turkishId: {
    type: 'text',
    placeholder: 'form.fields.turkish-id',
    validation: [
      { type: 'required' },
    ],
  },
  birthDate: {
    type: 'date',
    placeholder: 'form.fields.birth-date',
    validation: [
      { type: 'required' },
      { type: 'birth-date' },
    ],
  },
  bankName: {
    type: 'text',
    placeholder: 'form.fields.bank-name',
    validation: [
      { type: 'required' },
    ],
  },
  MB_BankName: {
    type: 'select',
    placeholder: 'form.fields.mb-bank-name',
    validation: [
      { type: 'required' },
    ],
    isReadOnly: true,
  },
  tr_bankName: {
    type: 'select',
    placeholder: 'form.fields.mb-bank-name',
    validation: [
      { type: 'required' },
    ],
    isReadOnly: true,
  },
  tr_customerName: {
    type: 'text',
    placeholder: 'form.fields.customer-name',
    validation: [
      { type: 'required' },
    ],
  },
};
