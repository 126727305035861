import * as R from 'ramda';
import { LOYALTY_POINTS_STATUS_INDEXES, LOYALTY_POINTS_STATUSES, LOYALTY_POINTS_KEYS } from 'core/constants';

/**
 * @param {number} accumulatedPoints
 * @param {number} totalPoints
 * @returns {number}
 */

export const getAccumulatedPointsPercentage = (
  accumulatedPoints = 0,
  totalPoints = 0,
) => {
  if (Number(accumulatedPoints) === 0 || Number(totalPoints) === 0) {
    return 0;
  }

  return accumulatedPoints / totalPoints * 100;
};

export const getActiveLevelOrder = R.compose(
  R.prop(R.__, LOYALTY_POINTS_STATUS_INDEXES),
  R.toLower,
  R.propOr(LOYALTY_POINTS_STATUSES.BRONZE, LOYALTY_POINTS_KEYS.CURRENT_VIP_LEVEL),
);

