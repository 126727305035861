import React from 'react';
import PropTypes from 'prop-types';

export const IconPwa = ({ className }) => (
  <svg className={className} width="18" height="26" viewBox="0 0 18 26" fill="none" xmlns="http://www.w3.org/2000/svg">
    {/* <rect width="18" height="26" fill="#0A84FF" /> */}
    <rect x="1.2" y="1.2" width="15.6" height="23.1789" rx="1.64211" stroke="white" strokeWidth="2.4" />
    <circle cx="8.99918" cy="18.2209" r="1.42105" fill="white" />
  </svg>
);

IconPwa.propTypes = {
  className: PropTypes.string
};

IconPwa.defaultProps = {
  className: ''
};
