import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Countdown from 'react-countdown-now';

export class CustomCountdown extends Component {
  static propTypes = {
    children: PropTypes.oneOfType([
      PropTypes.elementType,
      PropTypes.func,
    ]).isRequired,
    date: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
    innerRef: PropTypes.oneOfType([
      PropTypes.func,
      PropTypes.shape({ current: PropTypes.any })
    ])
  };

  static defaultProps = {
    innerRef: () => {},
    date: null,
  }

  countdownRenderer = (parsedDate) => {
    const { children } = this.props;

    return children(parsedDate);
  };

  render() {
    const { date, innerRef, ...props } = this.props;

    return <Countdown ref={innerRef} {...props} date={date} renderer={this.countdownRenderer} />;
  }
}
