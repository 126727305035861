import React from 'react';
import PropTypes from 'prop-types';

export const IconCopy = ({ className }) => (
  <svg className={className} width="15" height="18" viewBox="0 0 15 18" fill="none">
    <path d="M2.16667 17.3333C1.70833 17.3333 1.31583 17.17 0.989168 16.8433C0.662501 16.5167 0.499446 16.1245 0.500001 15.6667V4.00001H2.16667V15.6667H11.3333V17.3333H2.16667ZM5.5 14C5.04167 14 4.64917 13.8367 4.3225 13.51C3.99583 13.1833 3.83278 12.7911 3.83333 12.3333V2.33334C3.83333 1.87501 3.99667 1.48251 4.32333 1.15584C4.65 0.829173 5.04222 0.666118 5.5 0.666673H13C13.4583 0.666673 13.8508 0.830007 14.1775 1.15667C14.5042 1.48334 14.6672 1.87556 14.6667 2.33334V12.3333C14.6667 12.7917 14.5033 13.1842 14.1767 13.5108C13.85 13.8375 13.4578 14.0006 13 14H5.5ZM5.5 12.3333H13V2.33334H5.5V12.3333Z" fill="#B4313F" />
  </svg>

);

IconCopy.propTypes = {
  className: PropTypes.string,
};

IconCopy.defaultProps = {
  className: null,
};
