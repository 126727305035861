import React from 'react';
import PropTypes from 'prop-types';

export const IconInputError = ({ className }) => (
  <svg className={className} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle r="10" transform="matrix(-1 0 0 1 10 10)" fill="#D4373E" />
    <g clipPath="url(#icon-input-error-0)">
      <path d="M10.0002 4.11523C10.6502 4.11523 11.1772 4.64218 11.1772 5.2922V10C11.1772 10.6501 10.6502 11.177 10.0002 11.177C9.35019 11.177 8.82324 10.6501 8.82324 10V5.2922C8.82324 4.64218 9.35019 4.11523 10.0002 4.11523Z" fill="white" />
      <path d="M10.0002 13.5308C9.35019 13.5308 8.82324 14.0577 8.82324 14.7077C8.82324 15.3577 9.35019 15.8847 10.0002 15.8847H10.0115C10.6615 15.8847 11.1885 15.3577 11.1885 14.7077C11.1885 14.0577 10.6615 13.5308 10.0115 13.5308H10.0002Z" fill="white" />
    </g>
  </svg>
);

IconInputError.propTypes = {
  className: PropTypes.string
};

IconInputError.defaultProps = {
  className: ''
};
