import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FormattedTag } from 'core/components';

import './greeting.scss';

export const Greeting = ({ className }) => (
  <div className={classNames('greeting mt-n0_75 mt-md-0', className)}>
    <div className="image m-auto my-auto" />

    <FormattedTag
      id="sidebar.not-logged.title" // TODO: change text to 'welcome bonus'
      className="h2 font-weight-bold d-block mb-1_25 mt-1 text-uppercase text-white title-welcome"
    />

    <FormattedTag
      id="sidebar.not-logged.sub-title"
      className="d-block title-secondary px-2 px-md-0 text-white"
    />
  </div>
);

Greeting.propTypes = {
  className: PropTypes.string,
};

Greeting.defaultProps = {
  className: '',
};
