import * as R from 'ramda';
import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { setDocumentScrollTopPosition } from 'core/helpers';

import { PAGE_NAMES } from '../../constants';

export class LocationTrackerUI extends PureComponent {
  static propTypes = {
    location: PropTypes.shape({
      pathname: PropTypes.string,
      state: PropTypes.shape(),
    }).isRequired,
    history: PropTypes.shape({
      action: PropTypes.string,
    }).isRequired,
    pageName: PropTypes.string.isRequired,
  };

  componentDidUpdate(prevProps) {
    const { location: { pathname, state }, history: { action }, pageName } = this.props;
    const { location: { pathname: prevPathname }, pageName: prevPageName } = prevProps;

    if (pathname !== prevPathname && action !== 'POP'
    && (pageName !== PAGE_NAMES.GAMES || (pageName === PAGE_NAMES.GAMES && prevPageName !== PAGE_NAMES.GAMES))) {
      setDocumentScrollTopPosition(R.propOr(0, 'scrollTop', state), document.querySelector('.main-container'));
    }
  }

  render() {
    return null;
  }
}

export const LocationTracker = withRouter(LocationTrackerUI);
