import React from 'react';
import PropTypes from 'prop-types';

export const IconStatusApproved = ({ className }) => (
  <svg className={className} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle r="10" transform="matrix(-1 0 0 1 10 10)" fill="#254096" />
    <g clipPath="url(#icon-status-approved)">
      <path fillRule="evenodd" clipRule="evenodd" d="M15.7071 7.96297C16.0976 7.57244 16.0976 6.93928 15.7071 6.54875C15.3166 6.15823 14.6834 6.15823 14.2929 6.54875L8.99941 11.8422L6.20615 9.04878C5.81564 8.65824 5.18248 8.65822 4.79194 9.04873C4.4014 9.43924 4.40138 10.0724 4.79189 10.4629L8.29189 13.9632C8.54906 14.2204 8.91146 14.3082 9.2405 14.2267C9.41148 14.1845 9.57349 14.0966 9.70711 13.963L15.7071 7.96297Z" fill="white" />
    </g>
  </svg>
);

IconStatusApproved.propTypes = {
  className: PropTypes.string,
};
IconStatusApproved.defaultProps = {
  className: null,
};
