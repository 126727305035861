import React from 'react';
import PropTypes from 'prop-types';

export const IconLangTr = ({ className }) => (
  <svg className={className} xmlns="http://www.w3.org/2000/svg" width="30" height="24" viewBox="0 0 30 24">
    <defs>
      <filter id="tr-lang-icon-c" width="116.7%" height="120.8%" x="-8.3%" y="-10.4%" filterUnits="objectBoundingBox">
        <feGaussianBlur in="SourceAlpha" result="shadowBlurInner1" stdDeviation="1.5" />
        <feOffset dy="2" in="shadowBlurInner1" result="shadowOffsetInner1" />
        <feComposite in="shadowOffsetInner1" in2="SourceAlpha" k2="-1" k3="1" operator="arithmetic" result="shadowInnerInner1" />
        <feColorMatrix in="shadowInnerInner1" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.3 0" />
      </filter>
      <rect id="tr-lang-icon-d" width="30" height="24" x="0" y="0" rx="4" />
      <path id="tr-lang-icon-a" d="M5.128 0h19.744c1.783 0 2.43.186 3.082.534.652.349 1.163.86 1.512 1.512.348.652.534 1.299.534 3.082v13.744c0 1.783-.186 2.43-.534 3.082-.349.652-.86 1.163-1.512 1.512-.652.348-1.299.534-3.082.534H5.128c-1.783 0-2.43-.186-3.082-.534-.652-.349-1.163-.86-1.512-1.512C.186 21.302 0 20.655 0 18.872V5.128c0-1.783.186-2.43.534-3.082.349-.652.86-1.163 1.512-1.512C2.698.186 3.345 0 5.128 0z" />
    </defs>
    <g fill="none" fillRule="evenodd">
      <mask id="tr-lang-icon-b" fill="#fff">
        <use xlinkHref="#tr-lang-icon-a" />
      </mask>
      <g fillRule="nonzero" mask="url(#tr-lang-icon-b)">
        <path fill="#D80027" d="M0 0H30V24H0z" />
        <g fill="#F0F0F0">
          <path d="M10.261 2.511L11.495 4.213 13.495 3.564 12.258 5.264 13.493 6.966 11.494 6.315 10.257 8.015 10.259 5.913 8.26 5.262 10.259 4.614z" transform="translate(5.088 6.737)" />
          <path d="M6.891 9.516c-2.348 0-4.252-1.904-4.252-4.253S4.543 1.01 6.89 1.01c.733 0 1.422.186 2.023.512C7.971.599 6.68.029 5.256.029 2.365.029.022 2.372.022 5.263c0 2.89 2.343 5.234 5.234 5.234 1.424 0 2.715-.57 3.658-1.492-.601.326-1.29.51-2.023.51z" transform="translate(5.088 6.737)" />
        </g>
      </g>
      <g fill="#000" mask="url(#tr-lang-icon-b)">
        <use filter="url(#tr-lang-icon-c)" xlinkHref="#tr-lang-icon-d" />
      </g>
    </g>
  </svg>
);

IconLangTr.propTypes = {
  className: PropTypes.string,
};

IconLangTr.defaultProps = {
  className: null,
};
