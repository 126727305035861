import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Button from 'reactstrap/lib/Button';
import Spinner from 'reactstrap/lib/Spinner';
import { InputText } from '../../input-text/input-text';
// import { IconArrowRight } from '../../icons/icon-arrow-right/icon-arrow-right';

import './input-with-action.scss';

export const InputWithAction = ({
  name, value, placeholder, isInProgress, onAction,
  onChange, type, length, isReadOnly, isDisabled, error,
}) => (
  <div className="input-with-action position-relative">
    <Button
      color="primary"
      className="input-with-action-btn position-absolute px-0"
      type="submit"
      onClick={onAction}
    >
      {isInProgress ? <Spinner size="sm" color="white" /> : <FormattedMessage id="send" />}
    </Button>
    <FormattedMessage id={placeholder}>
      {txt => (
        <InputText
          type={type}
          id={`input-${name}`}
          name={name}
          value={value}
          onChange={onChange}
          placeholder={txt}
          max={length.maximum}
          readOnly={isReadOnly}
          disabled={isDisabled}
          autoComplete="off"
          isError={!!error}
        />
      )}
    </FormattedMessage>
  </div>
);

InputWithAction.propTypes = {
  type: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.string,
  isInProgress: PropTypes.bool,
  onAction: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  isReadOnly: PropTypes.bool,
  isDisabled: PropTypes.bool,
  length: PropTypes.shape(),
  error: PropTypes.shape(),
};

InputWithAction.defaultProps = {
  value: '',
  placeholder: null,
  onChange: null,
  error: null,
  isReadOnly: false,
  isDisabled: false,
  isInProgress: false,
  length: {},
};
