import React from 'react';
import PropTypes from 'prop-types';

export const IconPromos = ({ className }) => (
  <svg className={className} width="20" height="23" viewBox="0 0 20 23" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8.12969 22.1172H11.8703V5.87915H8.12969V22.1172ZM14.6611 4.63005C15.2598 3.73609 15.6109 2.66242 15.6109 1.50733C15.6109 1.16209 15.3321 0.882812 14.9875 0.882812C12.8188 0.882812 10.9339 2.12151 10 3.92987C9.06604 2.12151 7.1811 0.882812 5.01252 0.882812C4.66792 0.882812 4.38906 1.16216 4.38906 1.50733C4.38906 2.66242 4.7402 3.73609 5.33891 4.63005H14.6611ZM13.1172 22.1172H17.4812C17.8259 22.1172 18.1047 21.8378 18.1047 21.4927V13.3736H13.1172V22.1172H13.1172ZM1.89529 21.4927C1.89529 21.8379 2.17415 22.1172 2.51875 22.1172H6.88284V13.3736H1.89536V21.4927H1.89529ZM0.648438 6.50366V11.5C0.648438 11.8452 0.927297 12.1245 1.2719 12.1245H6.88284V5.87915H1.2719C0.927297 5.87915 0.648438 6.15843 0.648438 6.50366ZM18.7281 5.87915H13.1172V12.1245H18.7281C19.0727 12.1245 19.3516 11.8452 19.3516 11.5V6.50366C19.3516 6.15843 19.0727 5.87915 18.7281 5.87915Z"
      fill="url(#promos)"
    />
  </svg>
);

IconPromos.propTypes = {
  className: PropTypes.string,
};

IconPromos.defaultProps = {
  className: null,
};
