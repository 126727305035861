module.exports = {
  oldPassword: {
    type: 'password',
    placeholder: 'form.fields.old-password',
    validation: [
      { type: 'required' },
      { type: 'min-length', value: 6 },
      { type: 'max-length', value: 16 },
      { type: 'password' },
    ],
  },
  newPassword: {
    type: 'password',
    placeholder: 'form.fields.new-password',
    validation: [
      { type: 'required' },
      { type: 'min-length', value: 6 },
      { type: 'max-length', value: 16 },
      { type: 'password' },
    ],
  },
  passwordConfirmation: {
    type: 'password',
    placeholder: 'form.fields.new-password-repeat',
    validation: [
      { type: 'required' },
      { type: 'min-length', value: 6 },
      { type: 'max-length', value: 16 },
      { type: 'password' },
      { type: 'passwords-equality' },
    ],
  },
};
