import React from 'react';
import PropTypes from 'prop-types';

export const IconFreeSpins = ({ className }) => (
  <svg className={className} width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.0019 7.78474C17.0019 4.0292 13.8809 0.984741 10.031 0.984741C8.49339 0.984741 7.02839 1.4731 5.82723 2.35999C5.28552 2.75997 5.17877 3.5126 5.58879 4.04103C5.99882 4.56947 6.77036 4.6736 7.31207 4.27362C8.08943 3.69966 9.03412 3.38474 10.031 3.38474C12.5221 3.38474 14.5416 5.35469 14.5416 7.78474C14.5416 10.2148 12.5221 12.1847 10.031 12.1847C8.15444 12.1847 6.5455 11.0668 5.86591 9.47643L6.35679 9.75183L6.46836 9.80722C7.03456 10.0535 7.71589 9.8425 8.03721 9.30156C8.37995 8.72456 8.1833 7.99065 7.59798 7.66233L4.60034 5.98088L4.48878 5.92549C3.92257 5.67922 3.24125 5.89021 2.91993 6.43115L1.16462 9.38614L1.10664 9.49603C0.847977 10.0533 1.05511 10.7176 1.60385 11.0254L1.71542 11.0808C2.28162 11.327 2.96295 11.116 3.28427 10.5751L3.51046 10.1943C4.50753 12.7605 7.05089 14.5847 10.031 14.5847C13.8809 14.5847 17.0019 11.5403 17.0019 7.78474Z"
      fill="#E15341"
    />
  </svg>
);

IconFreeSpins.propTypes = {
  className: PropTypes.string,
};

IconFreeSpins.defaultProps = {
  className: '',
};
