import React from 'react';
import PropTypes from 'prop-types';

export const IconTwitter = ({ className }) => (
  <svg className={className} width="33" height="33" viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.5115 1.33595C8.13372 1.33595 1.34215 8.12513 1.34215 16.5C1.34215 24.8749 8.13372 31.6641 16.5115 31.6641C24.8894 31.6641 31.6809 24.8749 31.6809 16.5C31.6809 8.12513 24.8894 1.33595 16.5115 1.33595ZM0.0230713 16.5C0.0230713 7.39688 7.40521 0.017334 16.5115 0.017334C25.6179 0.017334 33 7.39688 33 16.5C33 25.6031 25.6179 32.9827 16.5115 32.9827C7.40521 32.9827 0.0230713 25.6031 0.0230713 16.5Z"
      fill="#008E39"
    />
    <path
      d="M25.0258 9.54343C24.2845 10.0661 23.4638 10.4659 22.5952 10.7273C22.1291 10.1915 21.5095 9.81169 20.8204 9.63932C20.1312 9.46695 19.4058 9.5103 18.7421 9.76353C18.0784 10.0168 17.5085 10.4676 17.1096 11.0552C16.7106 11.6427 16.5017 12.3386 16.5112 13.0486V13.8224C15.1509 13.8577 13.803 13.5561 12.5876 12.9445C11.3721 12.3329 10.3268 11.4304 9.5448 10.3172C9.5448 10.3172 6.44861 17.2812 13.415 20.3763C11.8209 21.458 9.92188 22.0004 7.9967 21.9238C14.9631 25.7927 23.4777 21.9238 23.4777 13.0254C23.4769 12.8099 23.4562 12.5949 23.4157 12.3832C24.2057 11.6044 24.7632 10.6211 25.0258 9.54343Z"
      fill="#008E39"
    />
  </svg>
);

IconTwitter.propTypes = {
  className: PropTypes.string,
};

IconTwitter.defaultProps = {
  className: '',
};
