import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet-async';
import { withRouter } from 'react-router';
import { intlShape, injectIntl } from 'react-intl';

import { withLocale } from 'core/hocs';
import { getMetaByPage } from './head-data';

const HeadHelmetUI = ({
  page,
  values,
  options,
  locale,
  location,
  intl,
}) => (
  <Helmet>
    {getMetaByPage(
      page,
      locale,
      location,
      intl,
      values,
      options,
    )}
  </Helmet>
);

HeadHelmetUI.propTypes = {
  page: PropTypes.string,
  values: PropTypes.shape(),
  options: PropTypes.shape(),
  locale: PropTypes.string.isRequired,
  intl: intlShape.isRequired,
  location: PropTypes.shape().isRequired,
};

HeadHelmetUI.defaultProps = {
  page: '',
  values: {},
  options: {},
};

export const HeadHelmet = withRouter(
  injectIntl(
    withLocale(
      HeadHelmetUI
    )
  )
);
