import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { fromEvent, merge } from 'rxjs';
import { throttleTime, startWith } from 'rxjs/operators';
import { canUseDOM, isIntersectionObserverAvailable, isElementInViewport } from 'core/helpers';

const DEFAULT_DELAY = 200;

export const LAZY_LOAD_TYPES = {
  IMAGE: 'image',
  BACKGROUND: 'background',
};

export class ImageLazyLoad extends Component {
  static propTypes = {
    type: PropTypes.oneOf([
      LAZY_LOAD_TYPES.IMAGE,
      LAZY_LOAD_TYPES.BACKGROUND,
    ]),
    elementTag: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.elementType,
    ]),
    delay: PropTypes.number,
    classNameVisible: PropTypes.string,
    classNameLoaded: PropTypes.string,
    classNameError: PropTypes.string,
    isUserLoggedIn: PropTypes.bool,
    src: PropTypes.string.isRequired,
    srcLazy: PropTypes.string.isRequired,
    srcFallback: PropTypes.string,
    scrollRoot: PropTypes.node,
    children: PropTypes.node,
  };

  static defaultProps = {
    type: LAZY_LOAD_TYPES.IMAGE,
    elementTag: 'div',
    delay: DEFAULT_DELAY,
    scrollRoot: null,
    classNameVisible: null,
    classNameLoaded: null,
    classNameError: null,
    srcFallback: null,
    children: null,
    isUserLoggedIn: false,
  };

  ref = React.createRef();

  constructor(props) {
    super(props);

    if (!canUseDOM()) {
      return;
    }

    const { scrollRoot } = this.props;
    const isIntersectionObserver = isIntersectionObserverAvailable();

    if (isIntersectionObserver) {
      this.lazyLoadObserver = new IntersectionObserver(
        this.checkIntersections, { root: scrollRoot }
      );
    } else {
      this.checkImageInViewPort$ = merge(
        fromEvent(scrollRoot || window, 'scroll'),
        fromEvent(scrollRoot || window, 'resize'),
      ).pipe(
        startWith('init'),
        throttleTime(DEFAULT_DELAY)
      );
    }
  }

  state = {
    imgSrc: this.props.src,
  };

  componentDidMount() {
    if (this.lazyLoadObserver) {
      this.lazyLoadObserver.observe(this.ref.current);
    } else {
      this.checkImageInViewPortSubscription = this.checkImageInViewPort$.subscribe(
        this.onScrollChange
      );
    }
  }

  componentDidUpdate(prevProps) {
    const { scrollRoot } = this.props;

    if (prevProps.isUserLoggedIn !== this.props.isUserLoggedIn) {
      this.lazyLoadObserver = new IntersectionObserver(
        this.checkIntersections, { root: scrollRoot }
      );

      this.lazyLoadObserver.observe(this.ref.current);
    }
  }

  componentWillUnmount() {
    this.unsubscribe();
  }

  checkIntersections = (entries) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        this.onVisible();
      }
    });
  };

  createImageSrcHandler = (imgSrc, className) => () => {
    if (imgSrc) {
      this.setState({ imgSrc });
    }

    if (className) {
      const imgNode = this.ref.current;

      if (imgNode) {
        imgNode.classList.add(className);
      }
    }
  };

  onScrollChange = () => {
    const { scrollRoot } = this.props;
    const imgNode = this.ref.current;

    if (isElementInViewport(imgNode, scrollRoot)) {
      this.onVisible();
    }
  };

  onVisible = () => {
    const {
      srcLazy,
      srcFallback,
      classNameVisible,
      classNameLoaded,
      classNameError,
    } = this.props;

    const imgNode = this.ref.current;
    const img = new Image();

    if (classNameVisible) {
      imgNode.classList.add(classNameVisible);
    }

    img.onload = this.createImageSrcHandler(srcLazy, classNameLoaded);
    img.onerror = this.createImageSrcHandler(srcFallback, classNameError);
    img.src = srcLazy;

    this.unsubscribe();
  };

  unsubscribe = () => {
    const imgNode = this.ref.current;

    if (this.lazyLoadObserver) {
      this.lazyLoadObserver.unobserve(imgNode);
    }

    if (this.checkImageInViewPortSubscription) {
      this.checkImageInViewPortSubscription.unsubscribe();
    }
  };

  render() {
    const {
      src,
      srcLazy,
      srcFallback,
      delay,
      classNameVisible,
      classNameLoaded,
      classNameError,
      scrollRoot,
      type,
      elementTag: ElementTag,
      children,
      ...restProps
    } = this.props;

    const { imgSrc } = this.state;

    return (
      <Fragment>
        {type === LAZY_LOAD_TYPES.IMAGE ? (
          <img
            src={imgSrc}
            alt=""
            ref={this.ref}
            {...restProps}
          />
        ) : (
          <ElementTag
            style={{ backgroundImage: `url("${imgSrc}")` }}
            ref={this.ref}
            {...restProps}
          >
            {children}
          </ElementTag>
        )}
      </Fragment>
    );
  }
}
