import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { PAYMENT_METHODS_CATEGORIES } from 'core/constants';
import { PaymentsMenu } from 'components/payments-menu/payments-menu';

import './deposit-options.scss';

export const DepositOptions = ({
  className,
  items,
  currency,
  handleDepositOptions,
  isModal,
}) =>
  (
    <div className={classNames('deposit-options position-relative d-flex flex-column', className)}>
      <PaymentsMenu
        otherItems={items[PAYMENT_METHODS_CATEGORIES.OTHER]}
        recentlyItems={items[PAYMENT_METHODS_CATEGORIES.RECENTLY]}
        handleSelectedCard={handleDepositOptions}
        currency={currency}
        isModal={isModal}
      />
    </div>
  );

DepositOptions.propTypes = {
  className: PropTypes.string,
  handleDepositOptions: PropTypes.func.isRequired,
  items: PropTypes.shape({
    recently: PropTypes.arrayOf(
      PropTypes.shape({
        code: PropTypes.string,
        paymentMethod: PropTypes.string,
        minimumDeposit: PropTypes.number,
        feeRate: PropTypes.number,
        conversionRequired: PropTypes.bool,
        maximumDeposit: PropTypes.number,
        popupRequired: PropTypes.bool,
        method: PropTypes.string,
      }),
    ),
    other: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
  currency: PropTypes.string.isRequired,
  isModal: PropTypes.bool,
};

DepositOptions.defaultProps = {
  className: '',
  isModal: false,
};
