import React, { Component } from 'react';
import PropTypes from 'prop-types';

export const withDropdownAlignment = WrappedComponent =>
  class WithDropdownAlignment extends Component {
    static propTypes = {
      name: PropTypes.string.isRequired,
    };

    state = {
      dropdownMenuAlignmentSettings: {},
    };

    mediaBreakpoints = null;

    componentDidMount() {
      this.mediaBreakpoints = [
        window.matchMedia('(max-width: 719px)'),
        window.matchMedia('(max-width: 959px)'),
      ];

      this.mediaBreakpoints.map(breakpoint => breakpoint.addListener(this.updateDropdownAlignment));
    }

    componentWillUnmount() {
      if (this.mediaBreakpoints) {
        this.mediaBreakpoints.map(breakpoint => breakpoint.removeListener(this.updateDropdownAlignment));
      }
    }

    updateDropdownAlignment = () => {
      const { name } = this.props;

      const isMobile = this.mediaBreakpoints[0].matches;

      let formElement = null;

      document.getElementsByClassName(`form-group-${name}`).forEach(
        (element) => {
          if (element.offsetParent) {
            formElement = element;
          }
        }
      );

      let defaultDropdown = null;

      document.getElementsByClassName(`${name}-dropdown`).forEach(
        (element) => {
          if (element.offsetParent) {
            defaultDropdown = element;
          }
        }
      );

      if (formElement && defaultDropdown) {
        const availableHeight = !!formElement
          && !!formElement.offsetParent && formElement.offsetParent.clientHeight
          - (formElement.offsetHeight + formElement.offsetTop);

        const isDropdownFullSize = !!formElement.offsetParent
          && formElement.offsetParent.clientHeight <= defaultDropdown.scrollHeight;

        const isDirectionTop = (availableHeight < formElement.offsetTop)
          && (availableHeight <= defaultDropdown.scrollHeight);

        const isScroll = defaultDropdown.scrollHeight > formElement.offsetTop;

        const dropdownMenuHeight = isDirectionTop
          ? !isScroll && defaultDropdown.scrollHeight
          || formElement.offsetTop
          : availableHeight;

        if (isMobile) {
          this.setState({
            dropdownMenuAlignmentSettings: {
              maxHeight: dropdownMenuHeight,
              top: isDirectionTop && -(dropdownMenuHeight),
              position: 'absolute',
              overflowY: isScroll && 'scroll',
            },
          });

          if (isDropdownFullSize) {
            this.setState({
              dropdownMenuAlignmentSettings: {
                maxHeight: '95%',
                top: '1rem',
                position: 'fixed',
                left: '1rem',
                maxWidth: 270,
                overflowY: isScroll && 'scroll',
              },
            });
          }
        } else {
          this.setState({
            dropdownMenuAlignmentSettings: {
              top: isDirectionTop && -(dropdownMenuHeight),
              maxHeight: dropdownMenuHeight,
              overflowY: isScroll && 'scroll',
            },
          });
        }
      }
    };

    render() {
      const { dropdownMenuAlignmentSettings } = this.state;

      return (
        <WrappedComponent
          {...this.props}
          dropdownMenuAlignmentSettings={dropdownMenuAlignmentSettings}
          updateDropdownAlignment={this.updateDropdownAlignment}
        />
      );
    }
  };
