import React from 'react';
import PropTypes from 'prop-types';

export const IconPlayCircle = ({ className, isSmall }) => (
  <svg
    className={className}
    width={isSmall ? '38' : '64'}
    height={isSmall ? '39' : '64'}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 64 64"
    fill="none"
  >
    <circle opacity="0.2" cx="32" cy="32" r="32" fill="white" />
    <circle cx="31.9999" cy="32" r="27.6364" fill="url(#icon-play-circle-0)" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M31.9999 58.1818C46.4597 58.1818 58.1817 46.4598 58.1817 32C58.1817 17.5402 46.4597 5.81818 31.9999 5.81818C17.5401 5.81818 5.81812 17.5402 5.81812 32C5.81812 46.4598 17.5401 58.1818 31.9999 58.1818ZM31.6517 49.4545C45.5006 49.4545 56.7273 39.6944 56.7273 27.6546C56.7273 20.1914 50.9111 23.67 44.122 27.7305C39.9588 30.2205 35.4298 32.9293 31.6517 33.4679C28.1098 33.9729 23.7974 32.6115 19.7899 31.3465C13.1073 29.2369 7.27273 27.395 7.27273 34.9213C7.27273 46.961 17.8029 49.4545 31.6517 49.4545Z"
      fill="url(#icon-play-circle-1)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M42.3429 29.1623C44.043 30.5817 44.0734 33.2929 42.398 34.7477C39.064 37.6425 33.5122 41.9337 28.3086 43.5226C26.3832 44.1105 24.7274 42.3356 24.7274 40.1319V23.8935C24.7274 21.6178 26.4826 19.83 28.4516 20.5097C33.536 22.2647 39.0159 26.3847 42.3429 29.1623Z"
      fill="white"
    />
  </svg>
);

IconPlayCircle.propTypes = {
  className: PropTypes.string,
  isSmall: PropTypes.bool,
};

IconPlayCircle.defaultProps = {
  className: null,
  isSmall: false,
};
