import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Button from 'reactstrap/lib/Button';
import { withModalActions, withUser } from 'core/hocs';
import { FormattedTag } from 'core/components';
import { ModalWindow } from 'components/modal-window/modal-window';
import { USER_FIELDS } from 'core/constants';
import { MODAL_IDS } from '../../../constants';
import 'src/components/modals/promo-info-modal/promo-info-modal.scss';

class PromoModalUI extends Component {
  static propTypes = {
    openModalAndCloseOthers: PropTypes.func.isRequired,
    isSignUpCompleted: PropTypes.bool.isRequired,
  };

  onButtonClick = () => {
    const { openModalAndCloseOthers, isSignUpCompleted } = this.props;
    openModalAndCloseOthers(isSignUpCompleted ? MODAL_IDS.DEPOSIT : MODAL_IDS.COMPLETE_SIGN_UP);
  }

  render() {
    return (
      <ModalWindow
        id={MODAL_IDS.PROMO_INFO}
        coreProps={{ id: MODAL_IDS.PROMO_INFO }}
        bodyClassName="d-flex align-items-center flex-column justify-content-end text-center text-blue-dark"
        className="promo-info-modal"
        isModalCentered
        fade
      >
        <FormattedTag
          tag="div"
          id="promo-info-modal.description"
          className="h5 promo-description mb-1_5 mx-auto font-family-oswald promo-info-modal-description text-white text-uppercase"
        />
        <FormattedTag
          tag="div"
          id="promo-info-modal.title"
          className="h5 mb-1_25 mt-2 promo-info-modal-title font-family-oswald text-uppercase text-white"
        />
        <div className="mt-3_5 w-100">
          <FormattedTag
            id="activate"
            tag={Button}
            type="secondary"
            className="w-100 btn btn-secondary btn-lg"
            onClick={this.onButtonClick}
          />
        </div>
      </ModalWindow>
    );
  }
}

export const PromoInfoModal = withUser(withModalActions(PromoModalUI), [USER_FIELDS.IS_SIGN_UP_COMPLETED]);
