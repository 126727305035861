import store from 'store';
import * as R from 'ramda';
import { canUseDOM, isEmptyOrNil } from 'core/helpers';

// eslint-disable-next-line consistent-return
export const setLocalStorageData = (key, data) => {
  if (!canUseDOM() || isEmptyOrNil(key) || R.isEmpty(data)) {
    return null;
  }

  store.set(key, data);
};

export const getLocalStorageData = (key) => {
  if (!canUseDOM() || isEmptyOrNil(key)) {
    return null;
  }

  return store.get(key);
};

// eslint-disable-next-line consistent-return
export const clearLocalStorageData = (key) => {
  if (!canUseDOM() || isEmptyOrNil(key)) {
    return null;
  }

  store.remove(key);
};
