import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './icon-dot-preloader.scss';

export const IconDotPreloader = ({ className }) => (
  <span className={classNames('icon-dot-preloader', className)} />
);

IconDotPreloader.propTypes = {
  className: PropTypes.string,
};

IconDotPreloader.defaultProps = {
  className: '',
};
