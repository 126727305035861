import * as R from 'ramda';
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Input from 'reactstrap/lib/Input';
import classNames from 'classnames';
import { clearFormElementProps } from 'core/helpers';
import { withUserAgent } from 'core/hocs';

import { IconDotPreloader } from 'components/icons/icon-dot-preloader/icon-dot-preloader';
import { IconStatusApproved } from 'components/icons/icon-status-approved/icon-status-approved';
import { IconInputError } from 'components/icons/icon-input-error/icon-input-error';
import { hideNavigationOnInputFocus, showNavigationOnInputBlur } from 'helpers/form-elements';
import colors from 'customizations/js/color-variables';
import { MOBILE } from '../../constants';

import './input-text.scss';

class InputTextUI extends PureComponent {
  static propTypes = {
    autoFocus: PropTypes.bool,
    className: PropTypes.string,
    icon: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    isLoading: PropTypes.bool,
    disabled: PropTypes.bool,
    animatedPlaceholder: PropTypes.string,
    name: PropTypes.string.isRequired,
    type: PropTypes.string,
    onChange: PropTypes.func,
    isError: PropTypes.bool,
    rightContent: PropTypes.node,
    leftContent: PropTypes.node,
    placeholder: PropTypes.string,
    isNotEditable: PropTypes.bool,
    wrapperClassName: PropTypes.string,
    readOnly: PropTypes.bool,
    deviceType: PropTypes.string,
  }

  static defaultProps = {
    icon: null,
    value: '',
    isLoading: false,
    disabled: false,
    isError: false,
    className: '',
    type: 'text',
    animatedPlaceholder: '',
    leftContent: null,
    rightContent: null,
    placeholder: '',
    isNotEditable: false,
    wrapperClassName: null,
    autoFocus: false,
    readOnly: false,
    onChange: null,
    deviceType: 'desktop',
  }

   onFocus = () => {
     const { deviceType, name } = this.props;

     if (deviceType === MOBILE && (name === 'email' || name === 'password')) {
       this.navigation = document.querySelector('.casino-navigation');
       this.cookies = document.querySelector('.cookies-policy');

       hideNavigationOnInputFocus(this.cookies, this.navigation);
     }
   }

   onBlur = () => {
     const { props: { deviceType }, navigation, cookies } = this;

     showNavigationOnInputBlur(deviceType, cookies, navigation);
   };

   render() {
     const {
       icon: Icon,
       disabled,
       isLoading,
       value,
       className,
       animatedPlaceholder,
       name,
       type,
       onChange,
       isError,
       leftContent,
       rightContent,
       placeholder,
       isNotEditable,
       wrapperClassName,
       autoFocus,
       readOnly,
       ...props
     } = this.props;

     const otherProps = R.omit(['dispatch', 'isPwa'], { ...clearFormElementProps(props) });

     return (
       <div className={classNames('text-right d-flex align-items-center input-text-container position-relative w-100', wrapperClassName)}>
         {value && leftContent && (
           <span className="position-absolute left-content">
             {leftContent}
           </span>
         )}

         <Input
           {...otherProps}
           value={isLoading ? '' : value}
           disabled={disabled || isLoading || isNotEditable}
           className={classNames(className, 'w-100 input-inner', {
             'pt-3 pb-1 with-animated-placeholder': !!animatedPlaceholder,
             'border-danger': isError,
             'pl-6_25 with-icon': !!Icon,
             'pr-5': !!rightContent || isNotEditable,
             'pl-3_25': leftContent,
           })}
           onChange={onChange}
           name={name}
           type={type}
           placeholder={placeholder}
           autoFocus={autoFocus}
           readOnly={readOnly}
           onFocus={this.onFocus}
           onBlur={this.onBlur}
         />
         {animatedPlaceholder && (
           <span className="position-absolute animated-placeholder">
             {animatedPlaceholder}
           </span>
         )}
         {!!Icon && (
           <span className="d-flex input-icon position-absolute justify-content-center">
             {' '}
             <Icon color={value ? colors.gray40 : colors.gray10} />
           </span>
         )}
         {rightContent && (
           <span className="position-absolute right-content">
             {rightContent}
           </span>
         )}
         {isLoading && <IconDotPreloader className="dot-preloader text-gray-dark-hint position-absolute" />}
         {isNotEditable && <IconStatusApproved className="icon-success position-absolute" />}

         {isError && !rightContent && <IconInputError className="icon-input-error position-absolute" />}
       </div>
     );
   }
}

export const InputText = withUserAgent(InputTextUI);

