import React from 'react';
import PropTypes from 'prop-types';

export const IconDesktopSearch = ({ className }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width="28"
    height="28"
    viewBox="0 0 28 28"
    fill="none"
  >
    <path fillRule="evenodd" clipRule="evenodd" d="M20.4107 12.8319C20.4107 15.971 17.8659 18.5158 14.7268 18.5158C11.5877 18.5158 9.04293 15.971 9.04293 12.8319C9.04293 9.69282 11.5877 7.14807 14.7268 7.14807C17.8659 7.14807 20.4107 9.69282 20.4107 12.8319ZM23.4107 12.8319C23.4107 17.6279 19.5228 21.5158 14.7268 21.5158C13.0107 21.5158 11.4108 21.018 10.0638 20.1589L6.56066 23.6621C5.97487 24.2479 5.02513 24.2479 4.43934 23.6621C3.85355 23.0763 3.85355 22.1265 4.43934 21.5407L7.84769 18.1324C6.71607 16.6659 6.04293 14.8275 6.04293 12.8319C6.04293 8.03597 9.93083 4.14807 14.7268 4.14807C19.5228 4.14807 23.4107 8.03597 23.4107 12.8319Z" fill="url(#icon_desktop_search)" />
  </svg>
);

IconDesktopSearch.propTypes = {
  className: PropTypes.string,
};

IconDesktopSearch.defaultProps = {
  className: '',
};
