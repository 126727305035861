import { Component } from 'react';
import { getEnv, addScriptElementToDOM } from 'core/helpers';

const RECAPTCHA_KEY = getEnv('RECAPTCHA_SITE_KEY') || '';

export class RecaptchaLoader extends Component {
  componentDidMount() {
    addScriptElementToDOM({
      src: `https://www.google.com/recaptcha/api.js?render=${RECAPTCHA_KEY}`,
      async: true,
    });
  }

  render() {
    return null;
  }
}
