module.exports = {
  confirmationCode: {
    type: 'text',
    placeholder: 'form.fields.otp-confirmation-code',
    length: { max: 10 },
    validation: [
      { type: 'required' },
      { type: 'min-length', value: 6 },
      { type: 'max-length', value: 6 },
    ],
  },
};
