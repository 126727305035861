/* eslint-disable max-len */
import classNames from 'classnames';
import { ProfileModal } from 'components/modals/profile-modal/profile-modal';
import { SkipServerRender } from 'components/skip-server-render/skip-server-render';
import { RecommendedGames as RecommendedGamesCore } from 'core/components';
import { USER_FIELDS, TOURNAMENT_STATUSES } from 'core/constants';
import { withCoreComponent, withModalActions, withUser } from 'core/hocs';
import PropTypes from 'prop-types';
import React from 'react';
import { PAGE_NAMES } from '../../constants';

import { CasinoHeader } from './casino-header/casino-header';
import { CasinoHeaderMobile } from './casino-header/casino-header-mobile';
import { HeaderContent } from './header-content';
import { StickyHeader } from './sticky-header/sticky-header';

import './header.scss';

const HeaderUI = ({
  pageName,
  tournamentParams,
  mobileBackRoute,
  className,
  isUserLoggedIn,
  isUserRegistered,
  games: recommendedGames,
  categoryRouteParam,
  openModal,
}) => {
  const isResetPasswordPage = pageName === PAGE_NAMES.RESET_PASSWORD;
  // const isTransparentHeader = [PAGE_NAMES.HOME, PAGE_NAMES.GAME, PAGE_NAMES.GAMES, PAGE_NAMES.TOURNAMENT, PAGE_NAMES.CASINO].includes(pageName);

  return (
    <>
      <CasinoHeader
        className={classNames('casino-header pl-md-0_5', {
          'reset-password-page border-bottom border-white-10': isResetPasswordPage,
        }, className)}
      >
        <HeaderContent
          pageName={pageName}
          openModal={openModal}
          isUserRegistered={isUserRegistered}
          isUserLoggedIn={isUserLoggedIn}
          categoryRouteParam={categoryRouteParam}
          tournamentParams={tournamentParams}
          coreProps={{
            availableStatuses: [
              TOURNAMENT_STATUSES.ACTIVE,
              TOURNAMENT_STATUSES.PENDING,
              TOURNAMENT_STATUSES.AWARDED,
              TOURNAMENT_STATUSES.COMPLETED,
            ]
          }}
        />
      </CasinoHeader>

      <div className="casino-header-mobile bg-white d-flex d-md-none align-items-center justify-content-between px-1_25 px-sm-2_5">
        <CasinoHeaderMobile
          pageName={pageName}
          recommendedGames={recommendedGames}
          isUserLoggedIn={isUserLoggedIn}
        />
      </div>

      <StickyHeader className={classNames({ 'reset-password-page': isResetPasswordPage })}>
        <CasinoHeader className={classNames('casino-sticky-header h-100', { 'justify-content-sm-center': isResetPasswordPage })}>
          <HeaderContent
            tournamentParams={tournamentParams}
            pageName={pageName}
            openModal={openModal}
            isUserRegistered={isUserRegistered}
            isUserLoggedIn={isUserLoggedIn}
            categoryRouteParam={categoryRouteParam}
            coreProps={{
              availableStatuses: [
                TOURNAMENT_STATUSES.ACTIVE,
                TOURNAMENT_STATUSES.PENDING,
                TOURNAMENT_STATUSES.AWARDED,
                TOURNAMENT_STATUSES.COMPLETED,
              ]
            }}
          />
        </CasinoHeader>

        <div
          className="casino-header-mobile bg-white casino-sticky-header-mobile d-flex d-md-none align-items-center justify-content-between px-1_25 px-sm-2_5"
        >
          <CasinoHeaderMobile
            pageName={pageName}
            mobileBackRoute={mobileBackRoute}
            recommendedGames={recommendedGames}
            isUserLoggedIn={isUserLoggedIn}
          />
        </div>
      </StickyHeader>

      <SkipServerRender skip>
        <ProfileModal />
      </SkipServerRender>
    </>
  );
};

HeaderUI.propTypes = {
  pageName: PropTypes.string,
  mobileBackRoute: PropTypes.string,
  className: PropTypes.string,
  games: PropTypes.arrayOf(PropTypes.shape()),
  tournamentParams: PropTypes.shape(),
  openModal: PropTypes.func.isRequired,
  isUserRegistered: PropTypes.bool,
  isUserLoggedIn: PropTypes.bool,
  categoryRouteParam: PropTypes.string,
};

HeaderUI.defaultProps = {
  pageName: null,
  mobileBackRoute: null,
  className: null,
  games: [],
  isUserRegistered: false,
  isUserLoggedIn: false,
  categoryRouteParam: '',
  tournamentParams: {},
};

export const Header = withCoreComponent(
  RecommendedGamesCore,
  withModalActions(
    withUser(
      HeaderUI, [
        USER_FIELDS.IS_USER_REGISTERED,
        USER_FIELDS.IS_USER_LOGGED_IN,
      ]
    )
  )
);
/* eslint-enable max-len */
