import React, { Component } from 'react';
import PropTypes from 'prop-types';
import MaskedInput from 'react-text-mask';
import createAutoCorrectedDatePipe from 'text-mask-addons/dist/createAutoCorrectedDatePipe';
import { withLocale } from 'core/hocs';
import { DateInputInner } from './date-input-inner';
import { DATE_MASK_DIVIDERS } from '../../../constants';

export class DateInputUI extends Component {
  autoCorrectedDatePipe = createAutoCorrectedDatePipe('dd.mm.yyyy');

  renderInner = (setRef, props) => <DateInputInner setRef={setRef} {...this.props} otherProps={props} />;

  render() {
    const { locale, isSelfExclusion } = this.props;
    const dividerByLocale = DATE_MASK_DIVIDERS[locale] || '.';
    const divider = isSelfExclusion ? '-' : dividerByLocale;

    return (
      <MaskedInput
        mask={[/\d/, /\d/, divider, /\d/, /\d/, divider, /\d/, /\d/, /\d/, /\d/]}
        keepCharPositions
        pipe={this.autoCorrectedDatePipe}
        render={this.renderInner}
      />
    );
  }
}

DateInputUI.propTypes = {
  locale: PropTypes.string.isRequired,
  isSelfExclusion: PropTypes.bool
};

DateInputUI.defaultProps = {
  isSelfExclusion: false
};

export const DateInput = withLocale(DateInputUI);
