module.exports = {
  username: {
    type: 'text',
    placeholder: 'form.fields.username-or-email',
    validation: [
      { type: 'required' },
    ],
  },
  password: {
    type: 'password',
    placeholder: 'form.fields.password',
    validation: [
      { type: 'required' },
    ],
  },
};
