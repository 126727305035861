import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Input } from '../input/input';

export class DateInputInner extends PureComponent {
  static propTypes = {
    name: PropTypes.string.isRequired,
    value: PropTypes.string,
    label: PropTypes.string,
    placeholder: PropTypes.string,
    size: PropTypes.string,
    labelClassName: PropTypes.string,
    wrapperClassName: PropTypes.string,
    onChange: PropTypes.func,
    isRequired: PropTypes.bool,
    setRef: PropTypes.func.isRequired,
    otherProps: PropTypes.shape().isRequired,
  };

  static defaultProps = {
    value: '',
    label: null,
    isRequired: false,
    placeholder: null,
    onChange: null,
    labelClassName: null,
    wrapperClassName: null,
    size: null,
  };

  inputRef = React.createRef();

  componentDidMount() {
    const { setRef } = this.props;
    setRef(this.inputRef.current);
  }

  onChange = (e) => {
    const { otherProps, onChange } = this.props;
    otherProps.onChange(e);
    onChange(e);
  };

  render() {
    const {
      otherProps, ...props
    } = this.props;

    return (
      <Input
        {...props}
        {...otherProps}
        type="text"
        inputmode="numeric"
        onChange={this.onChange}
        innerRef={this.inputRef}
      />
    );
  }
}
