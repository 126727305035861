import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { toDateFormat } from 'core/helpers';

import DayPickerInput from 'react-day-picker/DayPickerInput';
import {
  formatDate,
  parseDate,
} from 'react-day-picker/moment';
import 'react-day-picker/lib/style.css';
import './date-picker-input.scss';

export class DatePickerInput extends Component {
  static propTypes = {
    onChange: PropTypes.func.isRequired,
    boxDateFormat: PropTypes.string,
    value: PropTypes.string,
    error: PropTypes.shape(),
  };

  static defaultProps = {
    error: null,
    value: '',
    boxDateFormat: '',
  };

  state = {
    selectedDay: null,
  }

  componentDidMount() {
    const { selectedDay } = this.state;

    if (!selectedDay) {
      this.setDateFromCore();
    }
  }

  onDayChange = (e) => {
    const { onChange, boxDateFormat } = this.props;

    if (e) {
      this.setState({ selectedDay: e });
      onChange(toDateFormat(e, boxDateFormat));
    }
  }

  handleDayClick = (day) => {
    const { onChange, boxDateFormat } = this.props;

    this.setState({ selectedDay: day });
    onChange(toDateFormat(day, boxDateFormat));
  }

  setDateFromCore = () => {
    const { value, boxDateFormat } = this.props;

    this.setState({ selectedDay: new Date(toDateFormat(value, 'YYYY-MM-DDT00:00:00', boxDateFormat)) });
  }

  render() {
    const {
      onChange, error, boxDateFormat, ...props
    } = this.props;
    const { selectedDay } = this.state;

    return (
      <DayPickerInput
        format={boxDateFormat}
        formatDate={formatDate}
        parseDate={parseDate}
        dayPickerProps={{
          selectedDays: this.state.selectedDay,
          onDayClick: this.handleDayClick,
          showOutsideDays: true,
        }}
        onDayChange={this.onDayChange}
        inputProps={{
          className: classNames('DayPickerInput-input form-control w-100 input-inner', {
            'border-danger error': !!error,
          }),
          onBlur: this.setDateFromCore,
        }}
        {...props}
        value={selectedDay}
      />
    );
  }
}
