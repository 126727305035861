import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withUser, withLocale } from 'core/hocs';
import { addScriptElementToDOM, isEmptyOrNil } from 'core/helpers';
import { USER_FIELDS, LOCALE_KEYS } from 'core/constants';
import { IconLiveChat } from 'components/icons/icon-live-chat/icon-live-chat';

import { SPORTSBOOK_POST_MESSAGE_TYPES } from '../../../constants';

import './zendesk-chat.scss';

const CHAT_LOCALES = {
  [LOCALE_KEYS.EN]: 'en',
  [LOCALE_KEYS.ES]: 'es',
};

class ZendeskChatUI extends Component {
  state = {
    isChatOpen: false,
  }

  static propTypes = {
    /* eslint-disable react/no-unused-prop-types */
    licenseKey: PropTypes.string.isRequired,
    group: PropTypes.string,
    additionalParams: PropTypes.shape(),
    isButtonHidden: PropTypes.bool,
    userData: PropTypes.shape(),
    locale: PropTypes.string.isRequired,
    onChatScriptLoaded: PropTypes.func,
    onChatScriptLoadingError: PropTypes.func,
    /* eslint-enable react/no-unused-prop-types */
  };

  static defaultProps = {
    group: null,
    isButtonHidden: false,
    additionalParams: null,
    userData: {},
    onChatScriptLoaded: () => {},
    onChatScriptLoadingError: () => {},
  };

  componentDidMount() {
    this.loadChatApi();
    window.addEventListener('message', this.onIframeMessage);
  }

  componentWillUnmount() {
    window.removeEventListener('message', this.onIframeMessage);
  }

  onIframeMessage = ({ data }) => {
    const { type } = data || {};

    if (type === SPORTSBOOK_POST_MESSAGE_TYPES.OPEN_CHAT) {
      this.onClick();
    }
  };

  chatScriptIsLoadedWithSuccess = () => {
    const { locale } = this.props;

    if (window.zE) {
      window.zE('messenger:set', 'locale', CHAT_LOCALES[locale] || LOCALE_KEYS.EN);
      window.zE('messenger:set', 'zIndex', 1031);

      window.zE('messenger:on', 'open', () => {
        this.setState({ isChatOpen: true });
      });

      window.zE('messenger:on', 'close', () => {
        this.setState({ isChatOpen: false });
      });
    }

    this.props.onChatScriptLoaded(window.zE);
  };

  chatScriptIsLoadedWithError = () => {
    this.props.onChatScriptLoadingError();
  };

  loadChatApi = () => {
    const { licenseKey } = this.props;

    if (isEmptyOrNil(licenseKey)) {
      throw new Error('`licenseKey` is REQUIRED for adding the Zendesk widget');
    }

    addScriptElementToDOM({
      src: `https://static.zdassets.com/ekr/snippet.js?key=${licenseKey}`,
      attributes: {
        id: 'ze-snippet',
        'data-script-zendesk': '',
        // Disables Cloudflare Rocket Loader script
        'data-cfasync': 'false',
      },
      onLoadEventListener: this.chatScriptIsLoadedWithSuccess,
      onErrorEventListener: this.chatScriptIsLoadedWithError,
    });
  };
  onClick = () => {
    this.toggleZendeskChatWidget();
  };

  toggleZendeskChatWidget = () => {
    if (!window.zE) return;

    if (this.state.isChatOpen) {
      window.zE('messenger', 'close');
    } else {
      window.zE('messenger', 'open');
    }
  }

  render() {
    const { isButtonHidden } = this.props;

    if (isButtonHidden) {
      return null;
    }

    /*
      ATTENTION!
      In order to show chat widget's DEFAULT button - add `return null` here
      and comment out `iframe#launcher` style in app.scss file
    */

    return (
      <div
        className="custom-chat-button custom-chat-button-zendesk d-flex justify-content-center align-items-center position-fixed"
        role="button"
        tabIndex="0"
        onClick={this.onClick}
        onKeyPress={this.onClick}
      >
        <IconLiveChat />
      </div>
    );
  }
}

export const ZendeskChat = withLocale(withUser(ZendeskChatUI, [USER_FIELDS.USER_DATA]));
