import React from 'react';
import PropTypes from 'prop-types';
import colors from 'customizations/js/color-variables';

export const IconArrowRightSmall = ({ className, color }) => (
  <svg className={className} width="5" height="7" viewBox="0 0 5 7" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.65794 4.35353C5.10751 3.9266 5.11553 3.11111 4.67251 2.67354C3.79095 1.80284 2.3229 0.512129 0.946961 0.0342166C0.437832 -0.142621 4.76837e-07 0.391239 4.76837e-07 1.05408L4.76837e-07 5.93829C4.76837e-07 6.62278 0.464133 7.16049 0.984767 6.95607C2.3292 6.42818 3.77821 5.18898 4.65794 4.35353Z"
      fill={color}
    />
  </svg>
);

IconArrowRightSmall.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string,
};
IconArrowRightSmall.defaultProps = {
  className: '',
  color: colors.gray10,
};
