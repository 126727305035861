import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import Button from 'reactstrap/lib/Button';
import PropTypes from 'prop-types';
import { IconSuccessBig } from 'components/icons/icon-notification/icon-success-big';
import { IconError } from 'components/icons/icon-notification/icon-error';
import { FormattedTag, RouteLink } from 'core/components';

import './status-notification-content.scss';

export class StatusNotificationContent extends Component {
  static propTypes = {
    headerIntlKey: PropTypes.string.isRequired,
    descIntlKey: PropTypes.string.isRequired,
    buttonIntlKey: PropTypes.string,
    email: PropTypes.string,
    onClick: PropTypes.func.isRequired,
    withCloseButton: PropTypes.bool,
    buttonType: PropTypes.string, // eslint-disable-line react/no-unused-prop-types
    // buttonOutline: PropTypes.bool,
    isSuccess: PropTypes.bool, // eslint-disable-line react/no-unused-prop-types
    link: PropTypes.string,
    withMainButton: PropTypes.bool,
  };

  static defaultProps = {
    email: '',
    withCloseButton: false,
    buttonIntlKey: 'got-it',
    buttonType: 'primary',
    // buttonOutline: false,
    isSuccess: true,
    link: null,
    withMainButton: true,
  };

  componentDidMount() {
    const { withCloseButton } = this.props;

    if (!withCloseButton) {
      const closeButton = document.querySelector('.modal-body .close-button');

      if (closeButton) {
        closeButton.classList.add('invisible');
      }
    }
  }

  getMainBtnElement = () => {
    const {
      buttonIntlKey,
      onClick,
    } = this.props;

    return (
      <FormattedTag
        id={buttonIntlKey}
        tag={Button}
        onClick={onClick}
        color="primary"
        className="status-notification-content-button mt-1 mx-auto"
      />
    );
  }

  render() {
    const {
      headerIntlKey,
      descIntlKey,
      email,
      link,
      withMainButton,
      isSuccess,
    } = this.props;

    const StatusIcon = isSuccess ? IconSuccessBig : IconError;

    return (
      <div className="status-notification-content d-flex flex-column align-items-center text-center">
        <StatusIcon className="status-notification-content-icon mb-2 mb-sm-1_5" />

        <FormattedTag
          id={headerIntlKey}
          className="status-notification-content-header mt-0_5 text-center text-blue-dark text-uppercase font-family-oswald mb-1"
          isHtml
        />

        <div className="status-notification-content-desc text-blue-dark text-center letter-spacing-default mt-0_25 mb-3">
          <FormattedMessage
            id={descIntlKey}
            values={{ email: <span className="text-gray-40">{email}</span> }}
          />
        </div>

        {withMainButton && (
          link ? (
            <RouteLink to={link}>
              {this.getMainBtnElement()}
            </RouteLink>
          ) : this.getMainBtnElement()
        )}
      </div>
    );
  }
}
