import React, { Component } from 'react';
import { checkPixelRatio } from '../helpers/check-pixel-ratio';

export const withPixelRatio = WrappedComponent =>
  class WithPixelRatio extends Component {
    state = {
      pixelRatio: '',
    };

    componentDidMount() {
      this.setState({ pixelRatio: checkPixelRatio() });
    }

    render() {
      const { pixelRatio } = this.state;

      return (
        <WrappedComponent
          {...this.props}
          pixelRatio={pixelRatio}
        />
      );
    }
  };
