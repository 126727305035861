/* eslint-disable react/jsx-curly-newline */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import {
  injectIntl,
  // intlShape,
  // FormattedMessage
} from 'react-intl';
import * as R from 'ramda';
import classNames from 'classnames';

import { withModalActions, withCoreComponent } from 'core/hocs';
import { withPixelRatio } from 'hocs/with-pixel-ratio';
import {
  getEnv,
  // getStaticUrl,
  isProjectEnvProdMode,
  getCasinoLicenseKey,
  // getCasinoLicenseId,
  setDocumentScrollTopPosition,
  addScriptElementToDOM,
  getValidationLicenseLink,
  isEmptyOrNil,
} from 'core/helpers';
import { RouteLink, FormattedTag, Producers as ProducersCore } from 'core/components';
import { LangSwitcher } from 'components/lang-switcher/lang-switcher';

import {
  footerMenu,
  // footerIconsWithLink,
  footerInfo,
  // getFooterSocialIconsWithLink,
} from './footer-menu';
import { FooterCollapse } from './footer-collapse/footer-collapse';
import { MODAL_IDS } from '../../constants';

import './footer.scss';

// const staticUrl = getStaticUrl();
const casinoLicenseKey = getCasinoLicenseKey();
// const casinoLicenseId = getCasinoLicenseId();

export class FooterUI extends PureComponent {
  static propTypes = {
    openModal: PropTypes.func,
    className: PropTypes.string,
  };

  static defaultProps = {
    openModal: null,
    className: null,
  };

  state = {
    liveChatIncBadgeContent: '',
  };

  liveChatIncBadgeOriginalRef = React.createRef();

  componentDidMount() {
    this.loadQualityBadgeScript();
  }

  loadQualityBadgeScript = () => {
    const licenseKey = getEnv('LIVECHATINC_API_LICENSE_KEY');

    if (isEmptyOrNil(licenseKey)) {
      throw new Error("`LIVECHATINC_API_LICENSE_KEY` env's key is REQUIRED for adding the LiveChatInc quality badge");
    }

    addScriptElementToDOM({
      src: `https://cdn.livechatinc.com/qb/qb-${licenseKey}-light-160.js`,
      async: true,
      attributes: {
        'data-script-livechatincqualtybadge': '',
        // Disables Cloudflare Rocket Loader script
        'data-cfasync': 'false',
      },
      onLoadEventListener: () => setTimeout(() => this.handleLiveChatBadgeContent(), 1000),
      onErrorEventListener: () => console.log('Failed to load live chat quality badge script.'),
    });
  };

  handleLiveChatBadgeContent = () => {
    const { current } = this.liveChatIncBadgeOriginalRef;

    if (isEmptyOrNil(current)) {
      return;
    }

    this.setState({ liveChatIncBadgeContent: current?.innerHTML || '' });
  };

  getLiveChatBadgeElement = (className = '') => {
    const { liveChatIncBadgeContent: content } = this.state;

    if (isEmptyOrNil(content)) {
      return null;
    }

    return (
      <div
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{ __html: content }}
        className={classNames('live-chat-inc-badge-custom', className)}
      />
    );
  };

  componentDidMount = () => {
    if (isProjectEnvProdMode()) {
      addScriptElementToDOM({
        src: getValidationLicenseLink(),
        async: true,
        onLoadEventListener: this.invokeLicenseValidation,
      });
    }

    // Extra license seal
    this.invokeLicenseValidation({ licenseKey: 'emr_96605720_eeec_44eb_9bfa_ba1e4ef23c15' });

    return null;
  };

  invokeLicenseValidation = ({ licenseKey }) => {
    const apgInit = R.path([licenseKey || casinoLicenseKey, 'init'], window);

    R.when(R.is(Function), R.call)(apgInit);
  };

  openModalAction = (e) => {
    const { openModal } = this.props;

    e.preventDefault();
    openModal(MODAL_IDS.SUPPORT);
  };

  render() {
    const {
      className,
    } = this.props;

    return (
      <div className={classNames('footer-wrapper', className)}>
        <div className="footer border-top border-gray-10-20 pt-sm-4_5">
          <div className="footer-top d-flex flex-column flex-sm-row justify-content-sm-between pb-sm-4_25 mb-sm-1_5">
            <div className="footer-content-wrapper mb-4 d-flex justify-content-lg-start flex-column flex-sm-row align-items-sm-start">
              <FooterCollapse togglerTitle="menu">
                <div className="footer-menu-wrapper d-flex flex-column mx-sm-3 d-sm-none">
                  {footerMenu.map(({
                    title, link, modalId, isExternal,
                  }) => (
                    <FormattedTag
                      tag={isExternal ? 'a' : RouteLink}
                      key={title}
                      to={link || '/'}
                      href={link}
                      onClick={(modalId && this.openModalAction) || setDocumentScrollTopPosition}
                      id={title}
                      className="mb-0_5 footer-menu-item"
                    />
                  ))}
                </div>
              </FooterCollapse>
              <FooterCollapse togglerTitle="info">
                <div className="footer-menu-wrapper footer-info d-flex flex-column d-sm-none mx-sm-2_5">
                  {footerInfo.map(column =>
                    column.map(({
                      title, link, modalId, isExternal,
                    }) => (
                      <FormattedTag
                        tag={isExternal ? 'a' : RouteLink}
                        key={title}
                        to={link || '/'}
                        href={link}
                        onClick={(modalId && this.openModalAction) || setDocumentScrollTopPosition}
                        id={title}
                        className="mb-0_5 footer-menu-item"
                      />
                    )))}
                </div>
              </FooterCollapse>
              <div className="footer-menu-wrapper d-none flex-column mx-sm-3 d-sm-flex  footer-menu">
                {footerMenu.map(({
                  title, link, modalId, isExternal,
                }) => (
                  <FormattedTag
                    tag={isExternal ? 'a' : RouteLink}
                    key={title}
                    to={link || '/'}
                    href={link}
                    onClick={(modalId && this.openModalAction) || setDocumentScrollTopPosition}
                    id={title}
                    className="mb-0_5 footer-menu-item"
                  />
                ))}
              </div>
              {footerInfo.map(column => (
                <div className="footer-menu-wrapper flex-column d-none d-sm-flex mr-sm-14">
                  {column.map(({
                    title, link, modalId, isExternal,
                  }) => (
                    <FormattedTag
                      tag={isExternal ? 'a' : RouteLink}
                      key={title}
                      to={link || '/'}
                      href={link}
                      onClick={(modalId && this.openModalAction) || setDocumentScrollTopPosition}
                      id={title}
                      className="footer-menu-item mb-0_5"
                    />
                  ))}
                </div>
              ))}
              <div className="footer-menu-wrapper social-with-language-wrapper">
                <LangSwitcher withText className="language" />
              </div>
            </div>
          </div>
          <div className="footer-disclaimer d-flex flex-column align-items-center pt-2 pb-2_5">
            <FormattedTag
              id="footer.disclaimer.age-restriction"
              className="text-small text-electric-blue-dark"
            />

            <FormattedTag
              id="footer.disclaimer.play-responsibly"
              className="text-small text-electric-blue-dark"
            />
          </div>
        </div>
      </div>
    );
  }
}

export const Footer = withModalActions(withPixelRatio(withCoreComponent(ProducersCore, injectIntl(FooterUI))));
