import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export const IconGamblersAnonymous = ({ className }) => (
  <svg className={classNames('footer-icon', className)} width="49" height="30" viewBox="0 0 49 30">
    <path fill="#FFF" fillRule="evenodd" d="M37.085 16.857h-7.4v7.955h-3.782V0h6.034l13.675 24.812H41.51l-4.425-7.955zm-7.4-3.151h5.63l-5.63-10.004v10.004zM0 29.538h48.267l-2.091-3.78H2.092L0 29.538zm21.238-8.507v-4.174h-6.356l1.69-3.151h8.286v11.106H2.655L16.33 0h8.528v10.634h-3.62V3.308h-1.931L9.332 21.031h11.906z" />
  </svg>
);

IconGamblersAnonymous.propTypes = {
  className: PropTypes.string,
};

IconGamblersAnonymous.defaultProps = {
  className: '',
};

