import React from 'react';
import PropTypes from 'prop-types';

/**
 * In order to avoid error "Unknown props `currentSlide`, `slideCount` props"
 * these ones should not be applied to an element
 * @see https://github.com/akiran/react-slick/issues/728
 */

export const CustomArrow = ({
  currentSlide,
  slideCount,
  icon,
  ...rest
}) => (
  <span role="button" {...rest}>
    {icon}
  </span>
);

CustomArrow.propTypes = {
  currentSlide: PropTypes.number,
  slideCount: PropTypes.number,
  icon: PropTypes.node.isRequired,
};

CustomArrow.defaultProps = {
  currentSlide: null,
  slideCount: null,
};
