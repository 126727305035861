import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Form from 'reactstrap/lib/Form';
import Input from 'reactstrap/lib/Input';
import Spinner from 'reactstrap/lib/Spinner';
import { withCoreComponent, withModalActions, withNotificationsActions } from 'core/hocs';
import { FormSetupOtp as FormSetupOtpCore, FormattedTag } from 'core/components';
import { NOTIFICATION_MESSAGES } from 'core/constants';
import { FormElement } from 'components/form-element/form-element';
import { ButtonWithLoader } from 'components/button-with-loader/button-with-loader';
import { IconCopy } from 'components/icons/icon-copy/icon-copy';

import { MODAL_IDS } from '../../../constants';

import './form-setup-otp.scss';

export class FormSetupOtpUI extends Component {
  static propTypes = {
    setupOtp: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    imageBase64: PropTypes.string,
    secret: PropTypes.string,
    fields: PropTypes.shape().isRequired,
    isInProgress: PropTypes.bool.isRequired,
    isSuccess: PropTypes.bool.isRequired,
    closeModal: PropTypes.func.isRequired,
    showSuccessNotification: PropTypes.func.isRequired,
  };

  static defaultProps = {
    imageBase64: null,
    secret: null,
  };

  componentDidMount() {
    this.props.setupOtp();
  }

  componentDidUpdate({ isSuccess: isPrevSuccess }) {
    const {
      isSuccess,
      closeModal,
    } = this.props;

    if (isSuccess && !isPrevSuccess) {
      closeModal(MODAL_IDS.SETUP_OTP);
    }
  }

  copySecret = () => {
    const copyInput = document.querySelector('.form-setup-otp-copy-input');

    this.props.showSuccessNotification(NOTIFICATION_MESSAGES.TEXT_COPIED);
    copyInput.select();
    document.execCommand('copy');
  };

  onSubmit = (e) => {
    e.preventDefault();
    this.props.onSubmit();
  };

  render() {
    const {
      fields,
      isInProgress,
      imageBase64,
      secret,
    } = this.props;

    const isQrCodeExist = !!imageBase64;

    return (
      <Form
        onSubmit={this.onSubmit}
        className="form-setup-otp"
      >
        <FormattedTag
          tag="div"
          id="form.setup-otp.title"
          className="form-setup-otp-title d-block text-center mb-3"
          isHtml
        />

        <div>
          <FormattedTag
            tag="div"
            id="form.setup-otp.description.1"
            className="form-setup-otp-desc d-flex text-gray-10 mb-1_25"
            isHtml
          />

          <FormattedTag
            tag="div"
            id="form.setup-otp.description.2"
            className="form-setup-otp-desc d-flex text-gray-10 mb-1_25"
            isHtml
          />

          <div className="d-flex align-items-center mb-1_25">
            <div
              className={classNames('form-setup-otp-qr-code-wrapper d-flex justify-content-center align-items-center rounded overflow-hidden flex-shrink-0 mr-2', {
                border: !isQrCodeExist || isInProgress,
              })}
            >
              {isQrCodeExist && (
                <img
                  src={imageBase64}
                  alt="otp-qr-code"
                  className="form-setup-otp-qr-code-image"
                />
              ) || (
                isInProgress && <Spinner />
              ) || (
                <FormattedTag
                  id="form.setup-otp.img.not.found"
                  className="text-small text-danger text-center px-3"
                  isHtml
                />
              )}
            </div>

            <div className="d-flex flex-column">
              <FormattedTag
                tag="div"
                id="form.setup-otp.description.3"
                className="form-setup-otp-desc text-gray-10 letter-spacing-0 mb-1"
                isHtml
              />

              <div className="d-flex align-items-center">
                <div
                  role="button"
                  tabIndex={0}
                  onClick={this.copySecret}
                  onKeyPress={this.copySecret}
                  className="mr-1"
                >
                  <IconCopy />
                </div>

                <Input
                  value={secret}
                  readOnly
                  className="form-setup-otp-copy-input font-weight-bold text-gray-20 h-auto bg-transparent border-0 p-0"
                />
              </div>
            </div>
          </div>

          <div className="mb-3">
            <FormattedTag
              tag="div"
              id="form.setup-otp.description.4"
              className="form-setup-otp-desc d-flex text-gray-10 mb-1_25"
              isHtml
            />

            <div className="position-relative">
              <FormElement
                coreProps={{ item: fields.confirmationCode }}
                labelClassName="text-gray-10 mb-0_5"
                size="lg"
                isDisabled={isInProgress}
              />
            </div>
          </div>
        </div>

        <FormattedTag
          tag={ButtonWithLoader}
          id="confirm"
          type="submit"
          isLoading={isInProgress}
          color="primary"
          size="lg"
          className="w-100"
        />
      </Form>
    );
  }
}

export const FormSetupOtp = withCoreComponent(
  FormSetupOtpCore,
  withModalActions(
    withNotificationsActions(
      FormSetupOtpUI
    ),
  ),
);
