import React from 'react';
import PropTypes from 'prop-types';

export const IconLangNo = ({ className }) => (
  <svg className={className} xmlns="http://www.w3.org/2000/svg" width="30" height="24" viewBox="0 0 30 24">
    <defs>
      <path id="no-a" d="M5.128 0h19.744c1.783 0 2.43.186 3.082.534.652.349 1.163.86 1.512 1.512.348.652.534 1.299.534 3.082v13.744c0 1.783-.186 2.43-.534 3.082a3.635 3.635 0 0 1-1.512 1.512c-.652.348-1.299.534-3.082.534H5.128c-1.783 0-2.43-.186-3.082-.534a3.635 3.635 0 0 1-1.512-1.512C.186 21.302 0 20.655 0 18.872V5.128c0-1.783.186-2.43.534-3.082A3.635 3.635 0 0 1 2.046.534C2.698.186 3.345 0 5.128 0z" />
      <path id="no-d" d="M0 0h30v24H0z" />
      <filter id="no-c" width="116.7%" height="120.8%" x="-8.3%" y="-10.4%" filterUnits="objectBoundingBox">
        <feGaussianBlur in="SourceAlpha" result="shadowBlurInner1" stdDeviation="1.5" />
        <feOffset dy="2" in="shadowBlurInner1" result="shadowOffsetInner1" />
        <feComposite in="shadowOffsetInner1" in2="SourceAlpha" k2="-1" k3="1" operator="arithmetic" result="shadowInnerInner1" />
        <feColorMatrix in="shadowInnerInner1" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.2 0" />
      </filter>
    </defs>
    <g fill="none" fillRule="evenodd">
      <mask id="no-b" fill="#fff">
        <use xlinkHref="#no-a" />
      </mask>
      <g fillRule="nonzero" mask="url(#no-b)">
        <path fill="#FF4B55" d="M30.833 23.98H-.261A2.739 2.739 0 0 1-3 21.241V2.76A2.739 2.739 0 0 1-.261.019h31.094a2.739 2.739 0 0 1 2.738 2.74V21.24a2.739 2.739 0 0 1-2.738 2.74z" />
        <path fill="#F5F5F5" d="M33.571 8.847H13.394V.02H7.09v8.827H-3v6.306H7.089v8.827h6.305v-8.827h20.177z" />
        <path fill="#41479B" d="M33.571 10.108H12.133V.02H8.35v10.088H-3v3.784H8.35V23.98h3.783V13.892h21.438z" />
      </g>
      <g fill="#000" mask="url(#no-b)">
        <use filter="url(#no-c)" xlinkHref="#no-d" />
      </g>
    </g>
  </svg>

);

IconLangNo.propTypes = {
  className: PropTypes.string,
};

IconLangNo.defaultProps = {
  className: null,
};
