import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import Form from 'reactstrap/lib/Form';
import Button from 'reactstrap/lib/Button';
import { FormConfirmPhone as FormConfirmPhoneCore, FormattedTag } from 'core/components';
import { withCoreComponent, withModalActions } from 'core/hocs';
import { IconCheckCircle } from 'components/icons/icon-check-circle/icon-check-circle';
import { ButtonWithLoader } from 'components/button-with-loader/button-with-loader';

import { FormElement } from 'components/form-element/form-element';
import { CustomCountdown } from 'components/custom-countdown/custom-countdown';
import { SkipServerRender } from 'components/skip-server-render/skip-server-render';
import { MODAL_IDS } from '../../../constants';
import './form-confirm-phone.scss';

export class FormConfirmPhoneUI extends Component {
  static propTypes = {
    onSubmit: PropTypes.func.isRequired,
    clearResendTime: PropTypes.func.isRequired,
    sendSms: PropTypes.func.isRequired,
    mobile: PropTypes.string,
    fields: PropTypes.shape().isRequired,
    resendTime: PropTypes.oneOfType(PropTypes.string, PropTypes.number),
    isInProgress: PropTypes.bool.isRequired,
    isSuccess: PropTypes.bool.isRequired,
    isFormFilled: PropTypes.bool.isRequired,
    isMobileNumberError: PropTypes.bool,
    closeModal: PropTypes.func.isRequired,
    intl: intlShape.isRequired,
  };

  static defaultProps = {
    mobile: null,
    resendTime: null,
    isMobileNumberError: false,
  };

  countDownRefApi;

  componentDidMount() {
    const { resendTime } = this.props;

    if (resendTime) {
      this.startTimer();
    }
  }

  componentDidUpdate({ resendTime: prevResendTime }) {
    const { resendTime } = this.props;

    if (!prevResendTime && resendTime) {
      this.startTimer();
    }
  }

  startTimer = () => {
    this.countDownRefApi.start();
  }

  onCountdownComplete = () => {
    const { clearResendTime } = this.props;

    clearResendTime();
  }

  closeModal = () => {
    const { closeModal } = this.props;
    closeModal(MODAL_IDS.CONFIRM_PHONE);
  };

  setRef = (ref) => {
    if (ref) {
      this.countDownRefApi = ref.getApi();
    }
  }

  sendVerifySms = () => {
    const { sendSms, intl: { formatMessage } } = this.props;

    sendSms({ template: formatMessage({ id: 'form.confirm-phone.message-template' }) });
  };

  render() {
    const {
      fields, onSubmit, isInProgress, mobile,
      isSuccess, isFormFilled, resendTime, isMobileNumberError
    } = this.props;

    if (isSuccess) {
      return (
        <div className="text-center">
          <IconCheckCircle className="mb-4 mt-1" />

          <FormattedTag className="h3 text-center d-block" id="form.confirm-phone.success.title" />

          <FormattedTag
            className="mb-3 mt-1 px-4 text-small text-white-65 d-block font-weight-normal"
            id="form.confirm-phone.success.description"
          />

          <Button color="secondary" className="w-100 mt-1" onClick={this.closeModal}>
            <FormattedMessage id="got-it" />
          </Button>
        </div>
      );
    }

    return (
      <Form onSubmit={onSubmit}>
        <div className="text-center">
          <FormattedTag className="h2 text-center" id="form.confirm-phone.title" />

          <FormattedTag
            className="mb-3_75 mt-0_5 px-3 d-block"
            id="form.confirm-phone.description"
            values={{ mobile }}
            isHtml
          />
        </div>

        <div className="input-confirm-code position-relative">
          <FormElement
            classNames="mb-1"
            coreProps={{ item: fields.confirmationCode }}
            size="lg"
            isDisabled={isInProgress || isMobileNumberError}
          />
        </div>

        <FormattedTag
          tag={ButtonWithLoader}
          isLoading={isInProgress}
          color="primary"
          className="mt-2 w-100"
          type="submit"
          disabled={!isFormFilled || isMobileNumberError}
          id="verify"
        />

        <CustomCountdown
          date={resendTime}
          onComplete={this.onCountdownComplete}
          innerRef={this.setRef}
          autoStart={false}
        >
          {({ formatted: { minutes, seconds }, completed }) => (
            <SkipServerRender skip>
              {completed ? (
                <FormattedTag
                  tag={ButtonWithLoader}
                  isLoading={isInProgress}
                  color="gray-10-50"
                  className="mt-1_5 w-100"
                  outline
                  onClick={this.sendVerifySms}
                  disabled={isInProgress || isMobileNumberError}
                  id="resend"
                />
              ) : (
                <div className="w-100 text-center mt-3 d-flex justify-content-between align-items-center h4 font-weight-semi-bold">
                  <div className="resend-line" />
                  {minutes}:{seconds}
                  <div className="resend-line" />
                </div>
              )}
            </SkipServerRender>
          )}
        </CustomCountdown>
      </Form>
    );
  }
}

export const FormConfirmPhone = withCoreComponent(
  FormConfirmPhoneCore,
  withModalActions(
    injectIntl(FormConfirmPhoneUI)
  )
);
