import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Button from 'reactstrap/lib/Button';
import { withModalActions, withOtpActions } from 'core/hocs';
import { FormattedTag } from 'core/components';
import { ModalWindow } from 'components/modal-window/modal-window';

import { MODAL_IDS } from '../../../constants';

import './remove-otp-modal.scss';

export class SetupOtpModalUI extends Component {
  static propTypes = {
    removeOtp: PropTypes.func.isRequired,
    closeModal: PropTypes.func.isRequired,
  };

  onRemoveOtp = () => {
    const { removeOtp, closeModal } = this.props;

    removeOtp();
    closeModal(MODAL_IDS.REMOVE_OTP);
  };

  render() {
    return (
      <ModalWindow
        id={MODAL_IDS.REMOVE_OTP}
        coreProps={{ id: MODAL_IDS.REMOVE_OTP }}
        bodyClassName="pt-6_75 px-2 pb-3"
        isModalCentered
        fade
      >
        <FormattedTag
          tag="div"
          id="remove-otp.title"
          className="remove-otp-title text-center mb-2"
        />

        <FormattedTag
          tag="div"
          id="remove-otp.description"
          className="remove-otp-desc text-center text-gray-10 mb-2"
          isHtml
        />

        <FormattedTag
          tag={Button}
          id="confirm"
          color="primary"
          onClick={this.onRemoveOtp}
          block
        />
      </ModalWindow>
    );
  }
}

export const RemoveOtpModal = withModalActions(
  withOtpActions(
    SetupOtpModalUI
  )
);

