import { PRODUCTION } from 'core/constants';
import { canUseDOM } from 'core/helpers';

export const GA = {
  event: ({ category = '', action = '', label = '' }) => {
    if (canUseDOM()) {
      if (window.gtag) {
        window.gtag('event', action, {
          event_category: category,
          event_label: label,
        });
      }

      if (window.fbq) {
        window.fbq('trackCustom', `${category}_${action}`.replace(/ /ig, '_'));
      }

      if (process.env.PROJECT_ENV !== PRODUCTION) {
        console.log('GTAG:', 'event', category, action, label);
      }
    }
  },
};

export const GTM = {
  event: ({ event, attributes = {} }) => {
    if (canUseDOM()) {
      if (window.dataLayer) {
        try {
          window.dataLayer.push({
            event,
            ...attributes,
          });
        } catch (err) {
          console.log(err);
        }
      }
    }
  }
};
