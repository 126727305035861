import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import FormGroup from 'reactstrap/lib/FormGroup';
import Label from 'reactstrap/lib/Label';
import { withCoreComponent } from 'core/hocs';
import { FormElement as FormElementCore } from 'core/components';

import { DatePickerInput } from 'components/form-elements/date-picker-input/date-picker-input';
import { Checkbox } from '../form-elements/checkbox/checkbox';
import { DateInput } from '../form-elements/date-input/date-input';
import { Input } from '../form-elements/input/input';
import { InputWithAction } from '../form-elements/input-with-action/input-with-action';
import { Radio } from '../form-elements/radio/radio';
import { Select } from '../form-elements/select/select';
import { TelInput } from '../form-elements/tel-input/tel-input';
import { Textarea } from '../form-elements/textarea/textarea';

import './form-element.scss';

const getElement = (item) => {
  let Element;

  switch (item.type) {
    case 'text':
      Element = Input;
      break;

    case 'text-button':
      Element = InputWithAction;
      break;

    case 'textarea':
      Element = Textarea;
      break;

    case 'select':
      Element = Select;
      break;

    case 'checkbox':
      Element = Checkbox;
      break;

    case 'radio':
      Element = Radio;
      break;

    case 'date':
      Element = DateInput;
      break;

    case 'date-picker':
      Element = DatePickerInput;
      break;

    case 'tel':
      Element = TelInput;
      break;

    case 'password':
      Element = Input;
      break;

    default:
      Element = null;
  }

  return Element;
};

export class FormElementUI extends PureComponent {
  static propTypes = {
    error: PropTypes.shape(),
    item: PropTypes.shape().isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool, PropTypes.number]),
    onChange: PropTypes.func,
    formGroupClassName: PropTypes.string,
    wrapperClassName: PropTypes.string,
    labelClassName: PropTypes.string,
    isEmptyErrorSpaceFilled: PropTypes.bool,
    withoutErrorText: PropTypes.bool,
    checkPasswordFieldErrors: PropTypes.func,
    isSpecificPasswordRule: PropTypes.bool,
  };

  static defaultProps = {
    value: undefined,
    error: null,
    onChange: () => {},
    checkPasswordFieldErrors: () => {},
    formGroupClassName: '',
    wrapperClassName: '',
    labelClassName: 'text-gray-20',
    isEmptyErrorSpaceFilled: true,
    withoutErrorText: false,
    isSpecificPasswordRule: false,
  };

  isCheckbox = this.props.item.type === 'checkbox';

  isPasswordField = this.props.item.type === 'password';

  onChange = (e) => {
    const { item, onChange, checkPasswordFieldErrors } = this.props;

    let val;

    if (this.isCheckbox) {
      val = e.currentTarget.checked;
    } else if (['tel', 'date-picker'].includes(item.type)) {
      val = e;
    } else {
      val = e.currentTarget.value;
    }

    onChange(val);

    if (this.isPasswordField) {
      checkPasswordFieldErrors(e.currentTarget.value);
    }
  };

  render() {
    const {
      value,
      error,
      formGroupClassName,
      item,
      wrapperClassName,
      labelClassName,
      isEmptyErrorSpaceFilled,
      withoutErrorText,
      isSpecificPasswordRule,
      ...otherProps
    } = this.props;
    const Element = getElement(item);

    return (
      <FormGroup
        className={classNames(`position-relative form-group-${item.name} mb-1_5`, formGroupClassName, {
          'has-error': error,
          'mb-0': item.type === 'text-button',
        })}
      >
        {item.label && !this.isCheckbox && (
          <Label className={labelClassName} for={`input-${item.name}`}>
            <FormattedHTMLMessage id={item.label} />
          </Label>
        )}
        <div className={wrapperClassName}>
          <Element
            {...item}
            value={value}
            error={error}
            {...otherProps}
            onChange={this.onChange}
          />
          {!!error && !withoutErrorText && !isSpecificPasswordRule && (
            <div className={classNames('d-flex text-danger w-100 pl-0_25', {
              'form-element-error pt-0_25': isEmptyErrorSpaceFilled || error,
            })}
            >
              <FormattedMessage id={error.message} values={error.values} />
            </div>
          )}
          {this.isPasswordField && isSpecificPasswordRule && !!error && (
            <div className={classNames('d-flex text-danger w-100 pl-0_25', {
              'form-element-error pt-0_25': isEmptyErrorSpaceFilled || error,
            })}
            >
              <FormattedMessage id="form.fields.warning.password-rule" />
            </div>
          )}
        </div>
      </FormGroup>
    );
  }
}

export const FormElement = withCoreComponent(FormElementCore, FormElementUI);
