module.exports = {
  email: {
    type: 'text',
    label: 'form.fields.email',
  },
  // fullName: {
  //   type: 'text',
  //   label: 'form.fields.full-name',
  //   placeholder: 'form.fileds.full-name.placeholder',
  //   validation: [
  //     { type: 'required' },
  //     { type: 'max-length', value: 70 },
  //   ],
  // },
  firstName: {
    type: 'text',
    label: 'form.fields.first-name',
    validation: [
      { type: 'latin-words-with-spaces-and-dashes-and-special-chars' },
      { type: 'max-length', value: 70 },
    ],
  },
  lastName: {
    type: 'text',
    label: 'form.fields.last-name',
    validation: [
      { type: 'latin-words-with-spaces-and-dashes-and-special-chars' },
      { type: 'max-length', value: 70 },
    ],
  },
  birthDate: {
    type: 'text',
    label: 'form.fields.birth-date',
  },
  gender: {
    type: 'select',
    label: 'form.fields.gender',
  },
  country: {
    type: 'select',
    label: 'form.fields.country',
    isOptionsLocalized: true,
  },
  city: {
    type: 'text',
    label: 'form.fields.city',
    validation: [
      { type: 'latin-words-with-spaces-and-dashes-and-special-chars' },
      { type: 'max-length', value: 50 },
    ],
  },
  address: {
    type: 'text',
    label: 'form.fields.address',
    validation: [
      { type: 'max-length', value: 75 },
    ],
  },
  postalCode: {
    type: 'text',
    label: 'form.fields.postal-code',
    validation: [
      { type: 'latin-and-number' },
      { type: 'max-length', value: 10 },
    ],
  },
  mobilePhone: {
    type: 'text',
    label: 'form.fields.mobile',
  },
  docNumber: {
    type: 'text',
    label: 'form.fields.national-id',
    validation: [
      { type: 'required' },
      { type: 'national-registration-number' },
    ],
  },
};
