import React from 'react';
import PropTypes from 'prop-types';

export const IconArrowUp = ({ className }) => (
  <svg className={className} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M5 13L10 8L15 13" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

IconArrowUp.propTypes = {
  className: PropTypes.string,
};

IconArrowUp.defaultProps = {
  className: '',
};
