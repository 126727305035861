import React from 'react';
import PropTypes from 'prop-types';

export const IconCheckCircle = ({ className }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="72" height="72" className={className}>
    <defs>
      <linearGradient id="icon-check-circle-a" x1="0%" y1="0%" y2="100%">
        <stop offset="0%" stopColor="#00DD89" />
        <stop offset="100%" stopColor="#009769" />
      </linearGradient>
    </defs>
    <path fill="url(#icon-check-circle-a)" d="M61.456 10.544C68.256 17.344 72 26.384 72 36s-3.745 18.656-10.544 25.456C54.656 68.256 45.616 72 36 72s-18.656-3.745-25.456-10.544C3.744 54.656 0 45.616 0 36s3.745-18.656 10.544-25.456C17.344 3.744 26.384 0 36 0s18.656 3.745 25.456 10.544zM36 67.781c17.524 0 31.781-14.257 31.781-31.781S53.524 4.219 36 4.219 4.219 18.476 4.219 36 18.476 67.781 36 67.781zM53.239 24.43a2.112 2.112 0 0 1 0 2.988L33.074 47.57a2.109 2.109 0 0 1-1.495.619 2.108 2.108 0 0 1-1.495-.619L18.761 36.254a2.112 2.112 0 0 1 0-2.988 2.115 2.115 0 0 1 2.99 0l9.828 9.822 18.67-18.658a2.115 2.115 0 0 1 2.99 0z" />
  </svg>
);

IconCheckCircle.propTypes = {
  className: PropTypes.string,
};

IconCheckCircle.defaultProps = {
  className: null,
};
