import { initClient } from 'core/client';
import { getEnv } from 'core/helpers';
import { LOCAL } from 'core/constants';
import * as Sentry from '@sentry/browser';
import routesConfig from '../routes';

const projectEnv = getEnv('PROJECT_ENV');

if (projectEnv !== LOCAL) {
  Sentry.init({
    dsn: getEnv('SENTRY_DSN'),
    environment: projectEnv,
  });

  Sentry.setTag('SSR', 'false');
}

initClient(routesConfig);
