import React from 'react';
import ReactDOM from 'react-dom';

import { GamesSearch } from './games-search';

export const GamesSearchPortal = props => ReactDOM.createPortal(
  <GamesSearch {...props} />,
  document.getElementById('root'),
);

