import React from 'react';
import { ModalWindow } from 'components/modal-window/modal-window';
import { FormCompleteSignUp } from 'components/forms/form-complete-sign-up/form-complete-sign-up';
import { MODAL_IDS } from '../../../constants';

export const CompleteSignUpModal = () => (
  <ModalWindow
    id={MODAL_IDS.COMPLETE_SIGN_UP}
    coreProps={{ id: MODAL_IDS.COMPLETE_SIGN_UP }}
    contentClassName="border-0 complete-profile-modal bg-white"
    isModalCentered
    fade
  >
    <FormCompleteSignUp />
  </ModalWindow>
);
