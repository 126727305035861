import React from 'react';
import PropTypes from 'prop-types';

export const IconArrowRightCornerCarousel = ({ className, color }) => (
  <svg className={className} width="9" height="18" viewBox="0 0 9 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M7.85359 8.56099L1.85342 1.17282C1.65478 0.936602 1.33825 0.943384 1.14641 1.18797C0.959254 1.42657 0.959254 1.80482 1.14641 2.04338L6.79307 8.99627L1.14641 15.9492C0.951197 16.1896 0.951197 16.5793 1.14641 16.8197C1.34168 17.0601 1.65818 17.0601 1.85342 16.8197L7.85359 9.43155C8.0488 9.19111 8.0488 8.8014 7.85359 8.56099Z" fill={color} stroke="white" strokeWidth="2" />
  </svg>
);

IconArrowRightCornerCarousel.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string,
};
IconArrowRightCornerCarousel.defaultProps = {
  className: '',
  color: '#FFF',
};
