import React from 'react';
import PropTypes from 'prop-types';

export const IconGift = ({ className }) => (
  <svg className={className} width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.75865 4.18469C2.40128 3.50663 2.32925 2.67354 2.5832 1.92747C2.82514 1.2164 3.30163 0.671344 3.92495 0.390316C4.57782 0.0952872 5.33782 0.11929 6.01008 0.452323C6.67957 0.784356 7.70367 1.90547 8.43226 2.76955C9.16455 1.90047 10.184 0.785356 10.8544 0.452323C11.5276 0.12029 12.2848 0.0962873 12.9386 0.389316C13.562 0.670344 14.0385 1.2154 14.2813 1.92647C14.5362 2.67454 14.4623 3.50763 14.1059 4.18469H14.5C15.6046 4.18469 16.5 5.08012 16.5 6.18469C16.5 6.73698 16.0523 7.18469 15.5 7.18469H1.5C0.947715 7.18469 0.5 6.73698 0.5 6.18469C0.5 5.08012 1.39543 4.18469 2.5 4.18469H2.75865ZM4.94351 4.03468C4.4864 3.98967 4.16043 3.80465 3.97482 3.48662C3.80306 3.19259 3.76705 2.78455 3.88155 2.44752C3.99144 2.12849 4.18906 1.89547 4.45593 1.77545C4.59168 1.71545 4.7385 1.68444 4.88902 1.68444C5.07094 1.68444 5.25748 1.72845 5.43293 1.81546C6.04425 2.11949 6.98061 3.16759 7.75169 4.15969C6.89104 4.14969 5.79861 4.11969 4.94351 4.03468ZM9.11191 4.15969C9.97256 4.14969 11.0668 4.11969 11.9201 4.03468C12.3781 3.98967 12.7032 3.80465 12.8888 3.48662C13.0605 3.19259 13.0975 2.78455 12.982 2.44652C12.874 2.12849 12.6745 1.89547 12.4077 1.77545C12.1085 1.64044 11.7529 1.65644 11.4316 1.81546C10.8194 2.12049 9.88298 3.16859 9.11191 4.15969ZM1.5 8.18469H7.93233V16.1847H4.5C2.84315 16.1847 1.5 14.8415 1.5 13.1847V8.18469ZM8.93233 16.1847V8.18469H15.5V13.1847C15.5 14.8415 14.1569 16.1847 12.5 16.1847H8.93233Z"
      fill="#E15341"
    />
  </svg>
);

IconGift.propTypes = {
  className: PropTypes.string,
};

IconGift.defaultProps = {
  className: '',
};
