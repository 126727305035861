import React from 'react';
import PropTypes from 'prop-types';

export const IconYoutube = ({ className }) => (
  <svg className={className} width="34" height="33" viewBox="0 0 34 33" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M16.9857 1.33595C8.60784 1.33595 1.81627 8.12513 1.81627 16.5C1.81627 24.8749 8.60784 31.6641 16.9857 31.6641C25.3635 31.6641 32.155 24.8749 32.155 16.5C32.155 8.12513 25.3635 1.33595 16.9857 1.33595ZM0.497192 16.5C0.497192 7.39688 7.87933 0.017334 16.9857 0.017334C26.092 0.017334 33.4741 7.39688 33.4741 16.5C33.4741 25.6031 26.092 32.9827 16.9857 32.9827C7.87933 32.9827 0.497192 25.6031 0.497192 16.5Z" fill="url(#icon-youtube-1)" />
    <path d="M24.7784 12.5823C24.6858 12.2376 24.5043 11.9233 24.252 11.6708C23.9997 11.4182 23.6855 11.2364 23.3408 11.1434C22.0719 10.8022 16.9857 10.8022 16.9857 10.8022C16.9857 10.8022 11.8995 10.8022 10.6307 11.1416C10.2859 11.2343 9.97148 11.416 9.71914 11.6686C9.46679 11.9212 9.28536 12.2357 9.19308 12.5805C8.85364 13.8507 8.85364 16.5 8.85364 16.5C8.85364 16.5 8.85364 19.1493 9.19308 20.4176C9.38005 21.1181 9.93187 21.6697 10.6307 21.8566C11.8995 22.1977 16.9857 22.1977 16.9857 22.1977C16.9857 22.1977 22.0719 22.1977 23.3408 21.8566C24.0414 21.6697 24.5915 21.1181 24.7784 20.4176C25.1179 19.1493 25.1179 16.5 25.1179 16.5C25.1179 16.5 25.1179 13.8507 24.7784 12.5823ZM15.3702 18.9315V14.0685L19.5815 16.4818L15.3702 18.9315Z" fill="url(#icon-youtube-2)" />
  </svg>
);

IconYoutube.propTypes = {
  className: PropTypes.string,
};

IconYoutube.defaultProps = {
  className: '',
};
