import React from 'react';
import { ModalWindow } from 'components/modal-window/modal-window';
import { NewLevelContent } from 'components/modals/new-level-modal/new-level-content/new-level-content';
// import { IconNewLevelBg } from 'components/icons/icon-new-level-bg/icon-new-level-bg';

import { MODAL_IDS } from '../../../constants';

import './new-level-modal.scss';

export const NewLevelModal = () => (
  <ModalWindow
    id={MODAL_IDS.NEW_LEVEL}
    coreProps={{ id: MODAL_IDS.NEW_LEVEL }}
    contentClassName="new-level-modal overflow-auto"
    bodyClassName="d-flex align-items-center justify-content-center d-sm-block"
    isModalCentered
    fade
  >
    <NewLevelContent modalId={MODAL_IDS.NEW_LEVEL} />
    {/* <IconNewLevelBg className="new-level-modal-bg w-100 h-100 position-absolute" /> */}
  </ModalWindow>
);
