import React from 'react';
import PropTypes from 'prop-types';
import colors from '../../../customizations/js/color-variables';

export const IconProfile = ({ className, color }) => (
  <svg className={className} width="28" height="28" viewBox="0 0 28 28">
    <path className="icon-filled-path icon-stroked-path" d="M23.72 4.278A13.66 13.66 0 0 0 14 .251c-3.673 0-7.125 1.43-9.722 4.027A13.659 13.659 0 0 0 .25 14a13.66 13.66 0 0 0 4.027 9.722 13.659 13.659 0 0 0 9.722 4.027 13.66 13.66 0 0 0 9.722-4.027A13.66 13.66 0 0 0 27.748 14c0-3.672-1.43-7.125-4.027-9.722zM7.144 24.01a6.942 6.942 0 0 1 6.856-5.753 6.942 6.942 0 0 1 6.856 5.753A12.069 12.069 0 0 1 14 26.138c-2.542 0-4.904-.787-6.856-2.128zm2.484-11.736A4.376 4.376 0 0 1 14 7.903a4.377 4.377 0 0 1 4.372 4.371 4.377 4.377 0 0 1-4.372 4.372 4.377 4.377 0 0 1-4.372-4.372zm12.624 10.619a8.564 8.564 0 0 0-2.502-4.032 8.563 8.563 0 0 0-2.49-1.572 5.983 5.983 0 0 0 2.723-5.015 5.99 5.99 0 0 0-5.983-5.982 5.99 5.99 0 0 0-5.983 5.982 5.983 5.983 0 0 0 2.723 5.015 8.565 8.565 0 0 0-2.49 1.571 8.565 8.565 0 0 0-2.502 4.033A12.107 12.107 0 0 1 1.861 14C1.861 7.307 7.306 1.862 14 1.862S26.137 7.307 26.137 14c0 3.51-1.498 6.675-3.886 8.893z" fill={color} stroke={color} strokeWidth=".3" />
  </svg>
);

IconProfile.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string,
};

IconProfile.defaultProps = {
  className: '',
  color: colors.gray30,
};
