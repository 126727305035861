import { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { parse as parseQueryString, stringify as stringifyQueryString } from 'qs';
import * as R from 'ramda';
import {
  withUser,
  withLocale,
  withModalActions,
  withFormDataActions,
  withNotificationsActions,
} from 'core/hocs';
import {
  USER_FIELDS,
  FORM_ACTIONS,
  FORM_FIELDS,
  RESPONSE_STATUSES,
  NOTIFICATION_MESSAGES,
} from 'core/constants';
import { wrapRoute2Locale, convertStringifiedBoolean } from 'core/helpers';

import { MODAL_IDS } from '../../constants';

const {
  EMAIL,
  IS_SOCIAL_AUTH,
  SOCIAL_AUTH_TYPE,
  SOCIAL_AUTH_PROFILE_ID,
} = FORM_FIELDS;

const {
  SOCIAL_AUTH_EMPTY_PROFILE_OR_ID,
  SOCIAL_AUTH_UNKNOWN,
} = RESPONSE_STATUSES;

class SocialAuthTrackerUI extends Component {
  componentDidMount() {
    const {
      locale,
      setFormData,
      openModalAndCloseOthers,
      showErrorNotification,
      location: { search },
      history,
    } = this.props;

    const queryStringData = parseQueryString(search, { ignoreQueryPrefix: true });
    let { isSocialAuthRedirect, socialIsNewUser } = queryStringData;

    isSocialAuthRedirect = convertStringifiedBoolean(isSocialAuthRedirect);
    socialIsNewUser = convertStringifiedBoolean(socialIsNewUser);

    const {
      socialType,
      socialProfileId,
      socialProfileEmail,
      socialError,
    } = queryStringData;

    if (isSocialAuthRedirect) {
      if (socialError) {
        let errorIntlKey;

        switch (socialError) {
          case SOCIAL_AUTH_EMPTY_PROFILE_OR_ID:
            errorIntlKey = NOTIFICATION_MESSAGES[SOCIAL_AUTH_EMPTY_PROFILE_OR_ID];
            break;
          case SOCIAL_AUTH_UNKNOWN:
            errorIntlKey = NOTIFICATION_MESSAGES[SOCIAL_AUTH_UNKNOWN];
            break;
          default:
            errorIntlKey = NOTIFICATION_MESSAGES.UNDEFINED;
        }

        showErrorNotification(errorIntlKey);
      } else if (socialIsNewUser) {
        setFormData(FORM_ACTIONS.SIGN_UP, {
          [EMAIL]: socialProfileEmail,
          [IS_SOCIAL_AUTH]: true,
          [SOCIAL_AUTH_TYPE]: socialType,
          [SOCIAL_AUTH_PROFILE_ID]: socialProfileId,
        });

        openModalAndCloseOthers(MODAL_IDS.SIGN_UP);
      }

      const allowedRedirectQueryParams = ['launch_from'];

      const redirectUrl = `/${stringifyQueryString(
        R.compose(
          R.filter(Boolean),
          R.pick(allowedRedirectQueryParams),
        )(queryStringData || {}),
        { addQueryPrefix: true },
      )}`;

      history.replace(`${wrapRoute2Locale(redirectUrl, locale)}`);
    }
  }

  render() {
    return null;
  }
}

SocialAuthTrackerUI.propTypes = {
  locale: PropTypes.string.isRequired,
  setFormData: PropTypes.func.isRequired,
  openModalAndCloseOthers: PropTypes.func.isRequired,
  showErrorNotification: PropTypes.func.isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string,
    search: PropTypes.string,
  }).isRequired,
  history: PropTypes.shape({
    replace: PropTypes.func,
  }).isRequired,
};

export const SocialAuthTracker = withRouter(
  withNotificationsActions(
    withModalActions(
      withFormDataActions(
        withLocale(
          withUser(
            SocialAuthTrackerUI,
            [USER_FIELDS.IS_USER_LOGGED_IN]
          )
        )
      )
    )
  )
);

