/* eslint-disable global-require */
module.exports = {
  SIGN_UP: require('./sign-up'),
  SIGN_UP_WITH_SMS_CONFIRMATION: require('./sign-up-with-sms-confirmation'),
  SIGN_IN: require('./sign-in'),
  COMPLETE_SIGN_UP: require('./complete-sign-up'),
  RESET_PASSWORD: require('./reset-password'),
  COMPLETE_RESET_PASSWORD: require('./complete-reset-password'),
  SUPPORT: require('./support'),
  EXCHANGE_LOYALTY_POINTS: require('./exchange-loyalty-points'),
  UPDATE_PASSWORD: require('./update-password'),
  UPDATE_PLAYER_INFO: require('./update-player-info'),
  COMPLETE_PROFILE: require('./complete-profile'),
  TRANSACTION_FILTER: require('./transaction-filter.js'),
  REDEEM_BONUS: require('./redeem-bonus'),
  PROCESS_DEPOSIT: require('./process-deposit'),
  PROCESS_WITHDRAWAL: require('./process-withdrawal'),
  CONFIRM_PHONE: require('./confirm-phone'),
  CONFIRM_OTP: require('./confirm-otp'),
  SETUP_OTP: require('./setup-otp'),
};
/* eslint-enable global-require */
