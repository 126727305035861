import React from 'react';
import PropTypes from 'prop-types';
import colors from 'customizations/js/color-variables';

export const IconArrowRight = ({ className, color }) => (
  <svg className={className} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      opacity="0.6"
      d="M8 5L13 10L8 15"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

IconArrowRight.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string,
};
IconArrowRight.defaultProps = {
  className: '',
  color: colors.gray20,
};
