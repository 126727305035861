import React from 'react';
import PropTypes from 'prop-types';

export const IconLogoSmall = ({ className }) => (
  <svg
    className={className}
    width="141"
    height="32"
    viewBox="0 0 141 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#icon-logo-small-clip0_1673_32195)">
      <path
        d="M60.1023 13.9095C57.646 8.30247 53.3253 2.53419 51.608 0.347727C51.5238 0.240011 51.4135 0.152365 51.2861 0.091935C51.1586 0.0315052 51.0176 0 50.8746 0C50.7317 0 50.5907 0.0315052 50.4633 0.091935C50.3358 0.152365 50.2255 0.240011 50.1414 0.347727C48.4241 2.53419 44.1034 8.30247 41.6471 13.9095C39.3703 19.1067 38.4859 24.5179 38.1479 27.5271C38.1284 27.7011 38.1673 27.8764 38.2591 28.029C38.3509 28.1815 38.4911 28.3039 38.6605 28.3792C38.8299 28.4545 39.0202 28.4791 39.2052 28.4495C39.3902 28.42 39.5609 28.3378 39.6937 28.2142C41.191 26.8285 42.766 25.5177 44.4122 24.2873C46.3118 22.8896 48.3239 21.6302 50.4308 20.5202C50.5666 20.449 50.7197 20.4116 50.8755 20.4116C51.0313 20.4116 51.1844 20.449 51.3202 20.5202C53.4263 21.6307 55.4378 22.8901 57.3371 24.2873C58.9837 25.5173 60.5587 26.8281 62.0556 28.2142C62.1884 28.3378 62.3591 28.42 62.5442 28.4495C62.7292 28.4791 62.9195 28.4545 63.0889 28.3792C63.2583 28.3039 63.3985 28.1815 63.4903 28.029C63.5821 27.8764 63.6209 27.7011 63.6015 27.5271C63.2651 24.5179 62.379 19.1067 60.1023 13.9095Z"
        fill="url(#icon-logo-small-paint0_linear_1673_32195)"
      />
      <path
        d="M53.1142 23.9784C52.4528 23.5399 51.447 23.0095 51.0492 22.8031C50.9972 22.7764 50.9388 22.7625 50.8795 22.7625C50.8201 22.7625 50.7616 22.7764 50.7096 22.8031C50.3118 23.0095 49.3045 23.5399 48.6431 23.9784C48.0804 24.3554 47.5456 24.7672 47.0422 25.211C46.9849 25.2603 46.9468 25.326 46.9339 25.3977C46.9211 25.4694 46.9343 25.543 46.9713 25.607C47.0084 25.6709 47.0673 25.7215 47.1387 25.7508C47.2101 25.7801 47.29 25.7863 47.3657 25.7686L50.8051 25.0302C50.8581 25.0175 50.9137 25.0175 50.9668 25.0302L54.4078 25.7686C54.4834 25.7863 54.5632 25.7801 54.6346 25.7508C54.706 25.7215 54.7649 25.6709 54.802 25.607C54.8391 25.543 54.8523 25.4694 54.8394 25.3977C54.8266 25.326 54.7885 25.2603 54.7312 25.211C54.2226 24.7667 53.6823 24.3549 53.1142 23.9784Z"
        fill="white"
      />
      <mask
        id="mask0_1673_32195"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="38"
        y="0"
        width="26"
        height="29"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M51.6084 0.347727C53.3257 2.53419 57.6464 8.30247 60.1027 13.9095C62.3795 19.1067 63.2656 24.5179 63.6019 27.5271C63.6213 27.7011 63.5825 27.8764 63.4907 28.029C63.3989 28.1815 63.2587 28.3039 63.0893 28.3792C62.9199 28.4545 62.7296 28.4791 62.5446 28.4495C62.3596 28.42 62.1889 28.3378 62.056 28.2142C60.5591 26.8281 58.9841 25.5173 57.3376 24.2873C55.4382 22.8901 53.4267 21.6307 51.3206 20.5202C51.1849 20.449 51.0317 20.4116 50.8759 20.4116C50.7202 20.4116 50.567 20.449 50.4313 20.5202C48.3244 21.6302 46.3122 22.8896 44.4126 24.2873C42.7665 25.5177 41.1914 26.8285 39.6941 28.2142C39.5613 28.3378 39.3906 28.42 39.2056 28.4495C39.0206 28.4791 38.8303 28.4545 38.6609 28.3792C38.4915 28.3039 38.3513 28.1815 38.2595 28.029C38.1677 27.8764 38.1289 27.7011 38.1483 27.5271C38.4863 24.5179 39.3707 19.1067 41.6475 13.9095C44.1038 8.30247 48.4245 2.53419 50.1418 0.347727C50.226 0.240011 50.3363 0.152365 50.4637 0.091935C50.5911 0.0315052 50.7321 0 50.8751 0C51.0181 0 51.1591 0.0315052 51.2865 0.091935C51.414 0.152365 51.5242 0.240011 51.6084 0.347727ZM51.0497 22.8031C51.4474 23.0095 52.4532 23.5399 53.1146 23.9784C53.6828 24.3549 54.223 24.7667 54.7316 25.211C54.789 25.2603 54.827 25.326 54.8399 25.3977C54.8527 25.4694 54.8395 25.543 54.8025 25.607C54.7654 25.6709 54.7065 25.7215 54.6351 25.7508C54.5637 25.7801 54.4839 25.7863 54.4082 25.7686L50.9672 25.0302C50.9142 25.0175 50.8586 25.0175 50.8055 25.0302L47.3661 25.7686C47.2905 25.7863 47.2106 25.7801 47.1392 25.7508C47.0678 25.7215 47.0089 25.6709 46.9718 25.607C46.9347 25.543 46.9215 25.4694 46.9344 25.3977C46.9472 25.326 46.9853 25.2603 47.0427 25.211C47.5461 24.7672 48.0809 24.3554 48.6435 23.9784C49.3049 23.5399 50.3123 23.0095 50.7101 22.8031C50.7621 22.7764 50.8205 22.7625 50.8799 22.7625C50.9393 22.7625 50.9976 22.7764 51.0497 22.8031Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_1673_32195)">
        <rect opacity="0.2" x="50.8184" y="-0.55249" width="13.5125" height="29.2762" fill="black" />
      </g>
      <path
        d="M3.851 9.97632H17.2302V12.7068H6.82956C5.99679 12.7068 5.31762 13.4602 5.31762 14.3945V21.6093C5.31762 22.5391 5.99679 23.2955 6.82956 23.2955H17.2302V26.029H3.851C1.72785 26.029 0.000854492 24.171 0.000854492 21.8866V14.1157C0.000854492 11.8328 1.72785 9.97632 3.851 9.97632Z"
        fill="white"
      />
      <path
        d="M36.4767 12.3557C36.7189 12.9155 36.8425 13.5137 36.8405 14.1172V15.2263C36.8405 16.4649 36.1937 17.5318 35.2526 18.0019C36.1872 18.4721 36.8405 19.5389 36.8405 20.7776V26.029H31.5254V20.5003C31.5254 19.8765 31.113 19.3687 30.6085 19.3687H24.9279V26.029H19.6127V9.97485H32.9903C34.4764 9.97485 35.8444 10.9091 36.4767 12.3557ZM24.9279 12.7083V16.6352H30.6085C31.113 16.6352 31.5254 16.1274 31.5254 15.5036V14.3945C31.5254 13.4648 30.8462 12.7083 30.0134 12.7083H24.9279Z"
        fill="white"
      />
      <path
        d="M68.7881 9.97632H82.1674V12.7068H71.7666C70.9339 12.7068 70.2548 13.4602 70.2548 14.3945V14.949C70.2548 15.8787 70.9339 16.6337 71.7666 16.6337H78.3172C79.2128 16.642 80.072 16.9649 80.7234 17.5378C81.17 17.9379 81.5272 18.4164 81.7742 18.9459C82.0212 19.4753 82.1532 20.0451 82.1625 20.6223C82.1625 20.666 82.1625 20.7203 82.1625 20.773V21.8836C82.1625 21.9333 82.1625 21.98 82.1625 22.0343C82.1459 22.5882 82.0245 23.1351 81.8036 23.6496C81.5636 24.2098 81.1942 24.714 80.7218 25.1264C80.0715 25.6998 79.2125 26.0228 78.3172 26.0305H64.9379V23.2985H75.3387C76.1715 23.2985 76.8506 22.5451 76.8506 21.6124V21.0593C76.8506 20.1281 76.1715 19.3716 75.3387 19.3716H68.7881C67.8921 19.365 67.0322 19.0419 66.382 18.4675C65.9357 18.0678 65.5788 17.5897 65.3318 17.0608C65.0848 16.5319 64.9526 15.9627 64.9428 15.386C64.9428 15.3317 64.9428 15.2835 64.9428 15.2353V14.1247C64.9428 14.0735 64.9428 14.0208 64.9428 13.974C64.96 13.4236 65.0815 12.8803 65.3018 12.3692C65.5414 11.8097 65.9108 11.3065 66.3835 10.8955C67.0313 10.3166 67.8906 9.98811 68.7881 9.97632Z"
        fill="white"
      />
      <path
        d="M96.4639 9.97485H101.779V26.029H96.4639V19.3687H89.8664V26.029H84.5496V9.97485H89.8664V16.6352H96.4639V9.97485Z"
        fill="white"
      />
      <path
        d="M108.012 9.97632H121.391V12.7083H110.99C110.157 12.7083 109.48 13.4617 109.48 14.396V16.6352H115.435V19.3686H109.48V23.2985H121.391V26.032H104.161V14.1187C104.161 11.8328 105.888 9.97632 108.012 9.97632Z"
        fill="white"
      />
      <path
        d="M140.637 12.3557C140.88 12.9155 141.003 13.5137 141.001 14.1172V15.2263C141.001 16.4649 140.354 17.5318 139.413 18.0019C140.348 18.4721 141.001 19.5389 141.001 20.7776V26.029H135.686V20.5003C135.686 19.8765 135.275 19.3687 134.769 19.3687H129.089V26.029H123.773V9.97485H137.153C138.637 9.97485 140.005 10.9091 140.637 12.3557ZM129.089 12.7083V16.6352H134.769C135.275 16.6352 135.686 16.1274 135.686 15.5036V14.3945C135.686 13.4648 135.007 12.7083 134.174 12.7083H129.089Z"
        fill="white"
      />
    </g>
  </svg>
);

IconLogoSmall.propTypes = {
  className: PropTypes.string,
};

IconLogoSmall.defaultProps = {
  className: null,
};
