import React from 'react';
import PropTypes from 'prop-types';

export const IconSlotMachine = ({ className }) => (
  <svg className={className} width="22" height="22" viewBox="8 8 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9.63198 27.8681C9.63198 28.2133 9.91134 28.4926 10.2565 28.4926H22.7473C23.0925 28.4926 23.3718 28.2132 23.3718 27.8681V23.4963H9.63198V27.8681ZM13.3792 25.3699H19.6246C19.9698 25.3699 20.2491 25.6493 20.2491 25.9945C20.2491 26.3396 19.9697 26.619 19.6246 26.619H13.3792C13.034 26.619 12.7547 26.3396 12.7547 25.9945C12.7547 25.6493 13.034 25.3699 13.3792 25.3699ZM27.7436 8.50732C26.7105 8.50732 25.87 9.34777 25.87 10.3809C25.87 11.1941 26.3937 11.8808 27.1191 12.1395V16.0019H25.87V19.7491H27.7436C28.0888 19.7491 28.3682 19.4697 28.3682 19.1246V12.1395C29.0936 11.8808 29.6173 11.1941 29.6173 10.3809C29.6173 9.34777 28.7768 8.50732 27.7436 8.50732ZM11.5056 19.1245H14.0037V15.3773H11.5056V19.1245ZM19.0001 19.1245H21.4982V15.3773H19.0001V19.1245ZM15.2528 19.1245H17.751V15.3773H15.2528V19.1245ZM23.9964 12.2546H22.2027C21.2134 9.99612 18.9757 8.50732 16.5019 8.50732C14.0281 8.50732 11.7904 9.99612 10.8011 12.2546H9.00733C8.66209 12.2546 8.38281 12.5339 8.38281 12.8791V21.6226C8.38281 21.9679 8.66216 22.2472 9.00733 22.2472H23.9963C24.3416 22.2472 24.6209 21.9678 24.6209 21.6226V12.8791C24.6209 12.5339 24.3416 12.2546 23.9964 12.2546ZM22.7473 19.7491C22.7473 20.0943 22.468 20.3736 22.1228 20.3736H10.881C10.5358 20.3736 10.2565 20.0943 10.2565 19.7491V14.7528C10.2565 14.4075 10.5359 14.1282 10.881 14.1282H22.1227C22.468 14.1282 22.7472 14.4075 22.7472 14.7528V19.7491H22.7473Z"
      fill="url(#slot-machine)"
    />
  </svg>
);

IconSlotMachine.propTypes = {
  className: PropTypes.string,
};

IconSlotMachine.defaultProps = {
  className: null,
};
