import React from 'react';
import PropTypes from 'prop-types';

export const IconFacebook = ({ className }) => (
  <svg className={className} width="33" height="33" viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M16.4741 1.33595C8.10479 1.33595 1.31908 8.12457 1.31908 16.5C1.31908 24.8755 8.10479 31.6641 16.4741 31.6641C24.8435 31.6641 31.6292 24.8755 31.6292 16.5C31.6292 8.12457 24.8435 1.33595 16.4741 1.33595ZM0 16.5C0 7.39744 7.37516 0.017334 16.4741 0.017334C25.5731 0.017334 32.9483 7.39744 32.9483 16.5C32.9483 25.6026 25.5731 32.9827 16.4741 32.9827C7.37516 32.9827 0 25.6026 0 16.5Z" fill="url(#icon-fb-1)" />
    <path d="M21.219 8.58838H18.8468C17.7983 8.58838 16.7926 9.00515 16.0512 9.74702C15.3097 10.4889 14.8932 11.4951 14.8932 12.5442V14.9177H12.521V18.0824H14.8932V24.4118H18.0561V18.0824H20.4283L21.219 14.9177H18.0561V12.5442C18.0561 12.3344 18.1394 12.1332 18.2877 11.9848C18.436 11.8364 18.6371 11.7531 18.8468 11.7531H21.219V8.58838Z" fill="url(#icon-fb-2)" />
  </svg>
);

IconFacebook.propTypes = {
  className: PropTypes.string,
};

IconFacebook.defaultProps = {
  className: '',
};
