import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withLocale } from 'core/hocs';
import { FormattedTag } from 'core/components';
import {
  getConfig,
  isEmptyOrNil,
  // getStaticUrl
} from 'core/helpers';
import { withPixelRatio } from 'hocs/with-pixel-ratio';
import { IconChecked } from 'components/icons/icon-checked/icon-checked';
import { formatAmountWithCurrency } from 'helpers/numbers';
// import { BONUSES_WEEKLY } from '../../../../constants';

import './deposit-bonus.scss';

export const DEFAULT_BONUS_OPTION = getConfig('PROCESS_DEPOSIT.DEFAULT_BONUS_OPTION');

// const getDepositBonusWeeklyBg = pixelRatio => `${getStaticUrl()}/images/deposit/bonus-weekly${pixelRatio}.png`;

const FIELDS = {
  BONUS_AMOUNT: 'bonusAmount',
  WAGER: 'wager',
  DEPOSIT: 'deposit',
  AWARD_TYPE_PERCENT: 'percent',
  FREESPINS: 'freespins',
};

export class DepositBonusUI extends Component {
  buttonHandleClick= () => {
    const { handleClick, bonusType } = this.props;
    handleClick(bonusType);
  };

  renderDescription = (field) => {
    const { locale, currentBonus, currency } = this.props;

    if (isEmptyOrNil(currentBonus)) {
      return null;
    }

    const {
      wager, minAmount, maxAmount, flatAmount, percentQuantity, freespins
    } = currentBonus.description || {};

    const isPercent = currentBonus.description.awardType === FIELDS.AWARD_TYPE_PERCENT;

    if (field === FIELDS.BONUS_AMOUNT) {
      return ` ${isPercent
        ? `${percentQuantity}%`
        : formatAmountWithCurrency({
          amount: flatAmount,
          currency,
          locale,
          isIsoFormat: true,
        })}`;
    }

    if (field === FIELDS.WAGER) {
      return (
        <FormattedTag
          id="profile.deposit-new.wager"
          values={{ wager }}
          isHtml
        />
      );
    }

    if (field === FIELDS.DEPOSIT) {
      return (
        <FormattedTag
          id="profile.deposit-new.deposit-for-bonus"
          values={{
            minDeposit: formatAmountWithCurrency({
              amount: minAmount, currency, locale, isIsoFormat: true,
            }),
            maxBonus: formatAmountWithCurrency({
              amount: isPercent ? maxAmount * percentQuantity / 100 : flatAmount,
              currency,
              locale,
              isIsoFormat: true,
            }),
          }}
          isHtml
        />
      );
    }

    if (field === FIELDS.FREESPINS) {
      return freespins && (
        <FormattedTag
          id="profile.deposit-new.freespins"
          values={{ freespins }}
          isHtml
        />
      );
    }

    return null;
  };

  render() {
    const {
      className, isActive, bonusType, bonusLabel,
      currentBonus, promoValue, isLargeSize,
      isCarousel, defaultBonusLabel
    } = this.props;
    const { bonusPlanId } = currentBonus || {};

    const isDefaultBonusOption = bonusType === DEFAULT_BONUS_OPTION;
    // const isBonusWeekly = BONUSES_WEEKLY.includes(bonusPlanId);

    return (
      <div
        className={classNames('deposit-bonus position-relative rounded overflow-hidden flex-grow-1 bg-blue-dark text-white', {
          'deposit-active': isActive && !isDefaultBonusOption,
          'card-size-large': isLargeSize,
          'h-100': !isCarousel,
          'border border-white-50': isDefaultBonusOption,
        }, className)}
        tabIndex="0"
        role="button"
        onClick={this.buttonHandleClick}
        onKeyPress={this.buttonHandleClick}
      >
        <div
          className={classNames(
            'position-absolute d-flex justify-content-end w-100 deposit-bonus-layer overflow-hidden h-100', {
              'with-bonus': !isDefaultBonusOption,
              active: isActive && !isDefaultBonusOption,
            }
          )}
        >
          <div className={classNames('rounded-circle deposit-bonus-radio m-1 d-flex justify-content-center align-items-center', {
            'icon-checked': isActive
          })}
          >
            {isActive && <IconChecked />}
          </div>
        </div>
        <div
          className={classNames('deposit-bonus-content position-relative d-flex flex-column rounded py-2 px-2_5 w-100 h-100', {
            'justify-content-center align-items-center': bonusType === DEFAULT_BONUS_OPTION,
            'justify-content-end': bonusType !== DEFAULT_BONUS_OPTION,
            'with-bonus': !isDefaultBonusOption,
          })}
        >
          <div className="deposit-bonus-headline h2 text-break text-uppercase font-family-oswald">
            <FormattedTag
              id={bonusPlanId ? `promo.bonus.title.${bonusPlanId}` : bonusLabel}
              className="deposit-bonus-headline-title"
              isHtml
            />
            {this.renderDescription(FIELDS.BONUS_AMOUNT)}
          </div>
          <div className="deposit-bonus-subtitle font-weight-medium h6 pt-0_25">
            {bonusType === DEFAULT_BONUS_OPTION && (
              <>
                <FormattedTag id={defaultBonusLabel || 'profile.deposit-new.take-no-bonuses'} isHtml />
                <br />
              </>
            )}
            {promoValue && bonusType !== DEFAULT_BONUS_OPTION && (
              <>
                <FormattedTag id="profile.deposit-new.promo" isHtml />
                <span>&#32;{promoValue}.&#32;</span>
              </>
            )}
            {this.renderDescription(FIELDS.WAGER)}
            {this.renderDescription(FIELDS.DEPOSIT)}
            {this.renderDescription(FIELDS.FREESPINS)}
          </div>
        </div>
      </div>
    );
  }
}

DepositBonusUI.propTypes = {
  locale: PropTypes.string.isRequired,
  currency: PropTypes.string.isRequired,
  className: PropTypes.string,
  isLargeSize: PropTypes.bool,
  isActive: PropTypes.bool.isRequired,
  bonusType: PropTypes.string.isRequired,
  bonusLabel: PropTypes.string.isRequired,
  handleClick: PropTypes.func,
  // pixelRatio: PropTypes.string,
  currentBonus: PropTypes.shape({
    bonusAmount: PropTypes.number,
    bonusPlanId: PropTypes.string,
    bonusPlanName: PropTypes.string,
    description: PropTypes.shape({
      awardType: PropTypes.string,
      maxAmount: PropTypes.oneOfType([
        PropTypes.string, PropTypes.number,
      ]),
      minAmount: PropTypes.oneOfType([
        PropTypes.string, PropTypes.number,
      ]),
      flatAmount: PropTypes.oneOfType([
        PropTypes.string, PropTypes.number,
      ]),
      wager: PropTypes.oneOfType([
        PropTypes.string, PropTypes.number,
      ]),
    }),
  }),
  promoValue: PropTypes.string,
  isCarousel: PropTypes.bool,
  defaultBonusLabel: PropTypes.string
};

DepositBonusUI.defaultProps = {
  className: '',
  isLargeSize: false,
  handleClick: () => null,
  currentBonus: null,
  promoValue: null,
  // pixelRatio: '',
  isCarousel: false,
  defaultBonusLabel: ''
};

export const DepositBonus = withPixelRatio(withLocale(DepositBonusUI));
