import { safeJsonParse } from 'core/helpers';

export const parseJson = (data, errorMessage = '') => {
  try {
    return safeJsonParse(data);
  } catch (e) {
    console.log(errorMessage, e);

    return {};
  }
};
