import { PRODUCTION } from 'core/constants';
import { canUseDOM, getEnv } from 'core/helpers';

export const YM = {
  event: (type = '') => {
    if (canUseDOM() && window.ym) {
      const id = getEnv('YMID');
      ym(id, 'reachGoal', type);

      if (process.env.PROJECT_ENV !== PRODUCTION) {
        console.log('YM:', 'event', type);
      }
    }
  },
};
