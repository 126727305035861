import React from 'react';
import PropTypes from 'prop-types';

export const IconLangRu = ({ className }) => (
  <svg className={className} xmlns="http://www.w3.org/2000/svg" width="30" height="24" viewBox="0 0 30 24">
    <g fill="none" fillRule="evenodd">
      <use fill="#D8D8D8" xlinkHref="#icon-lang-ru-a" />
      <g fillRule="nonzero" mask="url(#icon-lang-ru-b)">
        <path fill="#F0F0F0" d="M-3 .023V23.976h35.93V.023z" />
        <path fill="#0052B4" d="M-3 .023h35.93v23.953H-3z" />
        <path fill="#F0F0F0" d="M-3 .023h35.93v7.984H-3z" />
        <path fill="#D80027" d="M-3 15.992h35.93v7.984H-3z" />
      </g>
      <use fill="#000" filter="url(#icon-lang-ru-d)" xlinkHref="#icon-lang-ru-c" />
    </g>
  </svg>
);

IconLangRu.propTypes = {
  className: PropTypes.string,
};

IconLangRu.defaultProps = {
  className: null,
};
