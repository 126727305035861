import React, { PureComponent } from 'react';
import * as R from 'ramda';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Modal from 'reactstrap/lib/Modal';
import ModalBody from 'reactstrap/lib/ModalBody';
import { withCoreComponent } from 'core/hocs';
import { ModalWindow as ModalWindowCore } from 'core/components';
import { setDocumentScrollTopPosition, isEmptyOrNil } from 'core/helpers';
import { areModalsEmpty, isModalsNotEmpty } from 'helpers/modals';
import { MODAL_OPEN_CLASS } from '../../constants';
import { withModalAlignment } from '../../hocs/with-modal-alignment';

export class ModalWindowUI extends PureComponent {
  static propTypes = {
    id: PropTypes.string.isRequired,
    isOpen: PropTypes.bool,
    openModal: PropTypes.func.isRequired,
    closeModal: PropTypes.func.isRequired,
    onOpened: PropTypes.func,
    onClosed: PropTypes.func,
    modalClassName: PropTypes.string,
    contentClassName: PropTypes.string,
    bodyClassName: PropTypes.string,
    children: PropTypes.node.isRequired,
    fade: PropTypes.bool,
    className: PropTypes.string,
    classForDocumentBody: PropTypes.string,
    closeButtonClassName: PropTypes.string,
    modals: PropTypes.shape().isRequired,
    externalDialogContent: PropTypes.node,
    isModalCentered: PropTypes.bool.isRequired,
    isModalAlignTop: PropTypes.bool.isRequired,
    authType: PropTypes.string,
    updateModalAlignment: PropTypes.func.isRequired,
  };

  static defaultProps = {
    isOpen: false,
    modalClassName: '',
    contentClassName: '',
    bodyClassName: '',
    classForDocumentBody: '',
    closeButtonClassName: '',
    fade: false,
    onOpened: null,
    onClosed: null,
    className: '',
    externalDialogContent: null,
    authType: null,
  };

  mediaBreakpoints = null;

  mobileBreakpoint = null;

  componentDidUpdate({ authType: prevAuthType, isOpen: prevIsOpen }) {
    const {
      isOpen, authType, updateModalAlignment, classForDocumentBody, modals
    } = this.props;

    if (isOpen && authType !== prevAuthType) {
      updateModalAlignment();
    }

    if (isOpen && !prevIsOpen) {
      this.addDocumentBodyClass(MODAL_OPEN_CLASS);

      if (classForDocumentBody) {
        this.addDocumentBodyClass(classForDocumentBody);
      }
    }

    if (!isOpen && prevIsOpen) {
      if (classForDocumentBody) {
        this.removeDocumentBodyClass(classForDocumentBody);
      }

      if (areModalsEmpty(modals)) {
        this.removeDocumentBodyClass(MODAL_OPEN_CLASS);
      }

      if (isModalsNotEmpty(modals)) {
        this.addDocumentBodyClass(MODAL_OPEN_CLASS);
      }
    }
  }

  componentWillUnmount() {
    const { modals, classForDocumentBody } = this.props;

    if (classForDocumentBody) {
      this.removeDocumentBodyClass(classForDocumentBody);
    }

    if (areModalsEmpty(modals)) {
      this.removeDocumentBodyClass(MODAL_OPEN_CLASS);
    }

    if (isModalsNotEmpty(modals)) {
      this.addDocumentBodyClass(MODAL_OPEN_CLASS);
    }
  }

  toggle = () => {
    const {
      isOpen, id, openModal, closeModal, modals, classForDocumentBody,
    } = this.props;

    if (isOpen) {
      closeModal(id);

      if (areModalsEmpty(modals)) {
        this.removeDocumentBodyClass(classForDocumentBody, MODAL_OPEN_CLASS);
      }
    } else {
      openModal(id);
    }
  };

  onOpened = () => {
    const { onOpened, classForDocumentBody } = this.props;

    if (onOpened) {
      onOpened();
    }

    this.addDocumentBodyClass(MODAL_OPEN_CLASS);

    if (classForDocumentBody) {
      this.addDocumentBodyClass(classForDocumentBody);
    }

    const isIos = !!document.querySelector('.os-ios');

    if (isIos) {
      setDocumentScrollTopPosition();
    }
  };

  onClosed = () => {
    const { modals, onClosed, classForDocumentBody } = this.props;
    const { activeElement } = document;

    setTimeout(() => {
      activeElement.focus();
      document.body.style.paddingRight = null;
    }, 0);

    if (onClosed) {
      onClosed();
    }

    if (areModalsEmpty(modals)) {
      this.removeDocumentBodyClass(classForDocumentBody, MODAL_OPEN_CLASS);
    }

    if (isModalsNotEmpty(modals)) {
      this.addDocumentBodyClass(MODAL_OPEN_CLASS);
    }
  };

  onClose = () => {
    const { id, closeModal } = this.props;

    closeModal(id);
  };

  addDocumentBodyClass = (...classNamesList) => {
    setTimeout(() => document.body.classList.add(...classNamesList), 0);
  };

  removeDocumentBodyClass = (...classNamesList) => {
    document.body.classList.remove(R.reject(isEmptyOrNil, classNamesList));
  };

  render() {
    const {
      isOpen,
      children,
      bodyClassName,
      fade,
      className,
      id,
      externalDialogContent,
      isModalCentered,
      isModalAlignTop,
      closeButtonClassName,
      ...restProps
    } = this.props;

    return (
      <Modal
        isOpen={isOpen}
        fade={fade}
        wrapClassName={`wrap-${id}`}
        centered={isModalCentered}
        toggle={this.toggle}
        // Don't allow unwanted props down to reactstrap Modal component
        // external={null}
        {...R.pick(Object.keys(Modal.propTypes), restProps)}
        onOpened={this.onOpened}
        onClosed={this.onClosed}
        className={classNames(className, `dialog-${id}`, { 'modal-dialog-align-top': isModalAlignTop })}
      >
        {externalDialogContent}
        <ModalBody className={bodyClassName}>
          <button
            type="button"
            onClick={this.onClose}
            className={classNames('close-button mt-1', closeButtonClassName)}
          >&times;
          </button>
          {children}
        </ModalBody>
      </Modal>
    );
  }
}

export const ModalWindow = withCoreComponent(ModalWindowCore, withModalAlignment(ModalWindowUI));
