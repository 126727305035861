import React from 'react';
import PropTypes from 'prop-types';
import colors from 'customizations/js/color-variables';

export const IconCloseThin = ({ className, color }) => (
  <svg className={className} width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill={color}
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.8243 13.028C14.2538 13.4576 14.2538 14.1541 13.8243 14.5836C13.3947 15.0132 12.6982 15.0132 12.2686 14.5836L7.14432 9.45932L2.02 14.5836C1.59043 15.0132 0.893947 15.0132 0.46437 14.5836C0.0347932 14.1541 0.0347932 13.4576 0.46437 13.028L5.58868 7.90369L0.462807 2.77781C0.0332305 2.34824 0.0332304 1.65175 0.462807 1.22218C0.892384 0.792601 1.58887 0.792601 2.01844 1.22218L7.14432 6.34805L12.2702 1.22218C12.6998 0.792603 13.3962 0.792603 13.8258 1.22218C14.2554 1.65176 14.2554 2.34824 13.8258 2.77782L8.69995 7.90369L13.8243 13.028Z"
    />
  </svg>
);

IconCloseThin.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string,
};

IconCloseThin.defaultProps = {
  className: '',
  color: colors.gray40,
};
