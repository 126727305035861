import React from 'react';
import PropTypes from 'prop-types';
import { RouteLink } from 'core/components';
import { IconLogo } from 'components/icons/icon-logo/icon-logo';

export const Logo = ({ className, iconClassName }) => (
  <RouteLink className={className} to="/">
    <IconLogo className={iconClassName} />
  </RouteLink>
);

Logo.propTypes = {
  className: PropTypes.string,
  iconClassName: PropTypes.string,
};

Logo.defaultProps = {
  className: '',
  iconClassName: '',
};
